import s from "./changeTerms.module.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Grid, IconButton, Checkbox, Switch } from "@mui/material/";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArticleIcon from "@mui/icons-material/Article";
import GppBadIcon from "@mui/icons-material/GppBad";
import GppGoodIcon from "@mui/icons-material/GppGood";
import Swal from "sweetalert2";
import { termsAndConditionsUpdate } from "../../../store/slices/localSlice";
import { useTranslation } from "react-i18next";
import { updateTermsAndCondition } from "../../../api/terms/TermsService";
import "dayjs/locale/es";
import "dayjs/locale/pt";

export const ChangeTerms = () => {
  let navigate = useNavigate();
  const { id } = useParams();
  const [editedTerms, setEditedTerms] = useState({});
  const { terms_And_Conditions } = useSelector((state) => state.local);
  const legal = useSelector((state) => state.local.legalTerms);
  const [updateTerms, setUpdateTerms] = useState({});
  const [valueFrom, setValueFrom] = useState("");
  const [valueTo, setValueTo] = useState("");
  const { language } = useSelector((state) => state.local);
  const [isValid, setValid] = useState(false);
  const dispatch = useDispatch();
  const [primaryColor, setPrimaryColor] = useState("");
  const { uiConfig } = useSelector((state) => state.local);
  const { t } = useTranslation();
  const { textColor } = useSelector((state) => state.local);
  const currentUser = useSelector((state) => state.gamma.logged_user);
  const userOrganization = currentUser?.memberships[0]?.organization;
  const [inputs, setInputs] = useState({
    id: "",
    title: "",
    validFrom: "",
    validTo: "",
    published: "",
    omitAmount: 0,
    organizationId: userOrganization.id,
  });
  const min = 0;

  useEffect(() => {
    setUpdateTerms(terms_And_Conditions);
    let clone = { ...terms_And_Conditions.find((f) => f.id === id * 1) };
    setEditedTerms(clone);
    setInputs(clone);
    setEditedTerms(terms_And_Conditions.find((f) => f.id === id * 1));

    /* TODO:Revisar la inicialzacion de estas valiables */

    if (valueTo?.length === 0) {
      setValueTo(clone.validTo);
    }
    if (valueFrom?.length === 0) {
      setValueFrom(clone.validFrom);
    }
  }, [id, editedTerms, terms_And_Conditions, valueFrom, valueTo]);

  useEffect(() => {
    setPrimaryColor(uiConfig.primary.main);
  }, [uiConfig]);

  const handleChangeTermsAndConditions = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const replaceUpdateTerms = (term_to_replace) => {
    let termsListCopy = [...terms_And_Conditions];
    let pos = termsListCopy.indexOf(editedTerms);
    termsListCopy.splice(pos, 1, term_to_replace);
    setUpdateTerms(termsListCopy);
  };

  const handleChangeDateFrom = (date_picked) => {
    setValueFrom(date_picked);
  };

  const handleChangeDateTo = (date_picked) => {
    setValueTo(date_picked);
  };

  const handleOmit = (e) => {
    e.target.value = parseInt(e.target.value, 10);
    if (e.target.value < min) e.target.value = min;
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    let termsToUpdate = {
      id: editedTerms.id,
      title: inputs.title,
      textTc: inputs.textTc,
      creationDate: editedTerms.creationDate,
      validFrom: valueFrom,
      validTo: valueTo,
      omitAmount: inputs.omitAmount,
      published: inputs.published,
      organizationId: userOrganization.id,
    };
    Swal.fire({
      title: t("areYouSureEditTerms"),
      text: "",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "#d33",
      confirmButtonText: t("accept"),
      cancelButtonText: t("cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        updateTermsAndCondition(termsToUpdate)
          .then((response) => {
            console.log(response)
            replaceUpdateTerms(termsToUpdate);
            dispatch(termsAndConditionsUpdate({ ...updateTerms }));
            Swal.fire({
              icon: "success",
              title: t("changesSavedSuccessfully"),
              showConfirmButton: false,
              timer: 1500,
            });
            handleBack();
          }).catch((error) => {
            console.log(error)
          });
      }
    });
  };

  const handleBack = () => {
    navigate("../terms_and_conditions");
  };

  const handlePublished = (termsItem, index) => {
    if (termsItem.published === false) {
      let termsToUpdate = {
        id: termsItem.id,
        title: termsItem.title,
        textTc: termsItem.textTc,
        creationDate: termsItem.creationDate,
        validFrom: termsItem.validFrom,
        validTo: termsItem.validTo,
        published: true,
        organizationId: userOrganization.id,
      };
      updateTermsAndCondition(termsToUpdate).then(() => {
        replaceUpdateTerms(termsToUpdate, index);
        dispatch(termsAndConditionsUpdate({ updateTerms }));
      });
    } else {
      let termsToUpdate = {
        id: termsItem.id,
        title: termsItem.title,
        textTc: termsItem.textTc,
        creationDate: termsItem.creationDate,
        validFrom: termsItem.validFrom,
        validTo: termsItem.validTo,
        published: false,
        organizationId: userOrganization.id,
      };
      updateTermsAndCondition(termsToUpdate).then(() => {
        replaceUpdateTerms(termsToUpdate, index);
        dispatch(termsAndConditionsUpdate({ updateTerms }));
      });
    }
  };

  const handleBan = () => {
    Swal.fire({
      title: inputs.published
        ? `¿Estás seguro de que deseas No publicar  ${inputs?.title}?`
        : `¿Estás seguro de que deseas publicar  ${inputs?.title}?`,

      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "#d33",
      cancelButtonText: t("cancel"),
      confirmButtonText: t("accept"),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          inputs.published ? t("noPublished") : t("published"),
          inputs.published
            ? `Término y condicion NO publicado con éxito,
          Por favor no olvides guardar los cambios`
            : `Término y condicion Publicado con éxito,
          Por favor no olvides guardar los cambios`,
          "success"
        );
        setInputs({
          ...inputs,
          published: !inputs.published,
        });
      }
    });
  };

  return (
    <div className={s.container}>
      <div className={s.titleContainer}>
        <IconButton onClick={handleBack}>
          <ArrowBackIcon
            color={"primary"}
            sx={{
              fontSize: "2rem",
              cursor: "pointer",
            }}
          />
        </IconButton>
        <div className={s.title}>
          <ArticleIcon
            color={"primary"}
            sx={{
              fontSize: "2rem",
              color: primaryColor,
            }}
          />
          <h1 className={s.title}>{t("terms")}</h1>
        </div>
      </div>

      <Grid container justifyContent="center">
        <Grid
          container
          item
          spacing={3}
          xs={7}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item justifyContent="center" xs={8}>
            <TextField
              id="outlined-basic"
              label={t("title")}
              name="title"
              required
              rows={1}
              variant="outlined"
              sx={{
                marginTop: 3,
                width: "90%",
                backgroundColor: "white",
                boxShadow: "0px 0px 15px -3px rgba(0, 0, 0, 0.18)",
              }}
              onChange={handleChangeTermsAndConditions}
              value={inputs.title}
            />
          </Grid>

          <Grid
            item
            justifyContent="center"
            alignItems="center"
            xs={4}
            className={s.checkbox}
          >
            <FormControlLabel
              control={
                <label className={s.labelInput}>
                  {inputs.published ? (
                    <GppGoodIcon
                      sx={{
                        fontSize: "1.5rem",
                        color: primaryColor,
                        transition: "0.5s ease",
                      }}
                    />
                  ) : (
                    <GppBadIcon
                      sx={{
                        fontSize: "1.5rem",
                        color: "red",
                        transition: "0.5s ease",
                      }}
                    />
                  )}
                </label>
              }
            />
            <h3>
              {editedTerms?.published ? t("published") : t("noPublished")}{" "}
            </h3>
            <Switch
              checked={inputs?.published}
              sx={{ ms: 9 }}
              onClick={() => handleBan()}
            />
          </Grid>

          <Grid container item xs={12}>
            <TextField
              name="textTc"
              sx={{
                width: "100%",
                backgroundColor: "white",
                boxShadow: "0px 0px 15px -3px rgba(0, 0, 0, 0.18)",
              }}
              id="outlined-multiline-static"
              label={t("terms")}
              required
              multiline
              rows={14}
              onChange={handleChangeTermsAndConditions}
              defaultValue={inputs.textTc}
            />
          </Grid>

          <Grid
            container
            item
            xs={12}
            justifyContent="space-between"
            alignItems="center"
            columnSpacing={0}
            rowSpacing={2}
          >
            <Grid item>
              <TextField
                type="number"
                label={t("omitAmount")}
                required
                inputProps={{ min }}
                name="omitAmount"
                value={inputs.omitAmount}
                onChange={handleOmit}
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                className={s.superdate}
                disabled={isValid}
                adapterLocale={language}
              >
                <DatePicker
                  label={t("effectiveDate")}
                  inputFormat="DD/MM/YYYY"
                  value={valueFrom}
                  minDate={valueFrom}
                  onChange={handleChangeDateFrom}
                  renderInput={(params) => <TextField {...params} />}
                  className={s.date}
                  disabled={isValid}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                className={s.superdate}
                disabled={isValid}
                adapterLocale={language}
              >
                <DatePicker
                  label={t("endOfEffectiveDate")}
                  inputFormat="DD/MM/YYYY"
                  value={valueTo}
                  minDate={valueFrom}
                  onChange={handleChangeDateTo}
                  renderInput={(params) => <TextField {...params} />}
                  className={s.date}
                  disabled={isValid}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item>
              <Button
                className={s.btn}
                onClick={handleSubmit}
                disabled={isValid}
                sx={{ width: "100%", height: "100%", borderRadius: 2 }}
                color="primary"
                variant="contained"
              >
                {t("save")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
