import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styles from "./AgencieSelected.module.css"
import Column from "../assets/colums/columns.jsx"
import { createRow, createEditRow, createRowSelect, sortArrayFirstOwners, formatResultUsers } from "../../../../helpers/tableFunctions"
import { Modal } from "../../../Shared/Modal/Modal.jsx"
import Swal from "sweetalert2"
import { TableContainer, TableHead, Switch, TableRow, Button, TableBody, Table, Typography } from "@mui/material";
import { Grid, IconButton } from "@mui/material/";
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { t } from "i18next";
import HandleRequests from "../../../Shared/handleRequests/HandleRequests";
import { getAgencieById, editAgencieData, addRelationship, deleteRelationShip } from "../../../../api/agencies/AgenciesService";


const AgencieSelected = () => {

  const dispatch = useDispatch()
  const { name, id } = useParams();
  const typeDocument = ["C.U.I.L", "D.N.I", "Pasaporte", "Libreta de Enrolamiento"]
  const typePerson = ["Persona Física", "Persona Jurídica"]
  const [primaryColor, setPrimaryColor] = useState("");
  const [ligthPrimaryColor, setLightPrimaryColor] = useState("")
  const [subAgencieSelected, setSubAgencieSelected] = useState(null);
  const [secondaryColor, setSecondaryColor] = useState("");
  const [editData, setEditData] = useState({ street: "", zipcode: "", number: "", phone: "", documentNumber: "" });
  const [conditionalEditData, setConditionalEditData] = useState(true);
  const [rol, setRol] = useState(null)
  const [userToAdd, setUserToAdd] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [document, setDocument] = useState("");
  const [person, setPerson] = useState("");
  const [activeOrganization, setActiveOrganization] = useState(null)
  const navigate = useNavigate();
  const usersRedux = useSelector((state) => state?.gamma?.users?.content);
  const { textColor } = useSelector((state) => state?.local);
  const colors = useSelector((state) => state?.local?.uiConfig);
  const orgSelected = useSelector((state) => state.gamma.activeOrganization)
  const { concentratorSelectedRedux } = useSelector((state) => state.gamma)
  const Columns = Column()
  const backError = useSelector(state => state.errors.agencieSelectedError)

  let save = (subAgencieSelected?.phone || "0") !== editData?.phone || (subAgencieSelected?.address?.street || "0") !== editData?.street
    || (subAgencieSelected?.address?.zipcode || "0") !== editData?.zipcode || (subAgencieSelected?.address?.number || "0") !== editData?.number
    || (subAgencieSelected?.documentKind?.name || "No ingresado") !== document || (subAgencieSelected?.documentNumber || "0") !== editData?.documentNumber
    || (subAgencieSelected?.personKind?.name || "No ingresado") !== person

  let errors = editData?.phone === "" || editData?.street === "" || editData?.number === "" || editData.zipcode === ""
    || editData?.documentNumber === ""


  useEffect(() => {
    const makeSets = async () => {
      const subAgencie = await getAgencieById(id * 1, dispatch)
      setSubAgencieSelected(subAgencie);
      setPrimaryColor(colors?.primary?.main);
      setLightPrimaryColor(colors?.primary?.light)
      setSecondaryColor(colors?.secondary?.main);
      setEditData({
        street: subAgencie?.address?.street || "0",
        number: subAgencie?.address?.number || "0",
        zipcode: subAgencie?.address?.zipcode || "0",
        phone: subAgencie?.phone || "0",
        documentNumber: subAgencie?.documentNumber || "0"
      })
      setDocument(subAgencie?.documentKind?.name || "No ingresado")
      setPerson(subAgencie?.personKind?.name || "No ingresado")
      setActiveOrganization(orgSelected);
    }
    makeSets()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [concentratorSelectedRedux, colors, usersRedux, orgSelected, id]);


  const handleEditData = (e) => {
    setEditData({
      ...editData,
      [e.target.name]: e.target.value
    })
  }

  const handleSetStatus = async () => {
    let agencieToChangeStatus = { ...subAgencieSelected, organization: { id: orgSelected?.id, name: orgSelected?.name } }
    agencieToChangeStatus.status = agencieToChangeStatus.status === "DISABLED" ? "ENABLED" : "DISABLED"
    const response = await editAgencieData(agencieToChangeStatus)
      .then(() => {
        setSubAgencieSelected(agencieToChangeStatus)
        Swal.fire({
          icon: "question",
          title: t("userEditedSuccessful")
        })
      }).catch(() => {
        Swal.fire({
          icon: "error",
          title: t("anErrorOccurred,TryAgain")
        })
      })
  }


  const handleClose = () => {
    setOpenModal(false);
    setUserToAdd(null);
    setRol(null);
  };


  const handleSelect = (item) => {
    setUserToAdd(item);
  }


  const handleAddUser = async () => {
    if (!rol) {
      return
    } else if (subAgencieSelected?.relationships?.some(relation => relation.person.surname.toLowerCase() === userToAdd.lastname.toLowerCase() && relation.person.name.toLowerCase() === userToAdd.firstname.toLowerCase())) {
      setRol(null)
      setUserToAdd(null)
      setOpenModal(false)
      Swal.fire({
        icon: 'error',
        title: t('wereSorry'),
        text: t('youCantAddUserAgain'),
      })
    } else {
      let newRelationToAdd = {
        person: {
          name: userToAdd?.firstname,
          surname: userToAdd?.lastname,
          mail: userToAdd?.email,
          phone: "",
          organization: {
            id: activeOrganization?.id,
            name: activeOrganization?.name
          }
        },
        relationshipKind: rol
      };
      const response = await addRelationship(subAgencieSelected?.id, newRelationToAdd);
      if (response?.status === 200) {
        let relationShipAdded = await response?.json();
        let concentratorUpdated
        subAgencieSelected?.relationships?.length
          ? concentratorUpdated = {
            ...subAgencieSelected,
            relationships: [
              ...subAgencieSelected.relationships,
              { ...relationShipAdded }
            ]
          }
          : concentratorUpdated = {
            ...subAgencieSelected,
            relationships: [{ ...relationShipAdded }]
          }
        setSubAgencieSelected(concentratorUpdated);
        setUserToAdd(null)
        setOpenModal(false);
        setRol(null)
        Swal.fire({
          icon: "success",
          title: t("userAdded")
        })
      } else {
        setOpenModal(false);
        Swal.fire({
          icon: "error",
          title: t("sorryErrorOcurred")
        })
      }
    }
  }

  const handleDelete = async (id) => {
    let userToDestroy = subAgencieSelected?.relationships?.find(relation => relation.id === id);
    if ((subAgencieSelected?.relationships?.filter(relation => relation?.relationshipKind === "OWNER")).length <= 1 && userToDestroy.relationshipKind === "OWNER") {
      Swal.fire({
        icon: 'error',
        title: 'Lo sentimos',
        text: 'No ha sido posible eliminar al usuario debido a que la concentradora no puede no poseer un owner',
      })
    } else {
      const response = await deleteRelationShip(subAgencieSelected?.id, id);
      if (response?.status === 200) {
        let concentratorUpdated = {
          ...subAgencieSelected,
          relationships: subAgencieSelected?.relationships?.filter(relation => relation.id !== id)
        }
        setSubAgencieSelected(concentratorUpdated);
        Swal.fire({
          icon: 'success',
          title: 'Usuario eliminado',
          text: t("hasBeenSuccessfully"),
        })
      }
    }
  }

  const handleSaveData = async () => {
    if (save || !errors) {
      let concentratorEdited = {
        ...subAgencieSelected,
        address: {
          ...subAgencieSelected?.address,
          street: editData?.street,
          zipcode: editData?.zipcode,
          number: editData?.number
        },
        documentKind: {
          ...subAgencieSelected?.documentKind,
          name: document
        },
        personKind: {
          ...subAgencieSelected?.personKind,
          name: person
        },
        documentNumber: editData?.documentNumber,
        phone: editData?.phone,
        organization: {
          id: orgSelected?.id,
          name: orgSelected?.name
        }
      }
      await editAgencieData(concentratorEdited).then(() => {
        setSubAgencieSelected(concentratorEdited)
        setConditionalEditData(true)
        Swal.fire({
          icon: "success",
          title: t("dataSavedSuccessfully")
        })
      }).catch(() => {
        Swal.fire({
          icon: "error",
          title: t("anErrorOccurred,TryAgain")
        })
      })
    }
  }

  const titlesFilter2 = Columns?.selectedSubAgencieColumns?.filter(column => column.id !== "addRelation");
  console.log("titulo filtrado", titlesFilter2)


  return (
    <div className={styles.container}>
      <HandleRequests error={backError} loading={!subAgencieSelected}>
        <div className={styles.header}>
          <div className={styles.navigator}>
            <Grid container justifyContent="center" alignItems="center" width="100%" >
              <Grid container item xs={1}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <IconButton onClick={() => navigate(-1)}>
                  <ArrowBackIcon color={"primary"} sx={{
                    fontSize: '1.8rem',
                    cursor: "pointer",
                  }} />
                </IconButton>
              </Grid>
              <Grid item justifyContent="center" textAlign="center" alignItems="center" xs={1}>
                <div className={styles.title}>
                  <span>{subAgencieSelected?.subNumber || 0} </span>
                  <p />
                </div>
              </Grid>
              <Grid item justifyContent="center" textAlign="center" alignItems="center" display="flex" xs={8}>
                <div className={styles.title}>
                  <span>{` ${subAgencieSelected?.name}`}</span>
                  <p style={{ backgroundColor: primaryColor, marginLeft: 10, color: textColor, padding: 7 }}>{t('subagency')}</p>
                </div>
              </Grid>
              <Grid item justifyContent="center" textAlign="center" display="flex" alignItems="center" xs={2}>
                <span>{t('state')} </span>
                <Switch onClick={() => handleSetStatus()} checked={subAgencieSelected?.status === "DISABLED" ? false : true} sx={{ transform: "scale(1.3)", marginLeft: 1.4 }} disabled={conditionalEditData} />
              </Grid>
            </Grid>
          </div>

        </div>
        <div className={styles.tableContainer}>
          <TableContainer className={styles.table}>
            <Table>
              <TableHead className={styles.tableHead}>
                <TableRow sx={{ backgroundColor: primaryColor }}>
                  {
                    titlesFilter2?.map((column) => {
                      return createRow(column.label, column?.align, false, { color: textColor, fontSize: "0.9rem", fontWeight: 500, fontFamily: 'Roboto, sans-Serif' })
                    })
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {createEditRow(editData?.street, "street", false, handleEditData, editData, conditionalEditData)}
                  {createEditRow(editData?.number || "0", "number", false, handleEditData, editData, conditionalEditData)}
                  {createEditRow(editData?.zipcode, "zipcode", false, handleEditData, editData, conditionalEditData)}
                  {/* {createRow(<label onClick={() => handleSetStatus()}>
                <Switch checked={subAgencieSelected?.status === "DISABLED" ? false : true} sx={{transform: "scale(1.3)", marginLeft: 1.4}}/> 
                </label>, null, false)}  */}
                  {/* {createRow(
                  <Modal icon={<AddCircleOutlineIcon sx={{fontSize: 40, cursor: "pointer", color: primaryColor}}/>}
                    open={openModal}
                    onOpen={() => setOpenModal(true)}
                    onClose={handleClose}
                    title={t("userToAdd")}
                    keys={{ keys: ["firstname" , "lastname", "email"] }}
                    primaryColor={ligthPrimaryColor}
                    result={"email"}
                    format={formatResultUsers}
                    onSelect={handleSelect}
                    sx={{marginLeft: "30%"}}
                    component={"users"}
                    text={<div className={styles.modalDiv}>
                    {
                      userToAdd ? (
                        <div className={styles.addUserContainer}>
                           <FormControl sx={{width: "100%"}} error={!rol ? true : false}>
                              <InputLabel id="demo-simple-select-label">Puesto</InputLabel>
                              <Select
                                labelId={!rol ? "demo-simple-select-error-label" : "demo-simple-select-label"}
                                id={!rol ? "demo-simple-select-error" : "demo-simple-select"}
                                value={rol}
                                label="Puesto"
                                onChange={(e) => setRol(e.target.value)}
                              >
                              {
                                roles.map((r, i) => <MenuItem key={i} value={r}>{t(r.toLowerCase())}</MenuItem>)
                              }
                              </Select>
                              {
                                !rol ? <FormHelperText>{t("selectAPosition")}</FormHelperText> : null
                              }
                          </FormControl>
                            <span className={styles.nameAddUser}>{userToAdd.firstname} {userToAdd.lastname}</span>
                            <span className={styles.emailAddUser}>{userToAdd.email}</span>
                            <Tooltip title={!rol ? t("selectAPosition") : null}>
                              <Button variant="contained" sx={{marginTop: 2, backgroundColor: secondaryColor, color: "white", ":hover": `backgroundColor: ${primaryColor.main}`}} onClick={handleAddUser}>{t("add")}</Button>
                            </Tooltip>
                        </div>
                      ) : (
                        <h1>{t("selectUserToAdd")}</h1>
                      )
                    }
                  </div>}
                />, null, false)} */}
                  {createRow(<EditIcon sx={{
                    fontSize: '1.6rem', cursor: "pointer", color: primaryColor,
                    marginLeft: "15%"
                  }} onClick={() => {
                    if (!save && !errors) {
                      setConditionalEditData((b) => !b)
                    } else {
                      Swal.fire({
                        icon: "error",
                        text: t("pleaseSaveTheChanges")
                      })
                    }
                  }} />
                    , null, false)}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Typography variant="h6" gutterBottom component="div" sx={{ fontSize: '1.8rem', marginTop: 1, padding: 2, color: secondaryColor, width: "100%", fontWeight: 500 }}>
            {t("extraInfo")}:
          </Typography>
          <TableContainer className={styles.table}>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: primaryColor }}>
                  {
                    Columns?.extraInfoColumns?.map((column) => {
                      return createRow(column.label, column?.align, false, { color: textColor, fontSize: "0.9rem", fontWeight: 500, fontFamily: 'Roboto, sans-Serif' })
                    })
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {createEditRow(subAgencieSelected?.phone || "No ingresado", "phone", false, handleEditData, editData, conditionalEditData)}
                  {document && createRowSelect("Tipo de documento", document, typeDocument, setDocument, false, 1, conditionalEditData)}
                  {createEditRow(subAgencieSelected?.documentNumber, "documentNumber", false, handleEditData, editData, conditionalEditData)}
                  {person && createRowSelect("Tipo de persona", person, typePerson, setPerson, false, 2, conditionalEditData)}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Typography variant="h6" gutterBottom component="div" sx={{ fontSize: '1.8rem', marginTop: 1, padding: 1, color: secondaryColor, width: "90%", fontWeight: 500 }}>
            {
              subAgencieSelected?.relationships?.length ? `${t("withRelations")}:` : `${t("withoutRelations")}:`
            }
          </Typography>
          <TableContainer className={styles.table}>
            <Table>
              <TableHead>
                {
                  subAgencieSelected?.relationships?.length
                    ? <TableRow sx={{ backgroundColor: primaryColor }}>
                      {
                        Columns?.relationsShipsColumns?.map(subAgencie => {
                          return createRow(subAgencie.label, subAgencie?.align, false, { color: textColor, fontSize: "0.9rem", fontWeight: 500, fontFamily: 'Roboto, sans-Serif' })
                        })
                      }
                    </TableRow>
                    : <></>
                }
              </TableHead>
              <TableBody>
                {
                  subAgencieSelected?.relationships?.length
                    ? [...subAgencieSelected?.relationships].sort(sortArrayFirstOwners).map((employes, i) => {
                      return <TableRow align='center' key={i}>
                        {createRow(`${employes?.person?.name?.toUpperCase() || ""} ${employes?.person?.surname?.toUpperCase()}`, null, i % 2,)}
                        {createRow(employes.person.mail || "No ingresado", null, i % 2)}
                        {createRow(employes.person.phone || "No ingresado", null, i % 2)}
                        {createRow((employes.relationshipKind === "OWNER" ? t("owner") : t("salesman")), null, i % 2)}
                        {createRow(<IconButton sx={{ fontSize: '1.6rem', marginLeft: "35%" }} onClick={() => handleDelete(employes.id)}>
                          <DeleteOutlineOutlinedIcon sx={{ fontSize: '1.6rem', color: primaryColor }} />
                        </IconButton>, null, i % 2)}
                      </TableRow>
                    })
                    : <></>
                }
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className={styles.end}>
          {
            !conditionalEditData ? <Button
              variant="contained"
              onClick={handleSaveData}
              sx={{ width: "50%", height: "60%", backgroundColor: secondaryColor, color: textColor }}
              disabled={!save || errors}
            >
              {t("saveChanges")}
            </Button> : <></>
          }
        </div>
      </HandleRequests>
    </div>

  )
}

export default AgencieSelected;
