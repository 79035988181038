import { useState, useEffect } from "react";
import React from 'react'
import {
  Grid,
  Modal,
  TextField,
  Button,
  Box,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import styles from "../Premios.module.css";
import { useSelector } from "react-redux";
import s from "../ModalProviders/OrdersModal.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MovementsByImputation } from "../../../../api/accounting/accountingHelper";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { calcularDiferenciaFechas } from "../../../../helpers/calculateDifDate";
import { primeroDeOctubre } from "../../../../helpers/today";
import { RegexAgenciaNumero, minDateRange } from "../../../../config/strings";
import "dayjs/locale/es";
import "dayjs/locale/pt";
import Swal from "sweetalert2";
import { getHomologatedGames } from "../../../../api/general/generalService";
const ModalProviders = ({
  open,
  setOpen,
  filters,
  setFilters,
  setSearching,
  setSearch,
  tabValue,
  dateFrom,
  dateTo,
  setDateFrom,
  setDateTo,
  setSearchDay,
}) => {
  const { imputations } = useSelector((state) => state.gamma);
  const { t, i18n } = useTranslation();
  const [gameSelected, setGameSelected] = useState(-1);
  const [wrongDifDate, setWrongDifDate] = useState(false);
  const [prevDateFrom, setPrevDateFrom] = useState(filters.dateFrom);
  const [prevDateTo, setPrevDateTo] = useState(filters.dateTo);
  const [homologated_Games, setHomologated_Games] = useState([]);
  const agencieSelected = useSelector(
    (state) => state.myAgencies?.selected_agencie_to_consult
  );
  const agencies = useSelector((state) => state.myAgencies.user_agencies);
  const [desde, setDesde] = useState(() => new Date().setDate(1));
  const [hasta, setHasta] = useState(() => new Date());
  useEffect(() => {
    getGame();
  }, []);

  useEffect(() => {
    const dateFromFormat = JSON.stringify(dateFrom)
      .substring(0, 11)
      .split('"')[1];
    const dateToFormat = JSON.stringify(dateTo).substring(0, 11).split('"')[1];

    if (dateFrom && dateTo) {
      let diff = calcularDiferenciaFechas(dateFromFormat, dateToFormat);
      if (diff > 91) {
        setWrongDifDate(true);
        Swal.fire({
          title: t("wrongDates"),
          icon: "error",
        });
      } else {
        setWrongDifDate(false);
      }
    }
  }, [dateFrom, dateTo]);

  function buscar() {
    if (prevDateFrom !== filters?.dateFrom || prevDateTo !== filters?.dateTo) {
      setSearchDay(true);
    } else {
      setPrevDateFrom(filters?.dateFrom);
      setPrevDateTo(filters?.dateTo);
    }
    setOpen(false);
    setSearch(true);
  }

  const handleChangeDateFrom = (date_picked) => {
    setDesde(date_picked);
    setFilters((prevState) => ({
      ...prevState,
      from: `${date_picked.$D < 10 ? "0" : ""}${date_picked.$D}/${date_picked.$M + 1 < 10 ? "0" : ""
        }${date_picked.$M + 1}/${date_picked.$y}`,
    }));
    if (tabValue == "Resumen") {
      setHasta(date_picked);
      setDateTo(
        `${date_picked.$D < 10 ? "0" : ""}${date_picked.$D}/${date_picked.$M + 1 < 10 ? "0" : ""
        }${date_picked.$M + 1}/${date_picked.$y}`
      );
      setFilters((prevState) => ({
        ...prevState,
        to: `${date_picked.$D < 10 ? "0" : ""}${date_picked.$D}/${date_picked.$M + 1 < 10 ? "0" : ""
          }${date_picked.$M + 1}/${date_picked.$y}`,
      }));
    }
    setDateFrom(
      `${date_picked.$y}-${date_picked.$M + 1 < 10 ? "0" : ""}${date_picked.$M + 1
      }-${date_picked.$D < 10 ? "0" : ""}${date_picked.$D}T${date_picked.$H < 10 ? "0" : ""
      }${date_picked.$H}:${date_picked.$m < 10 ? "0" : ""}${date_picked.$m}:${date_picked.$s < 10 ? "0" : ""
      }${date_picked.$s}`
    );
    console.log("FILTER FROM despues del proceso: ", filters?.from);
  };

  const handleChangeDateTo = (date_picked) => {
    setHasta(date_picked);
    setDateTo(
      `${date_picked.$D < 10 ? "0" : ""}${date_picked.$D}/${date_picked.$M + 1 < 10 ? "0" : ""
      }${date_picked.$M + 1}/${date_picked.$y}`
    );
    setFilters((prevState) => ({
      ...prevState,
      to: `${date_picked.$D < 10 ? "0" : ""}${date_picked.$D}/${date_picked.$M + 1 < 10 ? "0" : ""
        }${date_picked.$M + 1}/${date_picked.$y}`,
    }));
  };

  const handleGameChange = (event) => {
    setGameSelected(event.target.value);
    setFilters((prevState) => ({ ...prevState, game: event.target.value }));
  };

  const handleSwitchByOthers = (value) => {
    setFilters({ ...filters, byOthers: !filters.byOthers });
  };

  function getGame() {
    getHomologatedGames()
      .then((r) =>
        r.filter(
          (juego) =>
            juego.integration[0].organization.id ===
            agencies[0].organization.id &&
            juego.integration[0].module === "SAA"
        )
      )
      .then((res) => {
        res.sort((a, b) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        setHomologated_Games(res);
      });
  }

  const handcleApplyFilter = () => {
    setOpen(false);
    setSearching(true);
    setSearch(true);
  };
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Grid container>
        <Grid
          xs={12}
          md={8}
          lg={4.5}
          item
          container
          className={s.boxModal}
          sx={{ bgcolor: "#EEEEEE" }}
        >
          <Grid item xs={10} className={s.exit}>
            <div className={s.titleModal}>
              <p>{t("filter")}</p>
              <span>{t("filterConfig")}</span>
            </div>
            <CloseIcon
              onClick={() => setOpen(false)}
              className={s.exitIcon}
              sx={{ color: "primary.dark" }}
            />
          </Grid>

          <Grid container spacing={1} className={s.filters}>
            <Grid sx={{ fontSize: "1rem", marginBottom: 2 }} item xs={10}>
              {t(tabValue)}
            </Grid>
            <Grid
              item
              container
              xs={10}
              sx={{ justifyContent: "space-between" }}
            >
              <Grid item xs={5.5}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={i18n.language}
                  className={styles.superdate}
                >
                  <DatePicker
                    defaultValue={new Date().setDate(1)}
                    value={desde}
                    label={tabValue != "Resumen" ? t("from") : ""}
                    inputFormat="DD/MM/YYYY"
                    inputProps={{ readOnly: true }}
                    onChange={handleChangeDateFrom}
                    renderInput={(params) => <TextField {...params} />}
                    className={styles.date}
                    minDate={primeroDeOctubre()}
                    maxDate={new Date()}
                  />
                </LocalizationProvider>
              </Grid>
              {tabValue != "Resumen" && (
                <Grid item xs={5.5}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={i18n.language}
                    className={styles.superdate}
                  >
                    <DatePicker
                      value={hasta}
                      label={t("to")}
                      inputFormat="DD/MM/YYYY"
                      inputProps={{ readOnly: true }}
                      onChange={handleChangeDateTo}
                      renderInput={(params) => <TextField {...params} />}
                      className={styles.date}
                      minDate={dateFrom}
                    />
                  </LocalizationProvider>
                </Grid>
              )}
            </Grid>
            <Grid sx={{ marginTop: 2 }} item xs={10}>
              <Box sx={{ fontSize: "1rem", marginBottom: 1 }}>{t("game")}</Box>
              <Box sx={{ minWidth: 120 }}>
                <FormControl sx={{ width: "45%" }} size="small">
                  <Select
                    value={gameSelected}
                    displayEmpty
                    onChange={handleGameChange}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value={-1}>
                      <em>{t("all")}</em>
                    </MenuItem>
                    {Array.isArray(homologated_Games) &&
                      homologated_Games
                        ?.sort((a, b) => a.name.localeCompare(b.name))
                        ?.map((item) => (
                          <MenuItem key={item.id} value={item.ownerId}>
                            {item.name}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>

          <Grid
            item
            xs={10}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              my: "30px",
              cursor: "not-allowed",
            }}
          >
            <Button
              disabled={
                (filters.agencieName === "" && filters.agencieNumber === "") ||
                agencieSelected === {} ||
                wrongDifDate
              }
              variant="contained"
              onClick={() => {
                handcleApplyFilter();
              }}
            >
              {t("apply")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ModalProviders;
