// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.indicator {
    border: '1px';
    border-style: dashed;
    padding: 20px;

}`, "",{"version":3,"sources":["webpack://./src/components/Pages/Main/index.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,oBAAoB;IACpB,aAAa;;AAEjB","sourcesContent":[".container {\n    height: 90vh;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.indicator {\n    border: '1px';\n    border-style: dashed;\n    padding: 20px;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
