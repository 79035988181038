import { createSlice } from "@reduxjs/toolkit";
import { getAgencieReport } from "../../api/movement/MovementService.js";
import { yesterday, today, EmptyMovement } from "../../config/strings.js";
import { setMy_AgenciesError } from "./errors";
import {
  getAgencieById,
  getAgencieByIdAwait,
} from "../../api/agencies/AgenciesService.jsx";
import { useSelector } from "react-redux";

const initialState = {
  agencies_favs: [],
  user_agencies: [],
  my_movements: [],
  total_movement: [],
  selected_agencie_to_consult: {},
  sale_points: [],
  selected_concentrator: {},
  analisisUsers: true,
};

const localSlice = createSlice({
  name: "myAgencies",
  initialState,
  reducers: {
    setSalePoints: (state, action) => {
      state.sale_points = action.payload;
    },

    setAgenciesById: (state, action) => {
      state.user_agencies = action.payload;
    },
    setTotalMovementById: (state, action) => {
      state.total_movement = action.payload;
    },
    setMy_Movements: (state, action) => {
      state.my_movements = action.payload;
    },
    clearMyAgencies(state, action) {
      state.user_agencies = [];
    },
    addFavAgencie(state, action) {
      if (
        !state.agencies_favs.some(
          (agencie) => agencie?.id === action.payload.id
        )
      ) {
        state.agencies_favs = [...state.agencies_favs, action.payload];
      }
    },
    setFavAgencies(state, action) {
      state.agencies_favs = action.payload;
    },
    removeFavAgencie(state, action) {
      state.agencies_favs = state.agencies_favs.filter(
        (favAgencie) => favAgencie?.id !== action.payload
      );
    },
    setSelectedAgencieToConsult(state, action) {
      state.selected_agencie_to_consult = action.payload;
    },
    resetAgenciesSlice(state, action) {
      state.agencies_favs = []
      state.user_agencies = []
      state.my_movements = []
      state.total_movement = []
      state.selected_agencie_to_consult = {}
      state.sale_points = []
      state.selected_concentrator = {}
      state.analisisUsers = true
    },
    setConcentrator: (state, action) => {
      state.selected_concentrator = action.payload;
    },
    alreadyAnalisis(state, action) {
      state.analisisUsers = action.payload;
    },
  },
});

export const callSalePointsById = (ids) => async (dispatch) => {
  try {
    const agencies = await Promise.all(
      ids?.map((id) => getAgencieById(id * 1, dispatch))
    );
    dispatch(setSalePoints(agencies));
  } catch (e) {
    console.log(e);
  }
};

export const callAgenciesFavsById = (ids) => async (dispatch) => {
  try {
    const agencies = await Promise.all(
      ids?.map((id) => getAgencieById(id * 1, dispatch))
    );
    dispatch(setFavAgencies(agencies));
  } catch (e) {
    console.log(e);
  }
};

export const callAgeniesById = (ids, agencySelected) => async (dispatch) => {
  try {
    let errorCounter = 0;
    let firstError = null;

    // Fetch all agencies by IDs, allowing some to fail without rejecting the entire promise
    const agencies = await Promise.allSettled(
      ids.map((id) =>
        getAgencieByIdAwait(id).then(
          (data) => data,
          (error) => {
            errorCounter++;
            firstError = error; // Capture the first error for later use
            return null; // Return null for failed requests
          }
        )
      )
    );

    // Filter out failed requests
    const successfulAgencies = agencies
      .filter(result => result.status === "fulfilled" && result.value)
      .map(result => result.value);

    if (errorCounter !== ids.length) {
      dispatch(setMy_AgenciesError({ error: false }));

      if (!agencySelected?.id && successfulAgencies.length > 0) {
        dispatch(setSelectedAgencieToConsult(successfulAgencies[0]));
      }

      dispatch(setAgenciesById(successfulAgencies));
    } else {
      throw new Error(firstError?.status || firstError?.message || "Unknown Error");
    }
  } catch (e) {
    console.error(e);
    dispatch(setMy_AgenciesError({ error: true, code: e.status || e.message }));
  }
};

// export const callTotalById = (ids) => async (dispatch) => {
//   try{
//     const total = await Promise.all(ids?.map(id => {
//      return getTotalMovements(id)
//       .then(res => {
//         if(res.status === 200){
//           return res.json();
//         }else{
//           //TODO HAY QUE REFACTORIZAR
//           return ({
//             total: {
//               debit: 0,
//               credit: 0
//             },
//             subTotal: {
//               debit: 0,
//               credit: 0
//             }
//           })
//         }
//       })
//     }))
//     dispatch(setTotalMovementById(total))
//   }catch(e){
//     console.log(e)
//   }
// }

// export const callMovementsById = (ids) => async (dispatch) => {
//   try{
//     const movements = await Promise.all(ids?.map(id => {
//       return getMovementsDay(id,1,yesterday(),today()).then ( res => {
//         if (res.status === 200){
//           return res.json();
//         }
//         else{
//           return EmptyMovement;
//         }
//       })
//     }))
//     dispatch(setMy_Movements(movements?.map(m=> m.content)))
//   }catch(e){
//     console.log(e)
//   }
// }
export const {
  setSalePoints,
  setAgenciesById,
  setTotalMovementById,
  clearMyAgencies,
  setMy_Movements,
  setFavAgencies,
  addFavAgencie,
  removeFavAgencie,
  setSelectedAgencieToConsult,
  resetAgenciesSlice,
  setConcentrator,
  alreadyAnalisis,
} = localSlice.actions;

export default localSlice.reducer;
