import { createSlice } from '@reduxjs/toolkit'

export const initialSession = {
  accessToken: null,
  expiresIn: null,
  refreshesExpiresIn: null,
  refreshToken: null,
  tokenType: null,
  sessionState: null,
  scope: null,
  decodedToken: null
}

export const sessionSlice = createSlice({
  name: 'session',
  initialState: initialSession,
  reducers: {
    updateSession: (state, action) => {
      state.sessionState = action.payload.sessionState
      state.scope = action.payload.scope
      state.tokenType = action.payload.tokenType
      state.refreshToken = action.payload.refreshToken
      state.expiresIn = action.payload.expiresIn
      state.refreshesExpiresIn = action.payload.refreshesExpiresIn
      state.accessToken = action.payload.accessToken
      state.decodedToken = action.payload.decodedToken
    },

    deleteSession: (state) => {
      state.sessionState = null
      state.scope = null
      state.tokenType = null
      state.refreshToken = null
      state.expiresIn = null
      state.refreshesExpiresIn = null
      state.accessToken = null
      state.decodedToken = null
    },

    updateAccessToken: (state, action) => {
      state.accessToken = action.payload
    }
  }
})

export const { updateSession, deleteSession, updateAccessToken } = sessionSlice.actions

export default sessionSlice.reducer
