// import decodj
import { jwtDecode } from "jwt-decode"
// import redux store
import store from "../store"
// import utils
import {
  getOrganizationId,
  isSessionExpired,
  logOutSession
} from "./session.utils"
import authService from "../services/auth.service";
import { updateAccessToken, updateSession } from "../store/slices/session";

const refreshToken = async () => {
  await authService.refreshToken()
    .then((data) => {
      const tokenDecoded = jwtDecode(data.accessToken);
      data.decodedToken = tokenDecoded

      store.dispatch(updateSession(data))
    }).catch((error) => {
      if (error instanceof Error) {
        throw error
      } else {
        throw new Error(`Error refreshing token ${error}`);
      }
    })
}

/** Intercept the request to verify whether the session has expired or not
 *
 * @param {any} request - the request object
 * */
export const axiosRequestInterceptor = async (request) => {
  const session = store.getState().session;
  const token = session.accessToken;

  // Check if token exists before proceeding
  if (token) {
    const tokenDecoded = jwtDecode(token);
    const isExpired = isSessionExpired(tokenDecoded.exp);

    const logOut = async () => {
      // Trigger logout and state reset
      await authService.logOut()
        .then(() => {
          store.dispatch(updateAccessToken(null));
        })
        .catch((error) => {
          console.log('Error during logout:', error)
        });
    }

    if (isExpired) {
      try {
        logOut()
      } catch (error) {
        console.log(error)
      }
    } else {
      refreshToken()
        .catch(error => (
          console.log('Refresh token error: ', error)
        ))
    }

    // Attach the token to the request header
    request.headers["Authorization"] = `Bearer ${token}`;
    request.baseURL = `/${getOrganizationId()}`;
  } else {
    console.log('No token available');
  }

  return request;
};

export const axiosResponseInterceptor = (error) => {
  try {
    if (error.response.status === 401) {
      console.log(error)
    }
  } catch (error) {
    console.log(error)
  }

  return Promise.reject(error)
}
