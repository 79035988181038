import React, { useState, useEffect } from "react";
import s from "./ControlCaja.module.css";
import { useNavigate } from "react-router-dom";
import {
  setConcentrator,
  setSelectedAgencieToConsult,
} from "../../../store/slices/my_agencies";
import { homologatedGames } from "../../../store/slices/gammaSlice";
//helper
import { lastWeek, PrimerDiaDelMes, today } from "../../../helpers/today";

//material ui
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  Button,
  TableRow,
  TableCell,
  TableBody,
  Box,
} from "@mui/material";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import {
  createPrizeRow,
  createPrizeRowMoney,
  createPrizeRowCenter,
} from "../../../helpers/tableFunctions";

import Swal from "sweetalert2";

//translation
import { useTranslation } from "react-i18next";

//react-redux
import { useSelector, useDispatch } from "react-redux";

//datePickers

//icons
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import NewspaperOutlinedIcon from "@mui/icons-material/NewspaperOutlined";
import ModalProviders from "./ModalProviders";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import {
  getCashControl,
  getTJcontrol,
} from "../../../api/saaservices/cashFlow";
import { getHomologatedGames } from "../../../api/general/generalService";
import { CalendarioHoy, CalendarioSemanaPasada } from "../../../helpers/today";
import { exportExcel, exportPDF } from "../../../helpers/exportExcel";
import { Currency, numberTwoDecimals } from "../../../helpers/currencyConvert";
import { useLocation } from "react-router-dom";
import AgencySelectModal from "../../Shared/AgencySelectModal/AgencySelectModal";
import CircularProgress from "@mui/material/CircularProgress";
import {
  AgencyPage,
  getPresentAgencyInPath,
  logOutPath,
  hasAgencyInPath,
  getAgencyNumberDisplay,
} from "../../../helpers/path";
import { currencyFormat } from "../../../helpers/currencyConvert";
import printTable from "../../../helpers/imprimir";
import { byNumberAndSubNumber } from "../../../helpers/agencies";
//import { minDateRange } from '../../../config/strings';
import { agencieSearchByNumber } from "../../../api/agencies/AgenciesService";
import { updateLocation } from "../../../store/slices/localSlice";
import { getSaaId } from "../../../utils/session.utils";

export const ControlCaja = () => {
  const [isReady, setIsReady] = useState(true);
  const { t } = useTranslation();
  const { currentLogo } = useSelector((state) => state.local);
  const [controlCaja, setControlCaja] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const agencies = useSelector((state) => state.myAgencies.user_agencies);
  const { activeOrganization } = useSelector((state) => state.gamma);
  const navigate = useNavigate();
  const agencieSelected = useSelector(
    (state) => state.myAgencies?.selected_agencie_to_consult
  );
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState();
  const [printing, setPrinting] = useState(false);
  //Date Pickers state
  const [search, setSearch] = useState(false);
  const [date, setDate] = useState(PrimerDiaDelMes());
  const [loading, setLoading] = useState(true);
  const [searching, setSearching] = useState(false);
  const [filters, setFilters] = useState({
    date: today(),
    to: today(),
    from: lastWeek(),
    game: "All",
  });
  const [loadingAgency, setLoadingAgency] = useState(false);
  const { textColor } = useSelector((state) => state.local);
  const location = useLocation();
  console.log("-----------");
  console.log(location);
  console.log("-----------");
  //handles
  const { formatCurrency } = currencyFormat();
  useEffect(() => {
    dispatch(updateLocation({ controlcaja: true }));
  }, []);

  const negativeTypes = ["Premios Pagados"];

  useEffect(() => {
    if (printing === true) {
      printTable(document.getElementById("controlCaja-table"));
    }
    setPrinting(false);
  }, [printing]);

  async function loadCashControl(agencia, subagencia) {
    const saaId = getSaaId()
    try {
      let mov = await getCashControl(
        saaId,
        filters?.date,
        agencia,
        subagencia
      );
      return mov;
    } catch (error) {
      console.log(error);
    }
  }

  function mapToItems(items) {
    return (items || []).map((item) => {
      if (negativeTypes.includes(item.tipo)) {
        item.importe = -item.importe;
      }
      return item;
    });
  }

  function attemptLoadControlCaja() {
    setLoading(true);
    loadControlCaja().then((controlCaja) => {
      setControlCaja(mapToItems(controlCaja));
      setLoading(false);
    });
  }

  async function loadControlCaja() {
    if (agencieSelected) {
      setControlCaja([]);
      const subAgencia =
        agencieSelected.agencyLevel === "CONCENTRATOR"
          ? -1
          : agencieSelected.subNumber;
      if (subAgencia != null) {
        return loadCashControl(agencieSelected.number, subAgencia);
      }
    }
    return [];
  }

  function getSortedControlCaja() {
    console.log(controlCaja);
    return (controlCaja || [])
      .sort((a, b) => {
        if (a.juegodescripcion < b.juegodescripcion) {
          return -1;
        }
        if (a.juegodescripcion > b.juegodescripcion) {
          return 1;
        }
        return 0;
      })
      .sort((a, b) => a.codigoMaquina - b.codigoMaquina)
      .sort((a, b) => byNumberAndSubNumber(a.puntoDeVenta, b.puntoDeVenta));
  }

  useEffect(() => {
    if (search) {
      attemptLoadControlCaja();
    }
    setSearch(false);
  }, [search, agencieSelected]);

  useEffect(() => {
    setControlCaja([]);
    const path = location.pathname.split("/");
    console.log("-----------");
    console.log(path);
    console.log("-----------");
    if (hasAgencyInPath(path)) {
      const agency = getPresentAgencyInPath(path, agencies);
      if (agency === null) {
        navigate('/logout');
      } else {
        dispatch(setSelectedAgencieToConsult(agency));
        attemptLoadControlCaja();
      }
    }
  }, []);

  const excelItem = () => {
    let excelData = [];
    getSortedControlCaja().map((item) => {
      const itemExcel = {};
      itemExcel[t("subagency")] = createPrizeRow({
        content: item.puntoDeVenta,
        color: "#FDFDFD",
      }).props.children;
      itemExcel[t("machine")] = createPrizeRow({
        content: item.codigoMaquina,
        color: "#FDFDFD",
      }).props.children;
      itemExcel[t("game")] = createPrizeRow({
        content: item?.juegodescripcion,
        color: "#FDFDFD",
      }).props.children;
      itemExcel[t("status")] = createPrizeRow({
        content: item.tipo,
        color: "#FDFDFD",
      }).props.children;
      itemExcel[t("amount")] = createPrizeRow({
        content: item.cantidad,
        color: "#FDFDFD",
      }).props.children;
      itemExcel[t("totalCollected")] = createPrizeRow({
        content: numberTwoDecimals(item.importe),
        color: "#FDFDFD",
      }).props.children;
      excelData.push(itemExcel);
    });
    const itemExcel = {};
    itemExcel[t("subagency")] = t("subtotal");
    itemExcel[t("amount")] = controlCaja.reduce(
      (acc, b) => acc + b.cantidad,
      0
    );
    const totalControlRegister = controlCaja.reduce((acc, b) => acc + b.importe, 0)
    itemExcel[t("totalCollected")] = numberTwoDecimals(totalControlRegister);
    excelData.push(itemExcel);
    return excelData;
  };

  const exportToExcel = () => {
    Swal.fire({
      title: t("excelConfirm"),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "#d33",
      cancelButtonText: t("cancel"),
      confirmButtonText: t("accept"),
    }).then((result) => {
      if (result.isConfirmed) {
        exportExcel(excelItem(), t("cashControl"));
      }
    });
  };

  const handleOpen = () => setOpenModal(true);

  function showHide() {
    setShowModal(!showModal);
  }

  const handleConsulting = (agencie) => {
    if (agencie) {
      if (location.pathname.includes(AgencyPage.ControlCaja)) {
        agencieSearchByNumber(activeOrganization?.id, {
          number: agencie?.number,
        })
          .then((response) => {
            dispatch(setConcentrator(response));
            dispatch(setSelectedAgencieToConsult(agencie));
            setLoadingAgency(false);
          })
          .then(() => {
            navigate(
              `${AgencyPage.ControlCaja}/${getAgencyNumberDisplay(agencie)}`
            );
            setSearch(true);
          })
          .catch((e) => {
            console.log(e);
            setLoadingAgency(false);
          });
      }
    }
  };

  return isReady ? (
    <Grid container className={s.container}>
      <Grid item container xs={11} className={s.header}>
        <Grid item lg={6} md={6} xs={12} className={s.titleContainer}>
          <AttachMoneyIcon sx={{ fontSize: "1.8rem", color: "primary.main" }} />
          <h4>{t("cashControl")}</h4>
        </Grid>

        <Grid item xs={12} md={6} lg={6} className={s.filters}>
          <Button
            disabled={!agencieSelected}
            variant="contained"
            className={s.filterButton}
            onClick={() => setOpenModal(true)}
          >
            <TuneRoundedIcon
              sx={{ color: "secondary.light", fontSize: "1.8rem" }}
            />
            <p style={{ fontSize: "0.9rem" }}>{t("filter")}</p>
          </Button>
          {searching ? (
            <Button
              variant="contained"
              sx={{ ml: 1 }}
              className={s.filterButton}
              onClick={() => {
                setFilters((prevState) => ({ ...prevState, date: today() }));
                setFilters((prevState) => ({ ...prevState, game: "All" }));
                setDate(CalendarioHoy());
                setControlCaja([]);
                setSearching(false);
                setSearch(false);
              }}
            >
              <p style={{ fontSize: "0.9rem" }}>{t("cleanFilters")}</p>
            </Button>
          ) : null}
          {loadingAgency ? (
            <Box sx={{ mx: 7 }}>
              <CircularProgress size="2rem" />
            </Box>
          ) : (
            <Button
              sx={{ ml: 1 }}
              onClick={showHide}
              color="primary"
              className={s.filterButton}
              variant="contained"
            >
              <p style={{ fontSize: "0.7rem" }}>{t("selectAgency")}</p>
            </Button>
          )}
        </Grid>
      </Grid>

      <Grid className={s.selectAgency} item xs={11}>
        <p style={{ fontSize: "1.3rem", fontWeight: "300" }}>
          {agencieSelected?.name
            ? `${t("salePoint")}: (${agencieSelected.number}${agencieSelected.subNumber !== null
              ? " - " + agencieSelected.subNumber
              : ""
            }) ${agencieSelected.name}`
            : t("agencyNotSelected")}
        </p>
        {showModal && (
          <AgencySelectModal
            open={showModal}
            setOpen={setShowModal}
            onSelect={handleConsulting}
          />
        )}
      </Grid>

      <Grid
        item
        container
        xs={11}
        sx={{ mt: 3, justifyContent: "flex-end", mb: 3 }}
      >
        <Button
          disabled={!controlCaja?.length}
          size="small"
          variant="contained"
          sx={{ mr: 1, height: "2.5rem" }}
          className={s.optionButton}
          onClick={() => setPrinting(true)}
        >
          <PrintOutlinedIcon
            sx={{ fontSize: "1.5rem", color: textColor }}
            className={s.iconOptions}
          />
        </Button>

        <Button
          size="small"
          variant="contained"
          sx={{ height: "2.5rem" }}
          className={s.optionButton}
          onClick={() => exportToExcel()}
        >
          <NewspaperOutlinedIcon
            sx={{ fontSize: "1.5rem", color: textColor }}
            className={s.iconOptions}
          />
        </Button>
      </Grid>

      {loading ? (
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "300px",
          }}
        >
          <CircularProgress size="3rem" />
        </Grid>
      ) : (
        <>
          {controlCaja?.length ? (
            <Grid
              item
              container
              xs={11}
              className={s.infoContainer}
              id="controlCaja-table"
            >
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                {printing !== true ? (
                  <p
                    className="texto"
                    style={{
                      margin: "0 5px 0 0",
                      fontSize: "1rem",
                      fontWeight: 600,
                    }}
                  >
                    {t("date")}: {filters?.date}
                  </p>
                ) : (
                  <div>
                    {currentLogo?.imgBase64.length ? (
                      <img
                        className={s.headerLogo}
                        src={currentLogo?.imgBase64}
                        alt="logo"
                      />
                    ) : (
                      <></>
                    )}
                    <h4 className={`${s.date} ${"texto"}`}>
                      {t("cashControl")}
                    </h4>
                    <h4 className={`${s.date} ${"texto"}`}>
                      {t("date")}:{" "}
                      <span className={`${s.inputationDate} ${"texto"}`}>
                        {filters?.date}
                      </span>
                    </h4>
                  </div>
                )}
              </Grid>
              <TableContainer className={s.tableContainer}>
                <Table className="print-table" stickyHeader>
                  <TableHead>
                    <TableCell
                      sx={{
                        backgroundColor: "primary.main",
                        color: textColor,
                        fontSize: "0.9em",
                        fontWeight: 500,
                      }}
                      className={s.tableCell}
                      align="center"
                    >
                      {t("agency")}
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: "primary.main",
                        color: textColor,
                        fontSize: "0.9em",
                        fontWeight: 500,
                      }}
                      className={s.tableCell}
                      align="center"
                    >
                      {t("machine")}
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: "primary.main",
                        color: textColor,
                        fontSize: "0.9em",
                        fontWeight: 500,
                      }}
                      className={s.tableCell}
                      align="center"
                    >
                      {t("game")}
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: "primary.main",
                        color: textColor,
                        fontSize: "0.9em",
                        fontWeight: 500,
                      }}
                      className={s.tableCell}
                      align="center"
                    >
                      {t("concept")}
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: "primary.main",
                        color: textColor,
                        fontSize: "0.9em",
                        fontWeight: 500,
                      }}
                      className={s.tableCell}
                      align="center"
                    >
                      {t("amount")}
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: "primary.main",
                        color: textColor,
                        fontSize: "0.9em",
                        fontWeight: 500,
                      }}
                      className={s.tableCell}
                      align="right"
                    >
                      {t("monetaryAmount")}
                    </TableCell>
                  </TableHead>
                  <TableBody>
                    {Array.isArray(controlCaja) &&
                      getSortedControlCaja(controlCaja).map((item, i) => (
                        <TableRow key={i}>
                          {createPrizeRowCenter({
                            content: item?.puntoDeVenta,
                            color: "#FDFDFD",
                          })}
                          {createPrizeRowCenter({
                            content: item?.codigoMaquina,
                            color: "#FDFDFD",
                          })}
                          {createPrizeRowCenter({
                            content: item?.juegodescripcion,
                            color: "#FDFDFD",
                          })}
                          {createPrizeRowCenter({
                            content:
                              item?.tipo === "Cupones"
                                ? "Validos"
                                : item?.tipo === "Premios Pagados"
                                  ? "Pagados"
                                  : item?.tipo,
                            color: "#FDFDFD",
                          })}
                          {createPrizeRowCenter({
                            content: item?.cantidad,
                            color: "#FDFDFD",
                          })}
                          {createPrizeRowMoney({
                            content: <Currency value={item?.importe} />,
                            color: "#FDFDFD",
                          })}
                        </TableRow>
                      ))}
                    <TableRow>
                      <TableCell sx={{ fontSize: "0.9em", fontWeight: 600 }}>
                        Validos
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell sx={{ fontSize: "0.9em", fontWeight: 600 }} align="center">
                        {Array.isArray(controlCaja) &&
                          controlCaja.filter(cc => cc.tipo == "Validos").reduce((acc, b) => acc + b?.cantidad, 0)
                        }
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: "0.9em", fontWeight: 600 }}
                        align="right"
                      >
                        <Currency
                          value={
                            Array.isArray(controlCaja) &&
                            controlCaja.filter(cc => cc.tipo == "Validos").reduce((acc, b) => acc + b?.importe, 0)
                          }
                        />

                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontSize: "0.9em", fontWeight: 600 }}>
                        Pagados
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell sx={{ fontSize: "0.9em", fontWeight: 600 }} align="center">
                        {Array.isArray(controlCaja) &&
                          controlCaja?.filter(cc => cc.tipo == "Pagados").reduce((acc, b) => acc + b?.cantidad, 0)}
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: "0.9em", fontWeight: 600 }}
                        align="right"
                      >
                        <Currency
                          value={
                            Array.isArray(controlCaja) &&
                            controlCaja.filter(cc => cc.tipo == "Pagados").reduce((acc, b) => acc + b?.importe, 0)
                          }
                        />

                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontSize: "0.9em", fontWeight: 600 }}>
                        {t("subtotal")}
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell sx={{ fontSize: "0.9em", fontWeight: 600 }} align="center">
                        {Array.isArray(controlCaja) &&
                          controlCaja?.reduce((acc, b) => acc + b?.cantidad, 0)}
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: "0.9em", fontWeight: 600 }}
                        align="right"
                      >
                        <Currency
                          value={
                            Array.isArray(controlCaja) &&
                            controlCaja?.reduce((acc, b) => acc + b?.importe, 0)
                          }
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          ) : (
            <Grid
              item
              xs={11}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "200px",
              }}
            >
              <p style={{ fontSize: "1.8rem", fontWeight: 300 }}>
                {t("noResults")}
              </p>
            </Grid>
          )}
        </>
      )}
      <ModalProviders
        open={openModal}
        setOpen={setOpenModal}
        setFilters={setFilters}
        filters={filters}
        setSearching={setSearching}
        setSearch={setSearch}
        date={date}
        setDate={setDate}
      />
    </Grid>
  ) : (
    <Grid container className={s.container}>
      <Grid item container xs={11} className={s.header}>
        <Grid item lg={6} md={6} xs={12} className={s.titleContainer}>
          <h4>Próximamente.</h4>
        </Grid>
      </Grid>
    </Grid>
  );
};
