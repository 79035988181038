import { Button } from "@mui/material";
import React from 'react'
import { useSelector, useDispatch } from "react-redux";
import { useKeycloak } from "@react-keycloak/web";
import { roles } from "../../../config/strings";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.css";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import { useState } from "react";
import { setActiveOrganization } from "../../../store/slices/gammaSlice";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import selectOrganizationImage from "../../../assets/select-organization.svg"

export const Organizations = () => {
  const { legalTerms } = useSelector((state) => state.local);
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const [selectedLotery, setSelectedLotery] = useState("");
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.gamma.logged_user);

  const goToNextStep = () => {
    if (keycloak.realmAccess.roles.includes(roles.administradorLoteria)) navigate(`../admin/users`)
    else {
      legalTerms.accepted
        ? navigate(`../main/panel`)
        : navigate("../terms")
    }
  };

  const organizationSelected = (e) => {
    setSelectedLotery(e.target.value);
    dispatch(setActiveOrganization(e.target.value));
  };

  return (
    <div className={styles.container}>
      <img className={styles.selectOrganizationImage} src={selectOrganizationImage} alt="Seleccionar Organización" />
      <h1 className={styles.title}>Seleccionar Organización</h1>
      <div className={styles.container_terminos}>
        <FormControl fullWidth>
          <InputLabel id="select-label">Organización</InputLabel>
          <Select
            labelId="select-label"
            id="select"
            sx={{ width: 300 }}
            value={selectedLotery.name}
            onChange={organizationSelected}
            label="Organización"
          >
            {currentUser?.memberships?.map((item, i) => {
              return (
                <MenuItem key={i} value={item.organization}>
                  {item.organization.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Grid justifyContent="center" container>
          <Grid item xs={4}>
            <Button
              onClick={goToNextStep}
              variant="contained"
              sx={{ marginTop: 2 }}
              disabled={!selectedLotery.length}
            >
              Siguiente
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
