// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.changeTerms_container__YVhAL {
    margin-top: 20px;
    width: 100%;
}

.changeTerms_container__YVhAL .changeTerms_titleContainer__xqAVA{
    display: flex;
    justify-content: center;
    align-items: center;
}

.changeTerms_container__YVhAL .changeTerms_titleContainer__xqAVA .changeTerms_title__UgdBb{
    width: 55%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
}
.changeTerms_container__YVhAL .changeTerms_checkbox__sXJ9w {
    display: flex;
    align-items: center;
    margin-top: 17px;
}

.changeTerms_container__YVhAL .changeTerms_checkbox__sXJ9w h3{
    margin: 0;
    font-size: 1.3rem;
    font-family: Roboto, sans-serif;
    font-weight: 400;
}

.changeTerms_container__YVhAL .changeTerms_titleContainer__xqAVA .changeTerms_title__UgdBb h1{
    width: auto;
    font-size: 1.5rem;
    font-weight: 300;
    font-family: Roboto, sans-serif;
    margin-left: 10px;
}
.changeTerms_container__YVhAL .changeTerms_btn__CW6ee{
    border-bottom: 3px;
    width: 140px;
    height: 3rem;
    margin-bottom: 10px;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

`, "",{"version":3,"sources":["webpack://./src/components/Pages/TermsAndConditions/changeTerms.module.css"],"names":[],"mappings":";;AAEA;IACI,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,SAAS;IACT,iBAAiB;IACjB,+BAA+B;IAC/B,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,gBAAgB;IAChB,+BAA+B;IAC/B,iBAAiB;AACrB;AACA;IACI,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,gBAAgB;IAChB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;AACvB","sourcesContent":["\n\n.container {\n    margin-top: 20px;\n    width: 100%;\n}\n\n.container .titleContainer{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.container .titleContainer .title{\n    width: 55%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-right: 20px;\n}\n.container .checkbox {\n    display: flex;\n    align-items: center;\n    margin-top: 17px;\n}\n\n.container .checkbox h3{\n    margin: 0;\n    font-size: 1.3rem;\n    font-family: Roboto, sans-serif;\n    font-weight: 400;\n}\n\n.container .titleContainer .title h1{\n    width: auto;\n    font-size: 1.5rem;\n    font-weight: 300;\n    font-family: Roboto, sans-serif;\n    margin-left: 10px;\n}\n.container .btn{\n    border-bottom: 3px;\n    width: 140px;\n    height: 3rem;\n    margin-bottom: 10px;\n    transition: 0.3s;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border-radius: 10px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `changeTerms_container__YVhAL`,
	"titleContainer": `changeTerms_titleContainer__xqAVA`,
	"title": `changeTerms_title__UgdBb`,
	"checkbox": `changeTerms_checkbox__sXJ9w`,
	"btn": `changeTerms_btn__CW6ee`
};
export default ___CSS_LOADER_EXPORT___;
