// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Agencias_container__Gtalq {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

}

.Agencias_body__\\+DJgR{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 95%;
}

.Agencias_tables__-0O3x{
    margin-top: -25px;
    width: 100%;
    height: 80%;
    padding: 40px;
}

.Agencias_agencyTitle__A\\+AT3 {
    font-size: 1.5rem;
    margin-top: 10330px;
    text-transform: uppercase;
}

.Agencias_titleAgencias__29Qf8{
    animation: Agencias_init__yGqdE 0.5s both;
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-left: 45px;  

}

.Agencias_titleAgencias__29Qf8, .Agencias_title__YYskH{
    font-size: 1.5rem;
    font-family: Roboto, sans-serif;
    font-weight: 300;
} 

@keyframes Agencias_init__yGqdE {
    0%{
        opacity: 0;
        transform: translateX(-100px);
    }
    100%{
        opacity: 1;
        transform: translateX(0px);
    }
}

@keyframes Agencias_init2__DjSjm {
    0%{
        opacity: 0;
        transform: translateX(100px);
    }
    100%{
        opacity: 1;
        transform: translateX(0px);
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Pages/Agencias/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,WAAW;;AAEf;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;IACtB,UAAU;AACd;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,yCAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,iBAAiB;;AAErB;;AAEA;IACI,iBAAiB;IACjB,+BAA+B;IAC/B,gBAAgB;AACpB;;AAEA;IACI;QACI,UAAU;QACV,6BAA6B;IACjC;IACA;QACI,UAAU;QACV,0BAA0B;IAC9B;AACJ;;AAEA;IACI;QACI,UAAU;QACV,4BAA4B;IAChC;IACA;QACI,UAAU;QACV,0BAA0B;IAC9B;AACJ","sourcesContent":[".container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100%;\n    width: 100%;\n\n}\n\n.body{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n    width: 95%;\n}\n\n.tables{\n    margin-top: -25px;\n    width: 100%;\n    height: 80%;\n    padding: 40px;\n}\n\n.agencyTitle {\n    font-size: 1.5rem;\n    margin-top: 10330px;\n    text-transform: uppercase;\n}\n\n.titleAgencias{\n    animation: init 0.5s both;\n    display: flex;\n    align-items: center;\n    margin-top: 20px;\n    margin-left: 45px;  \n\n}\n\n.titleAgencias, .title{\n    font-size: 1.5rem;\n    font-family: Roboto, sans-serif;\n    font-weight: 300;\n} \n\n@keyframes init {\n    0%{\n        opacity: 0;\n        transform: translateX(-100px);\n    }\n    100%{\n        opacity: 1;\n        transform: translateX(0px);\n    }\n}\n\n@keyframes init2 {\n    0%{\n        opacity: 0;\n        transform: translateX(100px);\n    }\n    100%{\n        opacity: 1;\n        transform: translateX(0px);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Agencias_container__Gtalq`,
	"body": `Agencias_body__+DJgR`,
	"tables": `Agencias_tables__-0O3x`,
	"agencyTitle": `Agencias_agencyTitle__A+AT3`,
	"titleAgencias": `Agencias_titleAgencias__29Qf8`,
	"init": `Agencias_init__yGqdE`,
	"title": `Agencias_title__YYskH`,
	"init2": `Agencias_init2__DjSjm`
};
export default ___CSS_LOADER_EXPORT___;
