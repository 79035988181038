import React, { useState } from "react";
import s from "./ModalCC.module.css";

import {
  Modal,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Grid,
  Select,
  MenuItem,
  Stack,
  Pagination,
  CircularProgress,
} from "@mui/material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import LocalPrintshopRoundedIcon from "@mui/icons-material/LocalPrintshopRounded";
import { MovementsRecaudación } from "../../../../api/accounting/accountingHelper";
import { formatJavaCompleto } from "../../../../config/strings";
import "dayjs/locale/es";
import "dayjs/locale/pt";
import Swal from "sweetalert2";
import { exportExcel } from "../../../../helpers/exportExcel.js";
import { useTranslation } from "react-i18next";
import { Currency } from "../../../../helpers/currencyConvert";
import { getTresury } from "../../../../api/movement/BankService";
import { useEffect } from "react";
import printTable from "../../../../helpers/imprimir";
import { configDate } from "../../../../helpers/convertDate";
import { minDateRange } from "../../../../config/strings";
import { primeroDeOctubre } from "../../../../helpers/today";

const ModalCC = ({ open, closeModal }) => {
  const { movements } = useSelector((state) => state.gamma);
  const { textColor } = useSelector((state) => state.local);
  const [dateFrom, setDateFrom] = useState(
    diaAyer > minDateRange ? diaAyer : minDateRange
  );
  const [dateTo, setDateTo] = useState(diaHoy);
  const { t, i18n } = useTranslation();
  const agencieSelected = useSelector(
    (state) => state.myAgencies?.selected_agencie_to_consult
  );
  const { formatLocalDate } = configDate();
  const [bank, setBank] = useState({});

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  const getData = () => {
    setLoading(true);
    getTresury(agencieSelected.id, dateFrom, dateTo, "NONE", page, 10).then(
      (res) => {
        setBank(res);
        setTotalPages(res.totalPages);
        setLoading(false);
      }
    );
  };

  const getItemsByKind = (items) => {
    let data = items?.map((item) => {
      if (item?.accountingCode?.kind === "CREDIT") {
        return {
          ...item,
          amount: item.amount * -1,
          accountingCode: {
            ...item.accountingCode,
            description: "Pago",
          },
        };
      } else if (item?.accountingCode?.kind === "DEBIT") {
        return {
          ...item,
          accountingCode: {
            ...item.accountingCode,
            description: "Cobro",
          },
        };
      }
    });
    return data || [];
  };

  useEffect(() => {
    getData();
  }, [page]);

  function diaAyer() {
    let fechaActual = new Date();
    let diaDelMes = fechaActual.getDate();

    if (diaDelMes === 1) {
      fechaActual.setMonth(fechaActual.getMonth() - 1);
      fechaActual.setDate(0);
    } else {
      fechaActual.setDate(diaDelMes - 1);
    }
    let año = fechaActual.getFullYear();
    let mes = fechaActual.getMonth() + 1;
    let dia = fechaActual.getDate();
    let fechaRestadaEnFormatoAñoMesDia =
      año +
      "-" +
      (mes < 10 ? "0" : "") +
      mes +
      "-" +
      (dia < 10 ? "0" : "") +
      dia +
      "T00:00:00";
    return fechaRestadaEnFormatoAñoMesDia;
  }
  function diaHoy() {
    let fechaActual = new Date();
    let año = fechaActual.getFullYear();
    let mes = fechaActual.getMonth() + 1;
    let dia = fechaActual.getDate();
    let fecha =
      año +
      "-" +
      (mes < 10 ? "0" : "") +
      mes +
      "-" +
      (dia < 10 ? "0" : "") +
      dia +
      "T00:00:00";
    return fecha;
  }

  const handleChangeDateFrom = (date_picked) => {
    const date = `${date_picked.$y}-${date_picked.$M + 1 < 10 ? "0" : ""}${date_picked.$M + 1
      }-${date_picked.$D < 10 ? "0" : ""}${date_picked.$D}T${date_picked.$H < 10 ? "0" : ""
      }${date_picked.$H}:${date_picked.$m < 10 ? "0" : ""}${date_picked.$m}:${date_picked.$s < 10 ? "0" : ""
      }${date_picked.$s}`;
    setDateFrom(date);
  };
  const handleChangeDateTo = (date_picked) => {
    const date = `${date_picked.$y}-${date_picked.$M + 1 < 10 ? "0" : ""}${date_picked.$M + 1
      }-${date_picked.$D < 10 ? "0" : ""}${date_picked.$D}T${date_picked.$H < 10 ? "0" : ""
      }${date_picked.$H}:${date_picked.$m < 10 ? "0" : ""}${date_picked.$m}:${date_picked.$s < 10 ? "0" : ""
      }${date_picked.$s}`;
    setDateTo(date);
  };

  function formatDate(string) {
    if (!string?.length) {
      return "";
    }
    return new Date(string).toLocaleDateString();
  }

  const exportItem = () => {
    const excelPage = [];
    bank.content?.map((element) => {
      const exelData = {};
      exelData[t("expirationDate")] = formatDate(element.obligationDate);
      exelData[t("status")] = element.accountingCode.description;
      exelData[t("monetaryAmount")] = element.amount;
      excelPage.push(exelData);
    });
    return excelPage;
  };

  const exportToExcel = () => {
    let today = new Date();
    exportExcel(
      exportItem(),
      `Datos bancarios - Cuenta Corriente - ${today.toISOString()}`
    );
  };

  return (
    <Modal open={open} onClose={() => closeModal(false)}>
      <Grid container>
        <Grid
          xs={12}
          md={8}
          lg={4.5}
          item
          container
          className={s.boxModal}
          sx={{ bgcolor: "#EEEEEE" }}
        >
          <Grid
            container
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Grid item xs={11} className={s.exit}>
              <div className={s.titleModal}>
                <AccountBalanceIcon
                  sx={{ fontSize: "1.8rem", color: "primary.main" }}
                />
                <p>{t("banks")}</p>
              </div>
              <CloseIcon
                onClick={() => closeModal(false)}
                className={s.exitIcon}
                sx={{ color: "primary.dark" }}
              />
            </Grid>

            <Grid
              item
              container
              xs={11}
              sx={{ justifyContent: "flex-start", alignItems: "center", my: 1 }}
            >
              <Grid
                item
                xs={8}
                sx={{
                  color: "primary.light",
                  backgroundColor: "primary.dark",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  px: 2,
                }}
                className={s.balance}
              >
                <p style={{ fontWeight: 400 }}>{t("balance")}</p>
                <Currency
                  value={getItemsByKind(bank?.content)?.reduce(
                    (acc, b) => acc + b.amount,
                    0
                  )}
                />
              </Grid>
            </Grid>

            <Grid
              item
              container
              xs={12}
              sx={{ justifyContent: "center", alignItems: "center", my: 2 }}
            >
              <Grid item xs={11} sx={{ mb: 3 }}>
                <p style={{ fontSize: "1.3rem", margin: 0, fontWeight: 300 }}>
                  {t("filterByDate")}
                </p>
              </Grid>
              <Grid item xs={5.5}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={i18n.language}
                >
                  <DatePicker
                    label={t("from")}
                    inputFormat="DD/MM/YYYY"
                    value={dateFrom}
                    onChange={handleChangeDateFrom}
                    renderInput={(params) => <TextField {...params} />}
                    minDate={primeroDeOctubre()}
                    maxDate={dateTo}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={5.5}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={i18n.language}
                >
                  <DatePicker
                    label={t("to")}
                    inputFormat="DD/MM/YYYY"
                    value={dateTo}
                    onChange={handleChangeDateTo}
                    renderInput={(params) => <TextField {...params} />}
                    minDate={dateFrom}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid
                item
                container
                xs={11}
                sx={{ justifyContent: "center", alignItems: "center", my: 2 }}
              >
                <Grid
                  item
                  xs={6}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      getData();
                    }}
                  >
                    {t("find")}
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={() =>
                      printTable(document.getElementById("bank-table"))
                    }
                  >
                    <LocalPrintshopRoundedIcon sx={{ fontSize: "1.5rem" }} />
                  </Button>

                  <Button
                    variant="contained"
                    sx={{ ml: 1, mr: 1 }}
                    onClick={() => exportToExcel()}
                  >
                    <DescriptionOutlinedIcon sx={{ fontSize: "1.5rem" }} />
                  </Button>
                </Grid>
              </Grid>

              <Grid
                item
                container
                xs={11}
                sx={{ my: 1, justifyContent: "center" }}
                id="bank-table"
              >
                <Grid item xs={12}>
                  <p className="texto">
                    {t("date")}: {formatLocalDate(dateFrom, formatJavaCompleto)}{" "}
                    al {formatLocalDate(dateTo, formatJavaCompleto)}
                  </p>
                </Grid>
                {loading ? (
                  <Grid item xs={11} className={s.noresult}>
                    <CircularProgress size={"3rem"} />
                  </Grid>
                ) : !getItemsByKind(bank?.content)?.length ? (
                  <Grid item xs={11} className={s.noresult}>
                    {t("noResults")}
                  </Grid>
                ) : (
                  <TableContainer className={s.tableContainer}>
                    <Table stickyHeader className="print-table">
                      <TableHead>
                        <TableCell
                          sx={{
                            backgroundColor: "primary.main",
                            color: textColor,
                            fontSize: "0.9rem",
                            fontWeight: 400,
                          }}
                        >
                          {t("movementDate")}
                        </TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: "primary.main",
                            color: textColor,
                            fontSize: "0.9rem",
                            fontWeight: 400,
                          }}
                        >
                          {t("type")}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            backgroundColor: "primary.main",
                            color: textColor,
                            fontSize: "0.9rem",
                            fontWeight: 400,
                          }}
                        >
                          {t("monetaryAmount")} ($)
                        </TableCell>
                      </TableHead>
                      <TableBody>
                        {Array.isArray(getItemsByKind(bank?.content)) &&
                          getItemsByKind(bank?.content)?.map((element, i) => (
                            <TableRow
                              className={s.tableRow}
                              style={{ height: "10px" }}
                              key={element}
                            >
                              <TableCell
                                align="center"
                                className="alinear-centro"
                                size="small"
                                sx={{
                                  fontSize: "0.9rem",
                                  backgroundColor: i % 2 ? "#DDDDDD" : "white",
                                }}
                              >
                                {formatLocalDate(
                                  element.obligationDate,
                                  formatJavaCompleto
                                ) || "-"}
                              </TableCell>
                              <TableCell
                                size="small"
                                sx={{
                                  fontSize: "0.9rem",
                                  backgroundColor: i % 2 ? "#DDDDDD" : "white",
                                }}
                              >
                                {element.accountingCode.description}
                              </TableCell>
                              <TableCell
                                className="alinear-derecha"
                                align="right"
                                size="small"
                                sx={{
                                  fontSize: "0.9rem",
                                  backgroundColor: i % 2 ? "#DDDDDD" : "white",
                                }}
                              >
                                <Currency value={element.amount} />
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Grid>

              <Grid
                item
                xs={11}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  my: 2,
                }}
              >
                <Pagination
                  showFirstButton
                  showLastButton
                  color="primary"
                  count={totalPages}
                  page={page}
                  onChange={(e, v) => setPage(v)}
                  disabled={!bank?.content?.length || loading}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ModalCC;
