import { React, useState, useEffect } from "react";
import s from "./Resumen.module.css";
//helper
import { today } from "../../../../../helpers/today";
import { convertDate } from "../../../../../helpers/convertDate";
//Material
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Button,
  ListItem,
  ListItemText,
  CircularProgress,
} from "@mui/material";
//icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import BarChartRoundedIcon from "@mui/icons-material/BarChartRounded";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import NewspaperOutlinedIcon from "@mui/icons-material/NewspaperOutlined";
import Swal from "sweetalert2";

//translation
import { useTranslation } from "react-i18next";
import Loader from "../../../../Shared/Loader";
//redux
import { useSelector, useDispatch } from "react-redux";

//cellCreator
import { createPrizeRow } from "../../../../../helpers/tableFunctions";

//modals
import ModalApuestas from "../Modales/ModalApuestas";
import ModalJugadas from "../Modales/ModalDetalles";
import ModalListadoPlano from "../Modales/ModalListadoPlano";

//exportExcel
import { exportExcel, exportPDF } from "../../../../../helpers/exportExcel.js";
import {
  currencyFormat,
  Currency,
} from "../../../../../helpers/currencyConvert";
import {
  formatArg,
  formatJava,
  timeFormat,
} from "../../../../../config/strings";
import { configDate } from "../../../../../helpers/convertDate";
import { getHomologatedGames } from "../../../../../api/general/generalService";
import { format } from "date-fns";
import { getGameOffers } from "../../../../../api/games/GamesService.js";
import { getOrganizationId } from "../../../../../utils/session.utils.js";
import { getGamesSaa } from "../../../../../api/games/GamesService.js";

const Resumen = ({
  data,
  filtro,
  setFiltro,
  loading,
  setPrint,
  print,
  listadoPlano,
}) => {
  const [homologated_Games, setHomologated_Games] = useState([]);
  const { currentLogo } = useSelector((state) => state.local);
  const agencies = useSelector((state) => state.myAgencies.user_agencies);
  //Date Pickers state
  const [dateFrom, setDateFrom] = useState(filtro.from);
  const [dateTo, setDateTo] = useState(filtro.to);
  //modals states
  const [openDetails, setOpenDetails] = useState(false);
  const [openPlay, setOpenPlay] = useState(false);
  const [openListadoPlano, setOpenListadoPlano] = useState(false);
  const [dataModal, setDataModal] = useState([]);
  const [expandItem, setExpandItem] = useState([]);
  const [openItems, setOpenItems] = useState(false);
  const [expandAll, setExpandAll] = useState(false);

  const { textColor } = useSelector((state) => state.local);
  const dispatch = useDispatch();
  const { formatLocalDate, formatLocalDateTime } = configDate();
  //hooks
  const { t } = useTranslation();
  const { formatCurrency } = currencyFormat();

  console.log("------------------");
  console.log(data);
  console.log("------------------");

  //handles
  const handleDetails = () => {
    setOpenDetails(!openDetails);
  };
  useEffect(() => {
    getGame();
  }, []);
  const toggleExpandAll = () => {
    setExpandAll((prev) => {
      if (openItems) {
        setExpandItem([]);
        return false;
      } else {
        setOpenItems(true);
        return true;
      }
    });
  };

  const toggleExpandItem = (index) => {
    if (expandItem.includes(index)) {
      setExpandItem((prev) => {
        let data = prev.filter((item) => item !== index);
        return data;
      });
    } else {
      return setExpandItem((prev) => [...prev, index]);
    }
  };

  useEffect(() => {
    if (expandItem.length) {
      setOpenItems(true);
    } else {
      setOpenItems(false);
    }
  }, [expandItem]);

  const handlePlay = () => {
    setOpenPlay(!openPlay);
  };

  const handleListadoPlano = (data) => {
    setDataModal(data);
    setOpenListadoPlano(!openListadoPlano);
  };

  const exportItem = () => {
    const excelData = [];
    const traverse = data?.groups?.map((game) => {
      game.map((pdv) => {
        pdv.map((estatus) => {
          estatus.map((item) => {
            const itemExcel = {};
            itemExcel[t("salePoint")] = item.puntoDeVenta;
            itemExcel[t("game")] = item.juegoDescripcion;
            itemExcel[t("machine")] = item.codigoMaquina;
            itemExcel[t("status")] = item.estado;
            itemExcel[t("coupon")] = item.numeroCupon;
            itemExcel[t("raffles")] = item.numeroSorteo;
            itemExcel[t("caducity")] = item.fechaCaducidad;
            itemExcel[t("prizes")] = item?.premio;
            itemExcel[t("retention")] = item.retenido;
            itemExcel[t("payer")] = item.pdvPagador;
            excelData.push(itemExcel);
          });
        });
      });
    });
    return excelData;
  };

  const exportToExcel = () => {
    Swal.fire({
      title: t("excelConfirm"),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "#d33",
      cancelButtonText: t("cancel"),
      confirmButtonText: t("accept"),
    }).then((result) => {
      if (result.isConfirmed) {
        exportExcel(exportItem(), `${t("prizes")} ${t("paid")}`);
      }
    });
  };
  const exportToPDF = () => {
    Swal.fire({
      title: t("excelConfirm"),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "#d33",
      cancelButtonText: t("cancel"),
      confirmButtonText: t("accept"),
    }).then((result) => {
      if (result.isConfirmed) {
        exportPDF(exportItem(), `${t("prizes")} ${t("paid")}`);
      }
    });
  };

  const handlePrint = () => {
    setPrint(true);
  };

  function getGame() {
    // getHomologatedGames()
    //   .then((r) => {
    //     return r.filter(
    //       (juego) =>
    //         juego.integration[0].organization.id ===
    //           agencies[0].organization.id &&
    //         juego.integration[0].module === "SAA",
    //     );
    //   })
    getGamesSaa()
      .then((res) => {
        // res.sort((a, b) => {
        //   const nameA = a.name.toUpperCase();
        //   const nameB = b.name.toUpperCase();
        //   if (nameA < nameB) {
        //     return -1;
        //   }
        //   if (nameA > nameB) {
        //     return 1;
        //   }
        //   return 0;
        // });
        setHomologated_Games(res);
      })
      .catch((error) => console.log("Error"));
  }

  const filterGameName = () => {
    if (filtro.game === -1) {
      return "Todos";
    }
    const arr = homologated_Games.find((h) => h?.juego === filtro?.game);
    console.log("arr", arr);
    return arr.name;
  };

  const getPdvs = (game) => {
    let setpdv = new Set();
    let returnArray = [];
    game.forEach((element) => {
      setpdv.add(element.puntoDeVenta);
    });
    setpdv.forEach((el) => returnArray.push(el));

    return returnArray.sort(
      (a, b) => parseInt(a.split("-")[1]) - parseInt(b.split("-")[1]),
    );
  };

  return (
    <Grid className={s.container} container>
      <Grid className={s.filtersContainer} xs={12} container item>
        <Grid
          container
          item
          xs={12}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "primary.light",
            height: "7rem",
            borderRadius: 2,
            boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
          }}
        >
          <Grid
            item
            xs={7}
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <ListItem
              sx={{
                backgroundColor: "#EEEEEE",
                borderRadius: 3,
                width: "30%",
                boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
                padding: 0.5,
                paddingLeft: 1.5,
              }}
            >
              <ListItemText
                primary={t("coupons")}
                secondary={data?.size || "-"}
                primaryTypographyProps={{ fontSize: "1.1rem" }}
                secondaryTypographyProps={{ fontSize: "1.2rem" }}
              />
            </ListItem>
            <ListItem
              sx={{
                backgroundColor: "#EEEEEE",
                borderRadius: 3,
                width: "60%",
                boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
                padding: 0.5,
                paddingLeft: 1.5,
              }}
            >
              <ListItemText
                primary={t("total")}
                secondary={formatCurrency(data?.amount)}
                primaryTypographyProps={{ fontSize: "1.1rem" }}
                secondaryTypographyProps={{ fontSize: "1.2rem" }}
              />
            </ListItem>
          </Grid>
          <Grid
            item
            container
            xs={5}
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              disabled={!data?.groups}
              onClick={() => handlePrint()}
              sx={{ height: "3rem" }}
            >
              <PrintOutlinedIcon
                sx={{ color: textColor, fontSize: "1.5rem" }}
              />
            </Button>
            <Button
              variant="contained"
              onClick={exportToExcel}
              sx={{ height: "3rem" }}
            >
              <NewspaperOutlinedIcon
                sx={{ color: textColor, fontSize: "1.5rem" }}
              />
            </Button>
            <Button
              variant="contained"
              sx={{ height: "3rem" }}
              disabled={!data?.groups}
              onClick={() => {
                handleListadoPlano(data);
              }}
            >
              <BarChartRoundedIcon className={s.icon} />
            </Button>
          </Grid>
        </Grid>

        <Grid
          item
          xs={11.5}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            mt: 2,
          }}
        >
          {/* <Button
            variant="contained"
            disabled={!data?.groups}
            onClick={() => toggleExpandAll()}
            sx={{ height: "3rem", mr: 1 }}
          >
            {openItems ? (
              <UnfoldLessIcon sx={{ color: textColor, fontSize: "1.5rem" }} />
            ) : (
              <UnfoldMoreIcon sx={{ color: textColor, fontSize: "1.5rem" }} />
            )}
            {openItems ? t("collapseAll") : t("expandAll")}
          </Button>
      */}
        </Grid>
        {filtro?.from && filtro?.to ? (
          <Grid
            item
            xs={11.3}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              mt: 4,
            }}
          >
            <p
              style={{ margin: "0 5px 0 0", fontSize: "1rem", fontWeight: 600 }}
            >
              Fecha:
            </p>
            <p
              style={{ margin: "0 2px 0 0", fontSize: "1rem", fontWeight: 300 }}
            >
              {filtro?.from}
            </p>
          </Grid>
        ) : null}
      </Grid>
      <Grid className={s.gamesTable} container xs={12} item>
        {loading ? (
          <Grid
            item
            xs={12}
            sx={{
              minHeight: "150px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size="3rem" />
          </Grid>
        ) : !data?.groups ? (
          <Grid
            item
            xs={12}
            sx={{
              minHeight: "150px",
              fontSize: "1.8rem",
              fontWeight: 300,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {t("noResults")}
          </Grid>
        ) : (
          <Grid item container xs={12}>
            {print != true ? (
              data?.groups?.map((game, indxp) => (
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                  key={`grid${indxp}`}
                >
                  <Accordion
                    expanded={expandItem.includes(`t${indxp}`) || expandAll}
                    key={`t${indxp}`}
                    className={s.accordion}
                    sx={{
                      width: "95%",
                      backgroundColor:
                        indxp % 2 === 0 ? "primary.main" : "primary.light",
                      "&:hover": { backgroundColor: "primary.dark" },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      onClick={() => toggleExpandItem(`t${indxp}`)}
                    >
                      <Typography
                        sx={{ color: textColor }}
                        className={s.gameName}
                      >
                        <p className={s.p1}>{game[0][0][0].juegoDescripcion}</p>{" "}
                        <p className={s.p1}>
                          {" "}
                          {t("coupons")}:{" "}
                          {game?.reduce(
                            (acc, pdv) =>
                              acc +
                              pdv?.reduce(
                                (ac, status) => ac + status?.length,
                                0,
                              ),
                            0,
                          )}
                        </p>
                        <p
                          style={{
                            justifyContent: "center",
                            backgroundColor:
                              textColor === "black" ? "white" : "",
                            width: "25%",
                            minWidth: "12rem",
                            padding: "0.5rem",
                            borderRadius: "10px",
                          }}
                        >
                          <span>{t("total")}:</span>{" "}
                          <span
                            style={{
                              fontWeight: textColor === "black" ? "bold" : 400,
                            }}
                          >
                            <Currency
                              value={game?.reduce(
                                (acc, pdv) =>
                                  acc +
                                  pdv?.reduce(
                                    (ac, status) =>
                                      ac +
                                      status?.reduce(
                                        (acc, item) => acc + item?.premio,
                                        0,
                                      ),
                                    0,
                                  ),
                                0,
                              )}
                            />
                          </span>
                        </p>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={s.accordionDetails}>
                      {game
                        .sort((a, b) => {
                          let subagencyA = a[0][0]?.puntoVenta
                            ? a[0][0].puntoVenta.split("-")[1]
                            : a[0][0].puntoDeVenta.split("-")[1];
                          let subagencyB = b[0][0]?.puntoVenta
                            ? b[0][0].puntoVenta.split("-")[1]
                            : b[0][0].puntoDeVenta.split("-")[1];

                          console.log(subagencyA, subagencyB);
                          if (parseInt(subagencyA) < parseInt(subagencyB)) {
                            return -1;
                          }
                          if (parseInt(subagencyA) > parseInt(subagencyB)) {
                            return 1;
                          }
                          return 0;
                        })
                        .map((pdv, idx) =>
                          getPdvs(game[0][0]).map((pdvString, indice) => (
                            <Accordion
                              expanded={
                                expandItem.includes(
                                  `tx${indxp}${idx}${indice}`,
                                ) || expandAll
                              }
                              key={`tx${indxp}${idx}${indice}`}
                              className={s.accordion}
                              sx={{ width: "100%", borderRadius: "5px" }}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                sx={{
                                  backgroundColor: "#DDDDDD",
                                  borderRadius: "5px 5px 0 0",
                                }}
                                onClick={() =>
                                  toggleExpandItem(`tx${indxp}${idx}${indice}`)
                                }
                              >
                                <Typography
                                  sx={{ color: "#222222" }}
                                  className={s.gameName}
                                >
                                  <p className={s.p1}>{pdvString}</p>
                                  <p className={s.p1}>
                                    {" "}
                                    {`${t("coupons")}:`}{" "}
                                    {pdv?.reduce(
                                      (acc, pdv) =>
                                        acc +
                                        pdv?.filter(
                                          (st) => st.puntoDeVenta === pdvString,
                                        ).length,
                                      0,
                                    )}
                                  </p>
                                  <p
                                    style={{
                                      justifyContent: "center",
                                      backgroundColor:
                                        textColor === "black" ? "white" : "",
                                      width: "25%",
                                      minWidth: "11rem",
                                      padding: "0.5rem",
                                      borderRadius: "10px",
                                    }}
                                  >
                                    <span>{t("total")}:</span>
                                    <span
                                      style={{
                                        fontWeight:
                                          textColor === "black" ? "bold" : 400,
                                      }}
                                    >
                                      <Currency
                                        value={pdv?.reduce(
                                          (ac, status) =>
                                            ac +
                                            status
                                              ?.filter(
                                                (st) =>
                                                  st.puntoDeVenta === pdvString,
                                              )
                                              .reduce(
                                                (acc, item) =>
                                                  acc + item?.premio,
                                                0,
                                              ),
                                          0,
                                        )}
                                      />
                                    </span>
                                  </p>
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails className={s.accordionDetails}>
                                {pdv.map(
                                  (estatus, index) =>
                                    estatus?.filter(
                                      (st) => st.puntoDeVenta === pdvString,
                                    ).length > 0 && (
                                      <Accordion
                                        expanded={
                                          expandItem.includes(
                                            `tz${indxp}${idx}${index}${indice}`,
                                          ) || expandAll
                                        }
                                        key={`tz${indxp}${idx}${index}${indice}`}
                                        className={s.accordion}
                                        sx={{ borderRadius: "5px" }}
                                      >
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          sx={{
                                            backgroundColor: "#EDEDED",
                                            borderRadius: "5px 5px 0 0",
                                          }}
                                          onClick={() =>
                                            toggleExpandItem(
                                              `tz${indxp}${idx}${index}${indice}`,
                                            )
                                          }
                                        >
                                          <Typography
                                            sx={{ color: "#222222" }}
                                            className={s.gameName}
                                          >
                                            <p>{estatus[0].estado}</p>
                                            <p>
                                              {`${t("coupons")}:`}{" "}
                                              {
                                                estatus?.filter(
                                                  (st) =>
                                                    st.puntoDeVenta ===
                                                    pdvString,
                                                ).length
                                              }
                                            </p>
                                            <p
                                              style={{
                                                justifyContent: "flex-end",
                                              }}
                                            >
                                              <span>{t("total")}:</span>
                                              <Currency
                                                value={estatus
                                                  ?.filter(
                                                    (st) =>
                                                      st.puntoDeVenta ===
                                                      pdvString,
                                                  )
                                                  .reduce(
                                                    (acc, item) =>
                                                      acc + item?.premio,
                                                    0,
                                                  )}
                                              />
                                            </p>
                                          </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails
                                          className={s.accordionDetails}
                                        >
                                          <TableContainer
                                            className={s.tableContainer}
                                          >
                                            <Table stickyHeader>
                                              <TableHead
                                                sx={{
                                                  bgcolor: "secondary.light",
                                                }}
                                              >
                                                <TableRow
                                                  color="primary.main"
                                                  className={s.tableRow}
                                                >
                                                  <TableCell
                                                    align="right"
                                                    sx={{
                                                      fontSize: "0.9rem",
                                                      fontWeight: 500,
                                                    }}
                                                    className={s.tableCell}
                                                  >
                                                    {t("machine")}
                                                  </TableCell>
                                                  <TableCell
                                                    align="right"
                                                    sx={{
                                                      fontSize: "0.9rem",
                                                      fontWeight: 500,
                                                    }}
                                                    className={s.tableCell}
                                                  >
                                                    {t("coupon")}
                                                  </TableCell>
                                                  <TableCell
                                                    align="right"
                                                    sx={{
                                                      fontSize: "0.9rem",
                                                      fontWeight: 500,
                                                    }}
                                                    className={s.tableCell}
                                                  >
                                                    {t("raffles")}{" "}
                                                  </TableCell>
                                                  <TableCell
                                                    align="right"
                                                    sx={{
                                                      fontSize: "0.9rem",
                                                      fontWeight: 500,
                                                    }}
                                                    className={s.tableCell}
                                                  >
                                                    {t("status")}
                                                  </TableCell>
                                                  <TableCell
                                                    align="right"
                                                    sx={{
                                                      fontSize: "0.9rem",
                                                      fontWeight: 500,
                                                    }}
                                                    className={s.tableCell}
                                                  >
                                                    {t("caducity")}
                                                  </TableCell>
                                                  <TableCell
                                                    sx={{
                                                      fontSize: "0.9rem",
                                                      fontWeight: 500,
                                                    }}
                                                    align="right"
                                                    className={s.tableCell}
                                                  >
                                                    {t("prizes")} ($)
                                                  </TableCell>
                                                  <TableCell
                                                    sx={{
                                                      fontSize: "0.9rem",
                                                      fontWeight: 500,
                                                    }}
                                                    align="right"
                                                    className={s.tableCell}
                                                  >
                                                    {t("retention")} ($)
                                                  </TableCell>
                                                  <TableCell
                                                    align="right"
                                                    sx={{
                                                      fontSize: "0.9rem",
                                                      fontWeight: 500,
                                                    }}
                                                    className={s.tableCell}
                                                  >
                                                    {t("payer")}
                                                  </TableCell>
                                                  <TableCell></TableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                {estatus
                                                  ?.filter(
                                                    (st) =>
                                                      st.puntoDeVenta ===
                                                      pdvString,
                                                  )
                                                  .map((item, i) => (
                                                    <TableRow
                                                      key={`row${indxp}${idx}${i}${index}${indice}`}
                                                    >
                                                      <TableCell
                                                        align="right"
                                                        sx={{
                                                          backgroundColor:
                                                            data.color,
                                                        }}
                                                      >
                                                        {item.codigoMaquina ||
                                                          "-"}
                                                      </TableCell>
                                                      <TableCell
                                                        align="right"
                                                        sx={{
                                                          backgroundColor:
                                                            data.color,
                                                        }}
                                                      >
                                                        {item.numeroCupon ||
                                                          "-"}
                                                      </TableCell>
                                                      <TableCell
                                                        align="right"
                                                        sx={{
                                                          backgroundColor:
                                                            data.color,
                                                        }}
                                                      >
                                                        {item.numeroSorteo ||
                                                          "-"}
                                                      </TableCell>
                                                      <TableCell
                                                        align="right"
                                                        sx={{
                                                          backgroundColor:
                                                            data.color,
                                                        }}
                                                      >
                                                        {item.estado || "-"}
                                                      </TableCell>
                                                      <TableCell
                                                        align="right"
                                                        sx={{
                                                          backgroundColor:
                                                            data.color,
                                                        }}
                                                      >
                                                        {convertDate(
                                                          item.fechaCaducidad,
                                                        )}
                                                      </TableCell>
                                                      <TableCell
                                                        align="right"
                                                        sx={{
                                                          backgroundColor:
                                                            data.color,
                                                        }}
                                                      >
                                                        {formatCurrency(
                                                          item?.premio,
                                                        )}
                                                      </TableCell>
                                                      <TableCell
                                                        align="right"
                                                        sx={{
                                                          backgroundColor:
                                                            data.color,
                                                        }}
                                                      >
                                                        {formatCurrency(
                                                          item?.retenido,
                                                        )}
                                                      </TableCell>
                                                      <TableCell
                                                        align="right"
                                                        sx={{
                                                          backgroundColor:
                                                            data.color,
                                                        }}
                                                      >
                                                        {" "}
                                                        {item.pdvPagador || "-"}
                                                      </TableCell>
                                                      <TableCell align="right">
                                                        <Tooltip
                                                          title={t(
                                                            "moreInformation",
                                                          )}
                                                          placement="left"
                                                        >
                                                          <InfoRoundedIcon
                                                            sx={{
                                                              color:
                                                                "rgb(91, 150, 233)",
                                                            }}
                                                            className={s.icon}
                                                            onClick={() => {
                                                              handleDetails();
                                                              setDataModal(
                                                                item,
                                                              );
                                                            }}
                                                          />
                                                        </Tooltip>
                                                        <Tooltip
                                                          title={t(
                                                            "playDetails",
                                                          )}
                                                          placement="right"
                                                        >
                                                          <BarChartRoundedIcon
                                                            sx={{
                                                              color:
                                                                "rgb(91, 150, 233)",
                                                            }}
                                                            className={s.icon}
                                                            onClick={() => {
                                                              handlePlay();
                                                              setDataModal(
                                                                item,
                                                              );
                                                            }}
                                                          />
                                                        </Tooltip>
                                                      </TableCell>
                                                    </TableRow>
                                                  ))}
                                              </TableBody>
                                            </Table>
                                          </TableContainer>
                                        </AccordionDetails>
                                      </Accordion>
                                    ),
                                )}
                              </AccordionDetails>
                            </Accordion>
                          )),
                        )}
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ))
            ) : (
              <Grid item xs={12} id="resumen-tabla">
                {currentLogo?.imgBase64.length ? (
                  <Grid className="logo" item xs={12}>
                    <img src={currentLogo?.imgBase64} alt="logo" />
                  </Grid>
                ) : (
                  <></>
                )}
                <Grid>
                  <h4 className="texto">{t("summary")}</h4>
                  <h4 className="texto">
                    {t("date")}: {filtro?.from} al {filtro?.to}
                  </h4>
                  <h4 className="texto">
                    {t("game")}: {filterGameName()}
                  </h4>
                </Grid>
                {data?.groups?.map((game, i) =>
                  game.map((pdv) => (
                    <TableContainer>
                      <Table className={`print-table`}>
                        <TableHead>
                          <TableRow>
                            <TableCell>{t("salePoint")}</TableCell>
                            <TableCell>{t("game")}</TableCell>
                            <TableCell>{t("status")}</TableCell>
                            <TableCell>{t("machine")}</TableCell>
                            <TableCell>{t("coupon")}</TableCell>
                            <TableCell>{t("raffles")} </TableCell>
                            <TableCell>{t("status")}</TableCell>
                            <TableCell>{t("caducity")}</TableCell>
                            <TableCell>{t("prizes")} ($)</TableCell>
                            <TableCell>{t("retention")} ($)</TableCell>
                            <TableCell>{t("payer")}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {pdv
                            .sort((a, b) => {
                              if (a.puntoDeVenta < b.puntoDeVenta) {
                                return -1;
                              }
                              if (a.puntoDeVenta > b.puntoDeVenta) {
                                return 1;
                              }
                              return 0;
                            })
                            .map((estatus) =>
                              estatus.map((item, i) => (
                                <TableRow key={i}>
                                  <TableCell className="alinear-derecha">
                                    {item.puntoDeVenta || "-"}
                                  </TableCell>
                                  <TableCell className="alinear-derecha">
                                    {item.juegoDescripcion || "-"}
                                  </TableCell>
                                  <TableCell>{item.estado || "-"}</TableCell>
                                  <TableCell className="alinear-derecha">
                                    {item.codigoMaquina || "-"}
                                  </TableCell>
                                  <TableCell className="alinear-derecha">
                                    {item.numeroCupon || "-"}
                                  </TableCell>
                                  <TableCell className="alinear-derecha">
                                    {item.numeroSorteo || "-"}
                                  </TableCell>
                                  <TableCell>{item.estado || "-"}</TableCell>
                                  <TableCell className="alinear-centro">
                                    {item.fechaCaducidad}
                                  </TableCell>
                                  <TableCell
                                    className="alinear-derecha"
                                    align="right"
                                  >
                                    {formatCurrency(item?.premio)}
                                  </TableCell>
                                  <TableCell
                                    className="alinear-derecha"
                                    align="right"
                                  >
                                    {formatCurrency(item?.retenido)}
                                  </TableCell>
                                  <TableCell className="alinear-derecha">
                                    {" "}
                                    {item.pdvPagador || "-"}
                                  </TableCell>
                                </TableRow>
                              )),
                            )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )),
                )}
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
      {openDetails ? (
        <ModalApuestas
          openDetails={openDetails}
          handleDetails={handleDetails}
          data={dataModal}
          filtro={filtro}
        />
      ) : null}
      {openPlay ? (
        <ModalJugadas
          openPlay={openPlay}
          handlePlay={handlePlay}
          data={dataModal}
        />
      ) : null}
      {openListadoPlano ? (
        <ModalListadoPlano
          openListadoPlano={openListadoPlano}
          handleListadoPlano={handleListadoPlano}
          data={dataModal}
          listadoPlano={listadoPlano}
        />
      ) : null}
    </Grid>
  );
};

export default Resumen;
