import styles from "./index.module.css";
import React from 'react'
import { CircularProgress, Grid } from '@mui/material';

const Loader = () => {

    return (
        <Grid item xs={12} className={styles.container}>
            <CircularProgress size='3.5rem' />
        </Grid>
    )
}
export default Loader;
