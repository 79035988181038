import React from "react";
import { Outlet, Link } from "react-router-dom";
import s from "./NavBarAdmin.module.css";
import Grid from "@mui/material/Grid";
import { Header } from "../../Header";
import {
  List,
  ListItem,
  ListItemButton,
  Avatar,
  ListItemText,
  Divider,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import abacoLogo from "../../../assets/abaco-logo-b.png";
import { useEffect, useState } from "react";
import GroupIcon from "@mui/icons-material/Group";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import EventNoteIcon from "@mui/icons-material/EventNote";
import SettingsIcon from "@mui/icons-material/Settings";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import LogoutIcon from "@mui/icons-material/Logout";
import { setActiveOrganization } from "../../../store/slices/gammaSlice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Swal from "sweetalert2";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { HeaderMobile } from "../../Header/HeaderMobile";
import userImage from "../../../assets/blank-user.jpg";
import { useAuth } from "../../Auth/AuthProvider";
import { useJwt } from "react-jwt";

export const NavBarAdmin = () => {
  //TODO: unificar navbar y navbaradmin en uno solo
  const [userName, setUserName] = useState("");
  const { textColor } = useSelector((state) => state.local);
  // const { keycloak } = useKeycloak();
  const dispatch = useDispatch();
  const superusuario = null; // keycloak.realmAccess.roles.includes(roles.superusuario)
  const currentUser = useSelector((state) => state.gamma.logged_user);
  const [selectedLotery, setSelectedLotery] = useState(
    currentUser?.memberships[0].organization.name,
  );
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [openNav, setOpenNav] = useState(
    window.innerWidth < 1000 ? false : true,
  );
  const [selected, setSelected] = useState({});
  let navigate = useNavigate();
  // session hook
  const { session } = useAuth();
  const { decodedToken } = useJwt(session.accessToken);

  useEffect(() => {
    if (decodedToken) {
      setUserName(decodedToken.name);
    }
  }, [decodedToken]);

  const handleChange = (e) => {
    setSelectedLotery(e.target.value);
    const selectedOrg = currentUser.memberships.find(
      (membership) => membership.organization.name === e.target.value,
    );
    dispatch(setActiveOrganization(selectedOrg.organization));
  };

  const openSubMenu = () => {
    setOpen(!open);
  };

  const openNavMenu = () => {
    setOpenNav(!openNav);
  };

  async function logOutSession() {
    navigate("/logout"); // routing to the login
  }

  const logOut = () => {
    Swal.fire({
      title: t("logoutDialog"),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "#d33",
      cancelButtonText: t("cancel"),
      confirmButtonText: t("accept"),
    }).then((result) => {
      if (result.isConfirmed) {
        logOutSession();
      }
    });
  };

  const text = {
    fontFamily: "Roboto, sans-serif",
    fontSize: "0.8rem",
    fontWeight: "800",
  };

  return (
    <div className={s.container}>
      <Grid
        className={`${s.navbar} ${openNav ? s.open : null}`}
        sx={{ bgcolor: "primary.main" }}
        item
      >
        <div className={s.navContent}>
          <div className={s.navHeader}>
            <img className={s.abaco_logo} src={abacoLogo} alt="Logo Abaco" />
            <div className={s.avatar}>
              <Avatar
                sx={{ width: 40, height: 40 }}
                alt="Profile picture"
                src={userImage}
                className={s.profilepic}
              ></Avatar>
              <h3 className={s.username} style={{ color: textColor }}>
                {userName}
              </h3>
            </div>
            <div className={s.selector}>
              <FormControl
                fullWidth
                sx={{ color: textColor, height: "20px" }}
                size="small"
              >
                <InputLabel
                  id="select-label"
                  sx={{ color: textColor }}
                ></InputLabel>
                {currentUser?.memberships?.length > 1 ? (
                  <Select
                    labelId="select-label"
                    id="select"
                    sx={{
                      color: textColor,
                      borderColor: "black",
                      ":hover": `borderColor: black`,
                      height: "55px",
                      fontSize: "12px",
                    }}
                    value={selectedLotery}
                    onChange={handleChange}
                  >
                    {superusuario ? (
                      currentUser?.memberships?.map((item, i) => (
                        <MenuItem key={i} value={item.organization.name}>
                          {item.organization.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem
                        value={currentUser?.memberships[0]?.organization.name}
                      >
                        {currentUser?.memberships[0].organization.name}
                      </MenuItem>
                    )}
                  </Select>
                ) : (
                  <h3>{currentUser?.memberships[0]?.organization.name}</h3>
                )}
              </FormControl>
            </div>
          </div>

          <Divider className={s.divider} />

          <nav aria-label="navigation" className={s.navList}>
            <List>
              <ListItem
                className={s.itemContainer}
                onClick={(e) => {
                  window.innerWidth < 1000 && setOpenNav(false);
                  console.log(e);
                  setSelected({ users: true });
                }}
              >
                <Link
                  className={`${s.listItem} ${selected.users ? s.selectedItem : null}`}
                  to={`users`}
                >
                  <ListItemButton className={s.itemButton}>
                    <GroupIcon
                      sx={{ color: "secondary.main", mr: 1 }}
                      className={s.itemIcon}
                    />
                    <ListItemText
                      sx={{ color: textColor }}
                      primaryTypographyProps={{ style: text }}
                      primary={t("users")}
                      className={s.itemText}
                    />
                  </ListItemButton>
                </Link>
              </ListItem>
              <ListItem
                className={s.itemContainer}
                onClick={(e) => {
                  window.innerWidth < 1000 && setOpenNav(false);
                  setSelected({ sales_points: true });
                }}
              >
                <Link
                  className={`${s.listItem} ${selected.sales_points ? s.selectedItem : null}`}
                  to={`sales_points`}
                >
                  <ListItemButton className={s.itemButton}>
                    <PointOfSaleIcon
                      sx={{ color: "secondary.main", mr: 1 }}
                      className={s.itemIcon}
                    />
                    <ListItemText
                      sx={{ color: textColor }}
                      primaryTypographyProps={{ style: text }}
                      primary={t("points")}
                      className={s.itemText}
                    />
                  </ListItemButton>
                </Link>
              </ListItem>
              <ListItem
                className={s.itemContainer}
                onClick={(e) => {
                  window.innerWidth < 1000 && setOpenNav(false);
                  setSelected({ terms: true });
                }}
              >
                <Link
                  className={`${s.listItem} ${selected.terms ? s.selectedItem : null}`}
                  to={`terms_and_conditions`}
                >
                  <ListItemButton className={s.itemButton}>
                    <EventNoteIcon
                      sx={{ color: "secondary.main", mr: 1 }}
                      className={s.itemIcon}
                    />
                    <ListItemText
                      sx={{ color: textColor }}
                      primaryTypographyProps={{ style: text }}
                      primary={t("terms")}
                      className={s.itemText}
                    />
                  </ListItemButton>
                </Link>
              </ListItem>
              <ListItem
                className={s.itemContainer}
                onClick={(e) => {
                  window.innerWidth < 1000 && setOpenNav(false);
                  setSelected({ configure: true });
                }}
              >
                <Link
                  className={`${s.listItem} ${selected.configure ? s.selectedItem : null}`}
                  to={`configure`}
                >
                  <ListItemButton className={s.itemButton}>
                    <SettingsIcon
                      sx={{ color: "secondary.main", mr: 1 }}
                      className={s.itemIcon}
                    />
                    <ListItemText
                      sx={{ color: textColor }}
                      primaryTypographyProps={{ style: text }}
                      primary={t("configure")}
                      className={s.itemText}
                    />
                  </ListItemButton>
                </Link>
              </ListItem>
              <ListItem className={s.itemContainer}>
                <div className={s.listItem}>
                  <ListItemButton
                    onClick={openSubMenu}
                    className={s.itemButton}
                  >
                    <ViewQuiltIcon
                      sx={{ color: "secondary.main", mr: 1 }}
                      className={s.itemIcon}
                    />
                    <ListItemText
                      sx={{ color: textColor }}
                      primaryTypographyProps={{ style: text }}
                      primary={t("themes")}
                      className={s.itemText}
                    />
                    {open ? (
                      <ExpandLess sx={{ color: textColor }} />
                    ) : (
                      <ExpandMore sx={{ color: textColor }} />
                    )}
                  </ListItemButton>
                </div>
              </ListItem>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    className={`${s.itemContainer} ${s.subItem}`}
                    onClick={(e) => {
                      window.innerWidth < 1000 && setOpenNav(false);
                      setSelected({ colors: true });
                    }}
                  >
                    <Link
                      className={`${s.listItem} ${selected.colors ? s.selectedItem : null}`}
                      to={`colors`}
                    >
                      <ListItemButton className={s.itemButton}>
                        <ListItemText
                          sx={{ color: textColor, ml: 4 }}
                          primaryTypographyProps={{ style: text }}
                          primary={t("colors")}
                          className={s.itemText}
                        />
                      </ListItemButton>
                    </Link>
                  </ListItem>
                  <ListItem
                    className={`${s.itemContainer} ${s.subItem}`}
                    onClick={(e) => {
                      window.innerWidth < 1000 && setOpenNav(false);
                      setSelected({ logos: true });
                    }}
                  >
                    <Link
                      className={`${s.listItem} ${selected.logos ? s.selectedItem : null}`}
                      to={`logos`}
                    >
                      <ListItemButton className={s.itemButton}>
                        <ListItemText
                          sx={{ color: textColor, ml: 4 }}
                          primaryTypographyProps={{ style: text }}
                          primary={t("logos")}
                          className={s.itemText}
                        />
                      </ListItemButton>
                    </Link>
                  </ListItem>
                </List>
              </Collapse>

              {/* <ListItem className={s.itemContainer}>
               <Link className={`${s.listItem} ${selected.notifications ? s.selectedItem : null}`} to={`notifications`}>
                <ListItemButton className={s.itemButton}>
                    <NotificationsIcon sx={{color:'secondary.main', mr: 1}} className={s.itemIcon}/>
                    <ListItemText sx={{color: textColor}} primaryTypographyProps={{ style: text }} primary={t('notifications')} className={s.itemText}/>
                </ListItemButton>
              {/* </Link> 
            </ListItem> */}
              {/* <ListItem className={s.itemContainer}>
                <ListItemButton className={s.itemButton}>
                    <ViewCarouselIcon sx={{color:'secondary.main', mr: 1}} className={s.itemIcon}/>
                    <ListItemText sx={{color: textColor}} primaryTypographyProps={{ style: text }} primary={t('banners')} className={s.itemText}/>
                </ListItemButton>
            </ListItem> */}
              <ListItem className={s.itemContainer}>
                <div className={s.listItem}>
                  <ListItemButton onClick={logOut} className={s.itemButton}>
                    <LogoutIcon
                      sx={{ color: "secondary.main", mr: 1 }}
                      className={s.itemIcon}
                    />
                    <ListItemText
                      sx={{ color: textColor }}
                      primaryTypographyProps={{ style: text }}
                      primary={t("logOut")}
                      className={s.itemText}
                    />
                  </ListItemButton>
                </div>
              </ListItem>
            </List>
          </nav>
        </div>

        <div className={s.arrowNav}>
          <div className={s.arrowIcon} onClick={openNavMenu}>
            <ArrowBackIosIcon sx={{ fontSize: 50, color: "secondary.main" }} />
          </div>
        </div>
      </Grid>

      <div className={`${s.content} ${openNav ? s.openContent : null}`}>
        <div className={s.headerPos}>
          <div className={s.mobileHeader}>
            <HeaderMobile openNav={openNavMenu} />
          </div>
          <div className={s.webHeader}>
            <Header />
          </div>
        </div>
        <div className={s.outletPos}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};
