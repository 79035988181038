import { setUsersError } from "../../store/slices/errors";
import apiClient from "../../utils/api.client";
import { getOrganizationId } from "../../utils/session.utils";

export const create_user = async (payload) => {
    try {
        const response = await apiClient.post(`/gamma-users/users`, payload);
        const data = response.data;

        return data;
    } catch (error) {
        console.error('Error at create_user EP: ', error);
        throw error
    }
}

export const editUser = async (payload) => {
    try {
        const response = await apiClient.put(`/gamma-users/users`, payload);
        const data = response.data;

        return data;
    } catch (error) {
        console.error('Error at editUser EP: ', error);
        throw error
    }
}

export const deleteUser = async (userId) => {
    try {
        const response = await apiClient.delete(`/gamma-users/users/${userId}`);
        const data = response.data;

        return data;
    } catch (error) {
        console.error('Error at deleteUser EP: ', error);
        throw error
    }
}

// NOTE: Assess whether add orgId is needed or not
/*HAY QUE REVISAR SI EL BACK FILTRA LOS USUARIOS POR ORGANIZACION*/
export const searchUser = async (payload) => {
    try {
        const response = await apiClient.get(`/gamma-users/users?orderCriteria=ASC&orderField=lastname&query=${payload}`);
        const data = response.data;

        return data;
    } catch (error) {
        console.error('Error at searchUser EP: ', error);
        throw error
    }
}

export const searchUsersByOrganization = async (
    orgId = getOrganizationId(),
    page,
    perPage,
    dispatch,
    filters,
    query
) => {
    try {
        const response = await apiClient.get(`/gamma-users/organizations/${orgId}/users?pageNumber=${page || 1}&pageSize=${perPage || 10}&orderCriteria=${filters?.increasing ? "ASC" : "DESC"}&orderField=${filters?.name || "firstname"}${query ? `&query=${query}` : ''}`);
        const data = response.data;
        if (response.status !== 200) {
            throw new Error(response.status)
        } else {
            dispatch(setUsersError({ error: false }))
            return data;
        }
    } catch (error) {
        console.error('Error at searchUsersByOrganization EP: ', error);
        throw error

    }
}

//AGREGAR ORGID

export const getUsersByUUID = async (payload, organizationId = getOrganizationId()) => {
    try {
        const response = await apiClient.get(`/gamma-users/users/${payload}`);
        const data = response.data.content;

        return data;
    } catch (error) {
        console.error('Error at getUsersByUUID:', error);
        // Re-throw the error to allow further handling if needed
        throw error;
    }
}
