// import react
import { useState, useEffect } from "react";
// import styles
import "./index.css";
// import material UI
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Input from '@mui/material/Input';
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import FilledInput from "@mui/material/FilledInput";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
// import api
import authService from "../../../services/auth.service";
// import react-router
import { useNavigate } from "react-router-dom";
// import redux
import { useDispatch } from "react-redux";
import { updateSession } from "../../../store/slices/session";
import abacoLogoWhite from "../../../../src/assets/abaco-logo-b.png";
import { useAuth } from "../../Auth/AuthProvider";
import { decodeToken, useJwt } from "react-jwt";
import { isSessionExpired } from "../../../utils/session.utils";
import { resetAgenciesSlice } from "../../../store/slices/my_agencies";
import { logoBaseOnOrgId } from "../../../api/themes/ThemeService";
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import Switch from '@mui/material/Switch';

const organizationURL = {
  default: -1,
};

function getOrgIdFromHostName() {
  const currentUrl = window.location.hostname;

  const orgId = organizationURL[currentUrl];

  if (!orgId) {
    return organizationURL.default;
  }

  return orgId;
}

// ============================= | LOGIN | =====================================

export function Login() {
  // Section: Session storage
  const credentialsSession = JSON.parse(localStorage.getItem('credentials'))
  const rememberCredentials = localStorage.getItem('remember-credentials')
  // Section: State
  const [showPassword, setShowPassword] = useState(false);
  // Section: Redux
  const dispatch = useDispatch();
  // Section: Navigation
  const navigate = useNavigate();
  // session
  const { session } = useAuth();

  const [logError, setLogError] = useState({ error: false, message: "  " });

  const [logo, setLogo] = useState(null);

  const [formData, setFormData] = useState(credentialsSession === null ?
    {
      username: '',
      password: ''
    } :
    {
      username: credentialsSession.username,
      password: credentialsSession.password
    }
  )

  const [rememberSwitchValue, setRememberSwitchValue] = useState(rememberCredentials)

  useEffect(() => {
    process.env.REACT_APP_URL_ORGANIZATIONS.split(";").forEach((data) => {
      const organizationData = data.split(",");
      organizationURL[organizationData[0]] = organizationData[1];
    });

    const getLogo = async (organizationId) => {
      try {
        if (organizationId !== -1) {
          const response = await logoBaseOnOrgId(organizationId);
          setLogo(response.imgBase64);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const organizationId = getOrgIdFromHostName();
    getLogo(organizationId);
  }, []);

  // Section: handlers errors

  // NOTE: This is for clean the agencies redux state
  dispatch(resetAgenciesSlice());

  const { decodedToken } = useJwt(session.accessToken);

  // Section: Session validation
  useEffect(() => {
    if (session) {
      if (session.accessToken !== null) {
        if (decodedToken) {
          const isExpired = isSessionExpired(decodedToken.exp);
          if (!isExpired) {
            navigate("/check-user");
          }
        }
      }
    }
  }, [decodedToken, navigate, session]);

  // Section: Handler
  const handleSubmit = async (event) => {
    setLogError({ error: false, message: "" });
    event.preventDefault();

    const form = event.target;
    const formData = new FormData(form);

    const userData = {
      username: formData.get("username"),
      password: formData.get("password"),
    }

    if (rememberSwitchValue) {
      if (credentialsSession !== null) {
        userData.username = credentialsSession.username
        userData.password = credentialsSession.password
      }
    }

    try {
      const response = await authService.signIn(
        userData.username,
        userData.password,
      );
      const decodedToken = decodeToken(response.accessToken);
      response.decodedToken = decodedToken;

      dispatch(updateSession(response));

      // setting remember credential once the user is logged
      if (rememberSwitchValue) {
        localStorage.setItem('remember-credentials', true)
        // setting the password once the user is logged
        const userDataTransformed = JSON.stringify(userData)
        localStorage.setItem('credentials', userDataTransformed)
      }
    } catch (error) {
      const errorMessage = error.message.split(":");
      setLogError({ error: true, message: errorMessage[1] });
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleOnChange = (event) => {
    if (rememberCredentials) {
      setRememberSwitchValue(false)
      setFormData({
        username: '',
        password: ''
      })

      localStorage.removeItem('credentials')
      localStorage.removeItem('remember-credentials')
    }

    setFormData((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value
      }
    })
  }

  const handleSwitch = () => {
    let current
    setRememberSwitchValue((prev) => {
      current = !prev
      // this deletes the credentials from the storage in case the user disables the remember option
      if (!current) {
        localStorage.removeItem('credentials')
        localStorage.removeItem('remember-credentials')
      }

      return !prev
    })
  }

  // Section: JSX
  return (
    <div className="login--container">
      <div className="logo--container">
        <div className="dynamic--logo">
          {logo !== null &&
            <img alt="logo" className="logo--state" src={logo} />
          }
        </div>
        <div className="static--logo">
          <img alt="logo" className="logo--img" src={abacoLogoWhite} />
        </div>
      </div>
      <div className="form--container">
        <Box
          className="form--item"
          component="form"
          onSubmit={handleSubmit}
        >
          <p
            className={`${logError.error ? "show--message" : "hidde--message"}`}
          >
            {logError.message}
          </p>
          <div className="input--style">
            <FormControl
              variant="filled"
              sx={{ display: 'flex' }}
              fullWidth
            >
              <FilledInput
                hiddenLabel={true}
                id="filled-adornment-user"
                type='text'
                value={formData.username}
                onChange={handleOnChange}
                name="username"
                placeholder="DNI o correo electrónico"
                color="abaco"
                label="Usuario"
                startAdornment={
                  <InputAdornment position="end" >
                    <PersonIcon
                      sx={{
                        color: 'action.active',
                        mr: 1
                      }}
                    />
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
          <div className="input--style">
            <FormControl
              sx={{ display: 'flex' }}
              fullWidth
              variant="filled"
            >
              <FilledInput
                autoComplete="new-password"
                placeholder="Contraseña"
                id="filled-adornment-password"
                type={showPassword ? "text" : "password"}
                name="password"
                value={formData.password}
                onChange={handleOnChange}
                color="abaco"
                startAdornment={
                  <InputAdornment position="end" >
                    <LockIcon
                      sx={{
                        color: 'action.active',
                        mr: 1
                      }}
                    />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Contraseña"
              />
            </FormControl>
          </div>
          <Box className='switch-input'>
            <Switch
              checked={rememberSwitchValue}
              onChange={handleSwitch}
              color="purple"
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <p>Recordarme</p>
          </Box>
          <div className="form--button">
            <Button
              disabled={(!formData.username || !formData.password) ? true : false}
              variant="contained"
              type="submit"
              color="abaco"
              sx={{
                color: '#9999CC',
                borderRadius: '2rem',
                padding: '.4rem 1.5rem',
                textTransform: 'none'
              }}
            >
              Ingresar
            </Button>
            <p className='forget-password' onClick={() => navigate('/reset-password')}>
              ¿Olvidaste tu contraseña?
            </p>
          </div>
        </Box>
      </div>
    </div>
  );
}
