// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.ModalShared_boxModal__GXNWH{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30%;
    height: 70%;
    border-radius: 10px;
    box-shadow: 0px 5px 50px -3px rgba(0, 0, 0, 0.511);
}

.ModalShared_boxModal__GXNWH .ModalShared_title__YGvbE{
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    margin-top: 20px;
}

.ModalShared_boxModal__GXNWH .ModalShared_title__YGvbE p{
    font-size: 24px;
}


.ModalShared_boxModal__GXNWH .ModalShared_body__WniAV{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 85%;
    margin-top: 20px;
    width: 100%;
}

.ModalShared_boxModal__GXNWH .ModalShared_body__WniAV .ModalShared_searchBar__ROq7I{
    width: 90%;
}

.ModalShared_boxModal__GXNWH .ModalShared_body__WniAV .ModalShared_content__GEptS{
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}`, "",{"version":3,"sources":["webpack://./src/components/Shared/Modal/ModalShared.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,mBAAmB;IACnB,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,UAAU;IACV,WAAW;IACX,mBAAmB;IACnB,kDAAkD;AACtD;;AAEA;IACI,UAAU;IACV,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB;;;AAGA;IACI,aAAa;IACb,sBAAsB;IACtB,6BAA6B;IAC7B,mBAAmB;IACnB,WAAW;IACX,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,WAAW;IACX,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,uBAAuB;AAC3B","sourcesContent":["\n.boxModal{\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: center;\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    width: 30%;\n    height: 70%;\n    border-radius: 10px;\n    box-shadow: 0px 5px 50px -3px rgba(0, 0, 0, 0.511);\n}\n\n.boxModal .title{\n    width: 90%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 30px;\n    margin-top: 20px;\n}\n\n.boxModal .title p{\n    font-size: 24px;\n}\n\n\n.boxModal .body{\n    display: flex;\n    flex-direction: column;\n    justify-content: space-around;\n    align-items: center;\n    height: 85%;\n    margin-top: 20px;\n    width: 100%;\n}\n\n.boxModal .body .searchBar{\n    width: 90%;\n}\n\n.boxModal .body .content{\n    width: 100%;\n    height: 80%;\n    display: flex;\n    justify-content: center;\n    align-items: flex-start;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"boxModal": `ModalShared_boxModal__GXNWH`,
	"title": `ModalShared_title__YGvbE`,
	"body": `ModalShared_body__WniAV`,
	"searchBar": `ModalShared_searchBar__ROq7I`,
	"content": `ModalShared_content__GEptS`
};
export default ___CSS_LOADER_EXPORT___;
