import styles from "./index.module.css";
import React from 'react'
import { Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import errorImage from "../../../assets/error.svg";
import { useTranslation } from "react-i18next";

export const ServerError = () => {
  const navigate = useNavigate();
  const { code } = useParams();
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <img className={styles.errorImage} src={errorImage} alt="Error" />
      <div className={code * 1 >= 500 ? styles.title500 : styles.title}>{code * 1 >= 500 ? t('tryInOtherMoment') : t('sorryErrorOcurred')}</div>
      <div className={styles.errorCode}>({t('error')}: {code})</div>
      <div className={styles.container_error}>
        <Button
          onClick={() => navigate(-1)}
          variant="contained"
          sx={{ marginTop: 2 }}
        >
          {t('retry')}
        </Button>
      </div>
    </div>
  );
};
