import s from "./index.module.css"
import React from 'react'
import { Button, Grid } from "@mui/material";
import errorImage from "../../../assets/error.svg";
import { useTranslation } from "react-i18next";


export const ComponentError = ({ code }) => {

    const { t } = useTranslation();

    return (
        <Grid container className={s.container}>
            <Grid item xs={12} className={s.image} sx={{ mt: 3 }}>
                <img src={errorImage} alt="error" />
            </Grid>

            <Grid item xs={12} sx={{ mt: 3, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <h4 style={{ margin: 0, fontSize: '2rem', fontWeight: 400 }}>{t('sorryErrorOcurred')}</h4>
                <p style={{ margin: 0, fontSize: '1.5rem', fontWeight: 300, marginTop: '10px' }}>Error: {code}</p>
            </Grid>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
                <Button variant='outlined' onClick={() => window.location.reload()} className={s.button}>
                    {t('retry')}
                </Button>
            </Grid>
        </Grid>
    )
}
