import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import styles from "./ConcentratorSelected.module.css";
import Column from "../assets/colums/columns.jsx"
import { createRow, createEditRow, sortArrayFirstOwners, formatResultUsers } from "../../../../helpers/tableFunctions"
import { Modal } from "../../../Shared/Modal/Modal.jsx"
import { organizationAgenciesUpdateAfterEdit, setConcentratorSelectedRedux } from "../../../../store/slices/gammaSlice.js"
import Swal from "sweetalert2"
import FormControlLabel from "@mui/material/FormControlLabel";
import GppBadIcon from "@mui/icons-material/GppBad";
import GppGoodIcon from "@mui/icons-material/GppGood";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { TableContainer, TableHead, Switch, TableRow, TableBody, Table, Button, Typography, Box } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Grid, IconButton } from "@mui/material/";
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import HandleRequests from "../../../Shared/handleRequests/HandleRequests";
import { getAgencieById, editAgencieData, addRelationship, deleteRelationShip } from "../../../../api/agencies/AgenciesService";
import { useTranslation } from "react-i18next";

const ConcentratorSelected = () => {

  const { id } = useParams();

  const [concentratorSelected, setConcentratorSelected] = useState(null);
  const [primaryColor, setPrimaryColor] = useState("");
  const [ligthPrimaryColor, setLightPrimaryColor] = useState("")
  const [secondaryColor, setSecondaryColor] = useState("");
  const [editData, setEditData] = useState({ street: "", zipcode: "", number: "", phone: "" });
  const [conditionalEditData, setConditionalEditData] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [userToAdd, setUserToAdd] = useState(null);
  const [rol, setRol] = useState("");
  const indexOfAgencie = 0
  const [activeOrganization, setActiveOrganization] = useState(null)
  const backError = useSelector(state => state.errors.concentratorSelectedError)
  const Columns = Column()
  const { t } = useTranslation();

  const allConcentrators = useSelector((state) => state.gamma?.organizationAgencies?.content);
  const { textColor } = useSelector((state) => state.local);
  const colors = useSelector((state) => state.local.uiConfig);
  const orgSelected = useSelector((state) => state.gamma.activeOrganization)
  const dispatch = useDispatch();

  useEffect(() => {
    setPrimaryColor(colors?.primary?.main);
    setLightPrimaryColor(colors?.primary?.light)
    setSecondaryColor(colors?.secondary?.main);
    setActiveOrganization(orgSelected)
  }, [colors, orgSelected]);

  useEffect(() => {
    const getConcentratorSelected = async () => {
      const response = await getAgencieById(id, dispatch)
      setConcentratorSelected(response)
      setEditData({
        street: response?.address?.street,
        number: response?.address?.number || "0",
        zipcode: response?.address?.zipcode || "0",
        phone: response?.phone || "0"
      })
      dispatch(setConcentratorSelectedRedux(response))
    }
    getConcentratorSelected()
  }, [id, dispatch])


  let save = (concentratorSelected?.phone || "0") !== editData?.phone || concentratorSelected?.address?.street !== editData?.street || (concentratorSelected?.address?.zipcode || "0") !== editData?.zipcode || (concentratorSelected?.address?.number || "0") !== editData?.number
  let errors = editData.phone === "" || editData.street === "" || editData.number === "" || editData.zipcode === ""
  const navigate = useNavigate();


  const handleSetStatus = async () => {
    let agencieToChangeStatus = { ...concentratorSelected }
    agencieToChangeStatus.status = agencieToChangeStatus.status === "DISABLED" ? "ENABLED" : "DISABLED"
    agencieToChangeStatus = { ...agencieToChangeStatus, address: { ...agencieToChangeStatus.address, location: null } }
    await editAgencieData(agencieToChangeStatus)
      .then(() => {
        setConcentratorSelected(agencieToChangeStatus)
        const concentratorsToSet = [...allConcentrators]
        concentratorsToSet[indexOfAgencie] = agencieToChangeStatus
        dispatch(organizationAgenciesUpdateAfterEdit(concentratorsToSet))
        Swal.fire({
          icon: "success",
          title: "Usuario modificado con éxito."
        })
      }).catch(() => {
        Swal.fire({
          icon: "error",
          title: "Ocurrio un error, vuelve a intentarlo"
        })
      })
  }

  const editAlert = () => {
    Swal.fire({
      title: t('editDialog'),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "#d33",
      cancelButtonText: t('cancel'),
      confirmButtonText: t('accept'),
    }).then((result) => {
      if (result.isConfirmed) {
        setConditionalEditData((b) => !b)
      }
    })
  }

  const handleEditData = (e) => {
    setEditData({
      ...editData,
      [e.target.name]: e.target.value
    })
  }


  const handleClose = () => {
    setOpenModal(false);
    setUserToAdd(null);
    setRol("");
  };


  const handleSelect = (item) => {
    setUserToAdd(item);
  }


  const handleAddUser = async () => {
    if (!rol) {
      return
    } else if (concentratorSelected.relationships.some(relation => relation.person.surname.toLowerCase() === userToAdd.lastname.toLowerCase() && relation.person.name.toLowerCase() === userToAdd.firstname.toLowerCase())) {
      setRol("")
      setUserToAdd(null)
      setOpenModal(false)
      Swal.fire({
        icon: 'error',
        title: 'Lo sentimos',
        text: 'No ha sido posible agregar al usuario debido a que el mismo ya forma parte de la concentradora',
      })
    } else {
      let newRelationToAdd = {
        person: {
          name: userToAdd?.firstname,
          surname: userToAdd?.lastname,
          mail: userToAdd?.email,
          phone: "",
          organization: {
            id: activeOrganization?.id,
            name: activeOrganization?.name
          },
        },
        relationshipKind: rol
      };
      const response = await addRelationship(concentratorSelected?.id, newRelationToAdd);
      if (response?.status === 200) {
        let relationShipAdded = await response?.json();
        let concentratorUpdated = {
          ...concentratorSelected,
          address: { ...concentratorSelected.address, location: null },
          relationships: [
            ...concentratorSelected?.relationships,
            { ...relationShipAdded }
          ]
        }
        setConcentratorSelected(concentratorUpdated);
        if (indexOfAgencie !== -1) {
          const concentratorsToSet = [...allConcentrators]
          concentratorsToSet[indexOfAgencie] = concentratorUpdated
          dispatch(organizationAgenciesUpdateAfterEdit(concentratorsToSet));
        }
        setUserToAdd(null)
        setOpenModal(false);
        setRol("")
        Swal.fire({
          icon: "success",
          title: "Usuario agregado con éxito"
        })
      } else {
        setOpenModal(false);
        Swal.fire({
          icon: "error",
          title: "Ocurrio un error, vuelve a intentarlo"
        })
      }
    }
  }

  const handleDelete = async (relationId) => {
    let userToDestroy = concentratorSelected?.relationships?.find(relation => relation.id === relationId);
    if ((concentratorSelected.relationships?.filter(relation => relation.relationshipKind === "OWNER")).length <= 1 && userToDestroy.relationshipKind === "OWNER") {
      Swal.fire({
        icon: 'error',
        title: 'Lo sentimos',
        text: 'No ha sido posible eliminar al usuario debido a que la concentradora no puede no poseer un Propiertario',
      })
    } else {
      const response = await deleteRelationShip(concentratorSelected?.id, relationId);
      if (response?.status === 200) {
        let concentratorUpdated = {
          ...concentratorSelected,
          address: { ...concentratorSelected.address, location: null },
          relationships: concentratorSelected?.relationships?.filter(relation => relation.id !== relationId * 1)
        }
        setConcentratorSelected(concentratorUpdated);
        if (indexOfAgencie !== -1) {
          let allConcentratorsWithoutOne = allConcentrators.filter(concentrator => concentrator.id !== id * 1)
          let allConcentratorsToSet = [...allConcentratorsWithoutOne]
          allConcentratorsToSet[indexOfAgencie] = concentratorUpdated
          dispatch(organizationAgenciesUpdateAfterEdit(allConcentratorsToSet));
        }
        Swal.fire({
          icon: 'success',
          title: 'Usuario eliminado',
          text: 'El usuario ha sigo eliminado con éxito',
        })
      }
    }
  }


  const handleSaveData = async () => {
    if (save || !errors) {
      let concentratorEdited = {
        ...concentratorSelected,
        address: {
          ...concentratorSelected?.address,
          street: editData?.street,
          zipcode: editData?.zipcode,
          number: editData?.number,
          location: null
        },
        phone: editData?.phone
      }

      await editAgencieData(concentratorEdited).then(() => {
        setConcentratorSelected(concentratorEdited)
        if (indexOfAgencie !== -1) {
          const concentratorsToSet = [...allConcentrators]
          concentratorsToSet[indexOfAgencie] = concentratorEdited
          dispatch(organizationAgenciesUpdateAfterEdit(concentratorsToSet))
        }
        Swal.fire({
          icon: "success",
          title: "Datos guardados con éxito"
        })
      }).catch(() => {
        Swal.fire({
          icon: "error",
          title: "Ocurrio un error, intentelo nuevamente"
        })
      })
    }
  }

  const titlesFilter = Columns?.selecterConcentratorColumns?.filter(column => column.id !== "addRelation");
  const subAgenciesTitle = Columns?.subAgenciesColumns?.filter(column => column.id !== "agencyLevel")

  return (
    <div className={styles.container}>
      <HandleRequests error={backError} loading={!concentratorSelected}>
        <div className={styles.header}>
          <div className={styles.navigator}>
            <Grid container justifyContent="center" alignItems="center" width="100%" >
              <Grid container item xs={1}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <IconButton onClick={() => navigate(-1)}>
                  <ArrowBackIcon color={"primary"} sx={{
                    fontSize: '2rem',
                    cursor: "pointer",
                  }} />
                </IconButton>
              </Grid>
              <Grid item justifyContent="center" textAlign="center" alignItems="center" xs={1}>
                <div className={styles.title}>
                  <span>{concentratorSelected?.number} </span>
                  <p />
                </div>
              </Grid>
              <Grid item justifyContent="center" textAlign="center" alignItems="center" display="flex" xs={8}>
                <div className={styles.title}>
                  <span>{` ${concentratorSelected?.name}`}</span>
                </div>
              </Grid>
              <Grid item justifyContent="center" textAlign="center" display="flex" alignItems="center" xs={2}>
                <span>{t('state')} </span>
                <Switch onClick={() => handleSetStatus()} checked={concentratorSelected?.status === "DISABLED" ? false : true} sx={{ marginLeft: 1.4 }} disabled={conditionalEditData} />
              </Grid>
            </Grid>
          </div>

        </div>
        <div className={styles.tableContainer}>
          <TableContainer className={styles.table}>
            <Table>
              <TableHead className={styles.tableHead}>
                <TableRow sx={{ backgroundColor: primaryColor }}>
                  {

                    titlesFilter?.map((column) => {
                      return createRow(column.label, 'left', false, { color: textColor, fontSize: "0.9rem", fontWeight: 500, fontFamily: 'Roboto, sans-Serif' })

                    })
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {createEditRow(editData?.phone, "phone", false, handleEditData, editData, conditionalEditData)}
                  {createEditRow(editData?.street, "street", false, handleEditData, editData, conditionalEditData)}
                  {createEditRow(editData?.number, "number", false, handleEditData, editData, conditionalEditData)}
                  {createEditRow(editData?.zipcode, "zipcode", false, handleEditData, editData, conditionalEditData)}
                  {createRow(null, null, false)}
                  {/* {createRow(<Switch onClick={() => handleSetStatus()} checked={concentratorSelected?.status === "DISABLED" ? false : true} sx={{transform: "scale(1.3)", marginLeft: 1.4}} disabled={conditionalEditData}/>, null, false)}  */}
                  {/* {createRow(
                    <Modal icon={<AddCircleOutlineIcon sx={{fontSize: 40, cursor: "pointer", color: primaryColor}}/>}
                      open={openModal}
                      onOpen={() => setOpenModal(true)}
                      onClose={handleClose}
                      title={"Usuario para agregar"}
                      keys={{ keys: ["firstname" , "lastname", "email"] }}
                      primaryColor={ligthPrimaryColor}
                      result={"email"}
                      format={formatResultUsers}
                      onSelect={handleSelect}
                      sxStyles={{marginLeft: 3}}
                      component={"users"}
                      text={<div className={styles.modalDiv}>
                      {
                        userToAdd ? (
                          <div className={styles.addUserContainer}>
                            <FormControl sx={{width: "100%"}} error={!rol ? true : false}>
                                <InputLabel id="demo-simple-select-label">Puesto</InputLabel>
                                <Select
                                  labelId={!rol ? "demo-simple-select-error-label" : "demo-simple-select-label"}
                                  id={!rol ? "demo-simple-select-error" : "demo-simple-select"}
                                  value={rol}
                                  label="Puesto"
                                  onChange={(e) => setRol(e.target.value)}
                                >
                                {
                                  roles.map((r, i) => <MenuItem key={i} value={r}>{t(r.toLowerCase())}</MenuItem>)
                                }
                                </Select>
                                {
                                  !rol ? <FormHelperText>Seleccione un puesto</FormHelperText> : null
                                }
                            </FormControl>
                              <span className={styles.nameAddUser}>{userToAdd.firstname} {userToAdd.lastname}</span>
                              <span className={styles.emailAddUser}>{userToAdd.email}</span>
                              <Tooltip title={!rol ? "Seleccione un puesto" : null}>
                                <Button variant="contained" sx={{marginTop: 2, backgroundColor: secondaryColor, color: "white", ":hover": `backgroundColor: ${primaryColor.main}`}} onClick={handleAddUser}>Agregar</Button>
                              </Tooltip>
                          </div>
                        ) : (
                          <h1>Seleccione un usuario para agregar</h1>
                        )
                      }
                    </div>}
                  />, null, false)} */}
                  {createRow(<EditIcon sx={{ fontSize: '1.6rem', cursor: "pointer", color: primaryColor }}
                    onClick={() => {
                      if (!save && !errors) {
                        editAlert()
                      } else {
                        Swal.fire({
                          icon: "error",
                          text: "Por favor guarde los cambios."
                        })
                      }
                    }} />
                    , null, false)}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Typography variant="h6" gutterBottom component="div" sx={{ fontSize: '1.8rem', marginTop: 1, padding: 2, color: secondaryColor, width: "100%", fontWeight: 500 }}>
            {
              concentratorSelected?.childrenAgencies?.length ? "Sub. Agencias:" : "Sin Sub. Agencias"
            }
          </Typography>
          <TableContainer className={styles.table}>
            <Table>
              <TableHead>
                {
                  concentratorSelected?.childrenAgencies?.length
                    ?
                    <TableRow sx={{ backgroundColor: primaryColor }}>
                      {
                        subAgenciesTitle?.map(subAgencie => {
                          return createRow(subAgencie.label, "left", false, { color: textColor, fontSize: "0.9rem", fontWeight: 500, fontFamily: 'Roboto, sans-Serif' })
                        })

                      }
                    </TableRow>
                    : <></>
                }
              </TableHead>
              <TableBody>
                {
                  concentratorSelected?.childrenAgencies?.length ? [...concentratorSelected?.childrenAgencies].reverse()?.map((SubAgencies, i) => {
                    return <TableRow tabIndex={-1} key={SubAgencies.id}>
                      {createRow(SubAgencies.number !== null && `${SubAgencies.subNumber}`, null, i % 2)}
                      {createRow(SubAgencies.name, null, i % 2)}
                      {createRow(SubAgencies.agencyKind.name, null, i % 2)}
                      {createRow(null, null, i % 2)}
                      {createRow(t(SubAgencies.status.toLowerCase()), null, i % 2)}
                      {createRow(null, null, i % 2)}
                      {/* {createRow(t(SubAgencies.agencyLevel.toLowerCase()), null, i % 2)} */}
                      {createRow(<EditIcon sx={{ fontSize: '1.6rem', cursor: "pointer", color: primaryColor, marginLeft: 3 }} onClick={() => navigate(`../sale_point/${SubAgencies.name.replaceAll(" ", "-")}/${SubAgencies.number}-${SubAgencies.subNumber || 0}/${SubAgencies.id}`)} />, null, i % 2)}
                    </TableRow>
                  }) : <></>
                }
              </TableBody>
            </Table>
          </TableContainer>
          <Typography variant="h6" gutterBottom component="div" sx={{ fontSize: '1.5rem', marginTop: 1, padding: 2, color: secondaryColor, width: "100%", fontWeight: 500 }}>

            {
              concentratorSelected?.relationships?.length ? "Relaciones:" : ""
            }
          </Typography>
          <TableContainer className={styles.table}>
            <Table>
              <TableHead>
                {
                  concentratorSelected?.relationships?.length
                    ? <TableRow sx={{ backgroundColor: primaryColor }}>
                      {
                        Columns?.relationsShipsColumns?.map(subAgencie => {
                          return createRow(subAgencie.label, "center", false, { color: textColor, fontSize: "18px", fontWeight: 500, fontFamily: 'Roboto, sans-Serif' })
                        })
                      }
                    </TableRow>
                    : <></>
                }
              </TableHead>
              <TableBody>
                {
                  concentratorSelected?.relationships?.length
                    ? [...concentratorSelected?.relationships].sort(sortArrayFirstOwners).map((employes, i) => {
                      return <TableRow tabIndex={-1} key={i}>
                        {createRow(`${employes?.person?.name || ""} ${employes?.person?.surname}`, null, i % 2)}
                        {createRow(employes.person.mail || "No ingresado", null, i % 2)}
                        {createRow(employes.person.phone || "No ingresado", null, i % 2)}
                        {createRow(employes.relationshipKind, null, i % 2)}
                        {createRow(<IconButton sx={{ fontSize: '1.6rem', marginLeft: "35%" }} onClick={() => handleDelete(employes.id)}>
                          <DeleteOutlineOutlinedIcon sx={{ fontSize: '1.6rem', color: primaryColor }} />
                        </IconButton>, null, i % 2)}
                      </TableRow>
                    })
                    : <></>
                }
              </TableBody>
            </Table>
          </TableContainer>
          <div className={styles.end}>
            {
              !conditionalEditData ? <Button
                variant="contained"
                onClick={handleSaveData}
                sx={{ width: "50%", height: "30%", backgroundColor: secondaryColor, color: textColor }}
                disabled={!save || errors}
              >
                Guardar cambios
              </Button> : <></>
            }
          </div>
        </div>
      </HandleRequests>
    </div>
  );
}


export default ConcentratorSelected;
