// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.SearchBar_searchBarContainer__bghds {
    width: 100%;
    height: 3.5rem;
  }

.SearchBar_searchBarContainer__bghds svg{
  width: 30px;
  height: 30px;
}`, "",{"version":3,"sources":["webpack://./src/components/Shared/SearchBar/SearchBar.module.css"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,cAAc;EAChB;;AAEF;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":["\n.searchBarContainer {\n    width: 100%;\n    height: 3.5rem;\n  }\n\n.searchBarContainer svg{\n  width: 30px;\n  height: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchBarContainer": `SearchBar_searchBarContainer__bghds`
};
export default ___CSS_LOADER_EXPORT___;
