// NOTE: There is no reason for organizationID -> check with MASSA -> MASSA RESPONSE: YES

import apiClient from "../../utils/api.client";
import { getOrganizationId } from "../../utils/session.utils";

/** This gets the imputations
 *
 * */
export const getImputation = async (organizationId = getOrganizationId()) => {
  // try {
  //     const response = await fetch(`/gamma-accounting/imputations?pageNumber=1&pageSize=200&orderCriteria=ASC&orderField=name`, {
  //         method: "GET",
  //         headers: { "Content-Type": "application/json", "accept": "application/json" }
  //     })
  //     return await response.json();
  // } catch (e) {
  //     console.log(e);
  // }

  try {
    const response = await apiClient.get(
      `/gamma-accounting/imputations?pageNumber=1&pageSize=200&orderCriteria=ASC&orderField=name&organizationId=${organizationId}`
    );
    const data = response.data;

    return data;
  } catch (error) {
    console.log(error);
  }
};
