import { createSlice } from "@reduxjs/toolkit";
import { getAllUsersFromAgencies } from "../../api/agencies/AgenciesService.jsx";
import { getUsersByUUID } from "../../api/users/UsersConfig";

const initialState = {
  logged_user: {},
  users: {},
  users_login: [],
  agencies: {},
  allOrganizations: [],
  gameOffers: [],
  organizationAgencies: {},
  organizationAgenciesSearch: {},
  concentratorSelectedRedux: [],
  roles: {},
  salePoints: {},
  movements: {},
  imputations: [],
  activeOrganization: '',
  myAgencies: {},
  accountingCodeKinds: {},
  appConfig: [],
  agencySettlemeng: [],
  homologated_Games: [],
  organization_Configuration: {},
  organizationsPackage: {}
};

const gammaSlice = createSlice({
  name: "apiCalls",
  initialState,
  reducers: {
    homologatedGames(state, action) {
      state.homologated_Games = action.payload;
    },
    loggedUser(state, action) {
      state.logged_user = action.payload;
    },
    sliceOrganizationPackage(state, action) {
      state.organizationsPackage = action.payload;
    },
    setUserLogin(state, action) {
      state.users_login = action.payload;
      state.users = action.payload;
    },
    usersUpdate(state, action) {
      state.users = action.payload;
    },
    agenciesUpdate(state, action) {
      state.agencies = action.payload;
    },
    gameOffersUpdate(state, action) {
      state.gameOffers = action.payload;
    },
    organizationAgenciesUpdate(state, action) {
      state.organizationAgencies = action.payload;
    },
    organizationsAgenciesToSearchBar(state, action) {
      state.organizationAgenciesSearch = action.payload
    },
    organizationAgenciesUpdateAfterEdit(state, action) {
      state.organizationAgencies = { ...state.organizationAgencies, content: [...action.payload] }
    },
    agencySettlementUpdate(state, action) {
      state.agencySettlement = action.payload;
    },
    agencySettlementUpdateConcentrator(state, action) {
      state.agencySettlement = [...state.agencySettlement, ...action.payload]
    },
    setConcentratorSelectedRedux(state, action) {
      state.concentratorSelectedRedux = action.payload
    },
    allRolesUpdate(state, action) {
      state.roles = action.payload;
    },
    allSalePointsUpdate(state, action) {
      state.salePoints = action.payload;
    },
    allOrganizations(state, action) {
      state.allOrganizations = action.payload;
    },
    setActiveOrganization(state, action) {
      state.activeOrganization = action.payload;
    },
    movementsUpdate(state, action) {
      state.movements = { ...state.movements, movements: [...action.payload] }
    },
    agencySettlement(state, action) {
      state.movements = { ...state.movements, header: [...action.payload] };
    },
    accountingCodeKindsUpdate(state, action) {
      state.accountingCodeKinds = action.payload
    },
    appConfigLoader(state, action) {
      state.appConfig = action.payload
    },
    resetGammaSlice(state, action) {
      state.logged_user = {}
      state.users = {}
      state.users_login = []
      state.agencies = {}
      state.allOrganizations = []
      state.gameOffers = []
      state.organizationAgencies = {}
      state.organizationAgenciesSearch = {}
      state.concentratorSelectedRedux = []
      state.roles = {}
      state.salePoints = {}
      state.movements = {}
      state.imputations = []
      state.activeOrganization = ''
      state.myAgencies = {}
      state.accountingCodeKinds = {}
      state.appConfig = []
      state.agencySettlemeng = []
      state.homologated_Games = []
      state.organization_Configuration = {}
      state.organizationsPackage = {}
    },

    resetLoggedUser(state, action) {
      state.logged_user = {}
    },
    organizationConfiguration(state, action) {
      state.organization_Configuration = action.payload;
    }
  }
});



export const allUsersOfTheLoginInUser = async (users) => (dispatch) => {
  try {
    Promise.all(users?.map(obj => getUsersByUUID(obj.userUUID)))
      .then(res => {
        console.log(res);
        dispatch(setUserLogin(res.filter(item => item.id)))
      })
      .catch(error => console.log(error)
      )
  } catch (e) {
    console.log(e)
    throw e;
  }
}

export const {
  loggedUser,
  usersUpdate,
  setUserLogin,
  agenciesUpdate,
  resetLoggedUser,
  gameOffersUpdate,
  organizationAgenciesUpdate,
  organizationsAgenciesToSearchBar,
  organizationAgenciesUpdateAfterEdit,
  setConcentratorSelectedRedux,
  allRolesUpdate,
  allSalePointsUpdate,
  allOrganizations,
  setActiveOrganization,
  movementsUpdate,
  movementsUpdateAgencie,
  imputationUpdate,
  accountingCodeKindsUpdate,
  agencySettlement,
  resetGammaSlice,
  appConfigLoader,
  homologatedGames,
  organizationConfiguration,
  sliceOrganizationPackage
} = gammaSlice.actions;

export default gammaSlice.reducer;
