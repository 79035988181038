import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import CloudUploadRoundedIcon from "@mui/icons-material/CloudUploadRounded";
import { useSelector, useDispatch } from "react-redux";
import {
  Avatar, Chip,
  CircularProgress,
  Grid,
  Table, TableBody,
  TableCell,
  TableContainer,
  TableHead, TableRow,
  ToggleButton,
  ToggleButtonGroup
} from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import IconButton from "@mui/material/IconButton";
import Select from '@mui/material/Select';
import { LogoChangePage } from "../../../store/slices/paginationSlice"
import WebStoriesIcon from '@mui/icons-material/WebStories';
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import noImage from '../../../assets/no-image.png'
import { hashImage } from "../../../helpers/hashImage";
import { addAppLogos, appLogosUpdate, uiOrganizationLogo } from "../../../store/slices/localSlice";
import Swal from "sweetalert2";
import { parseDate, convertDate } from "../../../helpers/convertDate";
import HandleRequests from "../../Shared/handleRequests/HandleRequests"
import {
  currentLogo,
  getAllLogos,
  addNewLogo,
  setDefaultLogo,
  editLogo,
  deleteLogo,
  currentTheme
} from "../../../api/themes/ThemeService";
import s from "./index.module.css";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PushPinRoundedIcon from "@mui/icons-material/PushPinRounded";
import { formatJava, formatTimeTermsCond, timeFormat, formatDateTimeTermsCond } from "../../../config/strings";
import { configDate } from "../../../helpers/convertDate";
export const Logos = () => {
  const [primaryColor, setPrimaryColor] = useState("");
  const { uiConfig } = useSelector((state) => state.local);
  const { appLogos } = useSelector((state) => state.local);
  const actualLogo = useSelector((state) => state.local.currentLogo)
  const { activeOrganization } = useSelector((state) => state.gamma);
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [primaryLogo, setPrimaryLogo] = useState(noImage)
  const [fromDate, setFromDate] = useState("")
  const [toDate, setToDate] = useState("")
  const [uploadLogoType, setLogoType] = useState('primary');
  const [currentLogoId, setCurrentLogoId] = useState(0)
  const [isEditing, setIsEditing] = useState(false)
  const [editingLogo, setEditingLogo] = useState({})
  const [activeByLogo, setActiveByLogo] = useState({});
  const backError = useSelector(state => state.errors.logosError)
  const { textColor } = useSelector(state => state.local);
  const { formatLocalDate, formatLocalDateTime } = configDate();
  const [display, setDisplay] = useState('list');
  const [infoImage, setInfoImage] = useState({ format: '', size: '' });

  let today = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0];

  useEffect(() => {
    currentLogo(activeOrganization.id)
      .then(res => setActiveByLogo(res));
  }, []);

  useEffect(() => {
    setPrimaryColor(uiConfig.primary.main);
    dispatch(LogoChangePage(1))
    currentLogo(activeOrganization.id).then(res => {
      setCurrentLogoId(res.id)
    })
  }, [uiConfig, activeOrganization, dispatch]);

  if (!appLogos?.length) {
    getAllLogos(dispatch)
      .then(response => {
        dispatch(appLogosUpdate(response))
      }).catch((error) => {
        console.error('Error while getting logos: ', error)
        Swal.fire({
          icon: "error",
          title: 'Error'
        })
      })
  }


  const removeItemFromLogos = (logoId) => {
    let selectedLogo = appLogos.find(f => f.id === logoId)
    let logosListCopy = [...appLogos];
    let pos = logosListCopy.indexOf(selectedLogo);
    logosListCopy.splice(pos, 1)
    dispatch(appLogosUpdate(logosListCopy))
  }

  const handleChange = (event) => {
    setLogoType(event.target.value);
    setEditingLogo(prevState => ({ ...prevState, logoType: event.target.value }))
  };

  const imagePrimaryLogo = (e) => {
    const format = e.target.files[0].type;
    const size = e.target.files[0].size;

    let resultSize = '';

    if (size > 1024) {
      resultSize = (size / (1024 * 1024)).toFixed(2) + 'MB'
    } else {
      resultSize = size.toFixed(2) + 'KB'
    }
    setInfoImage({ format, size: resultSize })
    hashImage(e, (e) => setPrimaryLogo(e.target.result))
    hashImage(e, (e) => setEditingLogo(prevState => ({ ...prevState, imgBase64: e.target.result })))
  }

  const handleClose = () => {
    setFromDate('')
    setToDate('')
    setOpenModal(false);
    setIsEditing(false)
    setLogoType("primary")
    setInfoImage({ format: '', size: '' })
  };

  const addCustomLogos = () => {
    const payload = {
      organizationId: activeOrganization.id,
      imgBase64: primaryLogo,
      logoType: uploadLogoType,
      validFrom: fromDate,
      validTo: toDate
    }
    addNewLogo(payload).then((response) => {
      setOpenModal(false);
      dispatch(addAppLogos(response))
      setLogoType("primary")

      Swal.fire({
        icon: "success",
        title: t('successLogoLoad'),
        showConfirmButton: false,
        timer: 1500,
      });
    }).catch(() => {
      Swal.fire({
        icon: "error",
        title: t('anErrorOccurred,TryAgain')
      })
    })
  }

  const applyLogo = (logo) => {
    setDefaultLogo(logo.id, activeOrganization.id)
      .then(() => {
        dispatch(uiOrganizationLogo(logo))
        setCurrentLogoId(logo.id)
        Swal.fire({
          icon: "success",
          title: t('successLogoApply'),
          showConfirmButton: false,
          timer: 1500,
        });
      }).cahtch(() => {
        Swal.fire({
          icon: "error",
          title: t('anErrorOccurred,TryAgain')
        })
      })
  }

  const handleChangeInput = (e) => {
    const file = e?.target?.files[0];
    const type = file?.type?.split('/')[0];

    if (type === 'image') {
      imagePrimaryLogo(e);
    } else {
      Swal.fire(t('formatValidation'), t('imageValidation'), 'warning')
    }
  }

  function formatDate(string) {
    let date = new Date(string);
    return date.toISOString().split('T')[0];
  }
  const decodeImageSizeAndType = (base64) => {
    let base64Content = base64.split(';base64,').pop();
    let decodeData = atob(base64Content);
    let imageSizeBytes = decodeData.length;
    let imageSizeInKB = (imageSizeBytes / 1024).toFixed(3) + ' KB';
    let imageSizeInMB = (imageSizeBytes / (1024 * 1024)).toFixed(3) + ' MB';
    let imageSize = imageSizeBytes > 1024 ? imageSizeInMB : imageSizeInKB;
    let imageType = base64.split(';')[0].split(':')[1];
    return {
      size: imageSize,
      type: imageType,
    };
  }

  const modifyLogo = (logo) => {
    setIsEditing(true)
    setEditingLogo(logo)
    setOpenModal(true)
    setFromDate(formatDate(logo.validFrom));
    setToDate(formatDate(logo.validTo));
    let data = decodeImageSizeAndType(logo.imgBase64)
    setInfoImage({ format: data.type, size: data.size })
  }

  const saveEditedLogo = () => {
    const payload = {
      organizationId: activeOrganization.id,
      imgBase64: primaryLogo === noImage ? editingLogo.imgBase64 : primaryLogo,
      logoType: uploadLogoType,
      validFrom: fromDate,
      validTo: toDate,
      id: editingLogo.id
    }
    editLogo(payload)
      .then(() => {
        setOpenModal(false);
        setIsEditing(false)
        getAllLogos(dispatch)
          .then(data => {
            dispatch(appLogosUpdate(data))
          }).catch((error) => {
            console.error('Error while getting logos: ', error)
            Swal.fire({
              icon: "error",
              title: 'Error'
            })
          })
        setLogoType("primary")
        Swal.fire({
          icon: "success",
          title: t('successLogoEdit'),
          showConfirmButton: false,
          timer: 1500,
        });
      }).catch(() => {
        setOpenModal(false);
        Swal.fire({
          icon: "error",
          title: t('anErrorOccurred,TryAgain')
        })
      })
  }

  const deleteCustomLogos = (logoId) => {

    Swal.fire({
      title: t('deleteLogo'),
      text: t('deleteLogoMessage'),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: primaryColor,
      cancelButtonColor: "#d33",
      cancelButtonText: t("cancel"),
      confirmButtonText: t("delete"),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteLogo(logoId)
          .then(response => {
            if (response.status === 204) {
              removeItemFromLogos(logoId)
              Swal.fire(t("deleted"), t("keyRemovedSuccessfully"), "success");
            }
          });
      }
    })
  }

  const cellStyles = {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '.9rem',
    fontWeight: '400',
    borderBottom: '1px solid rgba(0, 0, 0, 0.455)',
    cursor: "pointer"
  }

  return (
    <Grid container className={styles.container}>
      <HandleRequests error={backError} loading={!Array.isArray(appLogos)}>
        <Grid item container xs={11} className={styles.titleContainer}>
          <Grid item container lg={4} md={6} xs={12} className={styles.title} mt={3} mb={5}>
            <IconButton>
              <WebStoriesIcon color={"primary"} sx={{
                fontSize: '1.8rem',
                color: primaryColor,
                cursor: "pointer",
                mr: 1,
              }} />
            </IconButton>
            {t('logos')}
            <Grid item lg={6} ml={2}>
              <Button variant='contained' onClick={() => {
                setPrimaryLogo(noImage)
                setOpenModal(true)
              }}>{t('add')}</Button>
            </Grid>
          </Grid>
          <Grid item lg={1.5} md={3} xs={10} className={s.displayItem}>
            <ToggleButtonGroup
              color="primary"
              exclusive
              value={display}
              onChange={(e, v) => setDisplay(v)}
            >
              <ToggleButton value="list"><ViewListIcon sx={{ fontSize: '1.2rem', color: 'primary.main' }} /></ToggleButton>
              <ToggleButton value="module"><ViewModuleIcon sx={{ fontSize: '1.2rem', color: 'primary.main' }} /></ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>
        {display === 'module' ?
          <Grid item xs={12} container display="flex" justifyContent="center" alignItems="center" className={styles.itemsContainer}>
            {
              appLogos?.map((logo, i) => (
                <Grid key={i} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: '10px', p: 2, m: 2, border: actualLogo.id === logo.id ? '3px solid' : 'none', borderColor: 'secondary.main' }} className={styles.caja} xs={10} sm={5} lg={3.5}>
                  <h3>
                    {logo.logoType === "primary" ? t('mainLogo') : t('printLogo')}
                  </h3>

                  {(logo.validFrom && logo.validTo) && (
                    <Box sx={{ fontSize: "0.8rem", display: "inline", mb: 1, fontWeight: 600 }} className={styles.itemDate}>({formatLocalDate(logo.validFrom, formatJava)} al {formatLocalDate(logo.validTo, formatJava)})</Box>
                  )
                  }

                  <img className={styles.primaryLogo} src={logo.imgBase64} alt="Logo" />

                  {logo?.id === activeByLogo?.id ? <Chip icon={<AccessTimeIcon />} color={'primary'} label={t('activeByDate')} sx={{ my: 1 }} /> : null}
                  <Box sx={{ mb: 2, display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {/*<Button onClick={() => applyLogo(logo)} sx={{ mt: 1, ml: 1, mb: (!logo.validFrom && !logo.validTo) && 3}} variant="contained" disabled={actualLogo.id === logo.id}>*/}
                    {/*  {t('apply')}*/}
                    {/*</Button>*/}
                    <Button onClick={() => modifyLogo(logo)} sx={{ mt: 1, ml: 1, mb: (!logo.validFrom && !logo.validTo) && 3 }} variant="contained">
                      {t('edit')}
                    </Button>
                    <Button onClick={() => deleteCustomLogos(logo.id)} sx={{ mt: 1, ml: 1, mb: (!logo.validFrom && !logo.validTo) && 3 }} variant="contained">
                      {t('delete')}
                    </Button>
                  </Box>
                </Grid>))
            }
          </Grid> :

          <Grid item xs={11} className={styles.dataContainer}>
            <TableContainer className={styles.tableContainer}>
              <Table>
                <TableHead sx={{ backgroundColor: 'primary.main' }}>
                  <TableCell sx={{ color: textColor, fontSize: "0.9rem", fontWeight: 500, fontFamily: 'Roboto, sans-Serif' }}>{t('logo')}</TableCell>
                  <TableCell sx={{ color: textColor, fontSize: "0.9rem", fontWeight: 500, fontFamily: 'Roboto, sans-Serif' }}>{t('type')}</TableCell>
                  <TableCell sx={{ color: textColor, fontSize: "0.9rem", fontWeight: 500, fontFamily: 'Roboto, sans-Serif' }}></TableCell>
                  <TableCell sx={{ color: textColor, fontSize: "0.9rem", fontWeight: 500, fontFamily: 'Roboto, sans-Serif' }}>{t('effectiveDate')}</TableCell>
                  <TableCell sx={{ color: textColor, fontSize: "0.9rem", fontWeight: 500, fontFamily: 'Roboto, sans-Serif' }}>{t('endOfEffectiveDate')}</TableCell>
                  {/*<TableCell align='center' sx={{color: textColor, fontSize: "18px", fontWeight: 500, fontFamily: 'Roboto, sans-Serif'}}>{t('apply')}</TableCell>*/}
                  <TableCell align='center' sx={{ color: textColor, fontSize: "0.9rem", fontWeight: 500, fontFamily: 'Roboto, sans-Serif' }}>{t('edit')}</TableCell>
                  <TableCell align='center' sx={{ color: textColor, fontSize: "0.9rem", fontWeight: 500, fontFamily: 'Roboto, sans-Serif' }}>{t('delete')}</TableCell>

                </TableHead>
                <TableBody>
                  {console.log("donde", appLogos)}
                  {

                    appLogos.length ?
                      appLogos?.map((l, i) => (
                        <TableRow key={i} sx={{ border: actualLogo.id === l.id ? '3px solid' : 'none', borderColor: 'secondary.main', "&:hover": { backgroundColor: "secondary.light" } }}>
                          <TableCell sx={{ ...cellStyles, backgroundColor: i % 2 === 0 ? "#dbdbdb76" : "#dbdbdd12" }}><img src={l.imgBase64} alt={l.id} style={{ width: 150, height: 60, objectFit: 'scale-down' }} /></TableCell>
                          <TableCell sx={{ ...cellStyles, backgroundColor: i % 2 === 0 ? "#dbdbdb76" : "#dbdbdd12" }}>{l.logoType === "primary" ? t('mainLogo') : t('printLogo')}</TableCell>
                          <TableCell align='center' sx={{ ...cellStyles, backgroundColor: i % 2 === 0 ? "#dbdbdb76" : "#dbdbdd12" }}>{l?.id === activeByLogo?.id ? <Chip icon={<AccessTimeIcon />} color={'primary'} label={t('activeByDate')} sx={{ my: 1 }} /> : null}</TableCell>
                          <TableCell sx={{ ...cellStyles, backgroundColor: i % 2 === 0 ? "#dbdbdb76" : "#dbdbdd12" }}>{formatLocalDate(l.validFrom, formatDateTimeTermsCond) || '-'}</TableCell>
                          <TableCell sx={{ ...cellStyles, backgroundColor: i % 2 === 0 ? "#dbdbdb76" : "#dbdbdd12" }}>{formatLocalDate(l.validTo, formatDateTimeTermsCond) || '-'}</TableCell>
                          {/*<TableCell align='center' sx={{...cellStyles,  backgroundColor: i%2 === 0 ?  "#dbdbdb76" : "#dbdbdd12"}}>*/}
                          {/*  {*/}
                          {/*    actualLogo.id === l.id ? <DoneRoundedIcon sx={{color: "grey", fontSize: "30px"}}/> :*/}
                          {/*    <DoneRoundedIcon onClick={() => applyLogo(l)} className={styles.iconbtn} color="primary"/>*/}
                          {/*  }*/}
                          {/*</TableCell>*/}
                          <TableCell align='center' sx={{ ...cellStyles, backgroundColor: i % 2 === 0 ? "#dbdbdb76" : "#dbdbdd12" }}>
                            {
                              <EditRoundedIcon onClick={() => modifyLogo(l)} className={styles.iconbtn} color="primary" />
                            }
                          </TableCell>
                          <TableCell align='center' sx={{ ...cellStyles, backgroundColor: i % 2 === 0 ? "#dbdbdb76" : "#dbdbdd12" }}>
                            <DeleteForeverOutlinedIcon onClick={() => deleteCustomLogos(l.id)} className={styles.iconbtn} color="primary" />
                          </TableCell>
                        </TableRow>
                      ))
                      : null
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        }

        {/*<Grid item xs={12}>*/}
        {/*  <div className={styles.uploader1}>*/}
        {/*    <Button*/}
        {/*        variant="contained"*/}
        {/*        sx={{ width: "50%", height: 40, mb: 10, mt: 10 }}*/}
        {/*        onClick={() => {*/}
        {/*          setPrimaryLogo(noImage)*/}
        {/*          setOpenModal(true)*/}
        {/*        }*/}
        {/*        }*/}
        {/*      >*/}
        {/*        {t('addLogos')}*/}
        {/*    </Button>*/}
        {/*  </div>*/}

        {/*</Grid>*/}

        <Dialog
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          fullWidth
          className={styles.dialogContainer}
        >
          <DialogTitle id="modal-title">{isEditing ? t('editLogo') : t('addLogos')}</DialogTitle>
          <DialogContent className={styles.dialogScroll}>
            <Grid item container className={styles.datesContainer}>
              <h3>{t('validity')}</h3>
              <Grid item xs={12} className={styles.datesInputs}>
                <Grid item xs={6} className={styles.dateInput}>
                  <p>{isEditing ? t('from') : t('from') + '*'}</p>
                  <input onChange={(e) => setFromDate(e.target.value)} value={(isEditing && !fromDate.length) ? formatLocalDate(editingLogo.validFrom, formatJava) : fromDate} type="date" min={today} max={toDate.length && toDate} className={styles.selectDate} />
                </Grid>
                <Grid item xs={6} className={styles.dateInput}>
                  <p>{isEditing ? t('to') : t('to') + '*'}</p>
                  <input onChange={(e) => setToDate(e.target.value)} value={(isEditing && !toDate.length) ? formatLocalDate(editingLogo.validTo, formatJava) : toDate} type="date" min={fromDate} className={styles.selectDate} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={10}>
              <InputLabel id="logo-type" sx={{ mb: 1 }}>{t('logoType')}</InputLabel>
              <Select
                labelId="logo-type"
                id="logo-type"
                value={isEditing ? editingLogo.logoType : uploadLogoType}
                label="Tipo de Logo"
                onChange={handleChange}
                sx={{ width: '42%' }}
              >
                <MenuItem value="primary">{t('mainLogo')}</MenuItem>
                <MenuItem value="secondary">{t('printLogo')}</MenuItem>
              </Select>
            </Grid>

            <Grid sx={{ backgroundColor: 'white', borderRadius: '10px' }} display="flex" justifyContent="center" item>
              <Box sx={{ margin: 0, textAlign: "center", width: '50%' }} display="flex" flexDirection="column" justifyContent="space-between" alignItems="center">
                <h4>{isEditing ? t('logo') : t('logo') + '*'}</h4>
                <h4 style={{ margin: 0, marginBottom: "5px", fontSize: "1rem" }}>{infoImage.size !== '' || infoImage.format !== '' ? t('size') + ':' + infoImage.size + ' ' + t('format') + infoImage.format : null}</h4>
                <img className={styles.addImage} src={isEditing ? editingLogo.imgBase64 : primaryLogo} alt="Logo" />
                <label htmlFor={"primaryLogo"} className={styles.labelUpload}>
                  <input
                    type="file"
                    accept="image/*"
                    name="primaryLogo"
                    onChange={(e) => handleChangeInput(e)}
                    id={"primaryLogo"}
                    className={styles.input1}
                  />
                  <CloudUploadRoundedIcon sx={{ fontSize: 40, color: primaryColor }}></CloudUploadRoundedIcon>
                  <h5>{t('uploadLogo')}</h5>
                </label>
              </Box>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button disabled={isEditing ? false : primaryLogo === noImage || !fromDate.length || !toDate.length} variant="contained" color={'success'} onClick={isEditing ? saveEditedLogo : addCustomLogos}>{t('accept')}</Button>
            <Button variant="contained" color="error" onClick={handleClose}>{t('cancel')}</Button>
          </DialogActions>
        </Dialog>
      </HandleRequests>
    </Grid>
  );
};
