import { React, useState } from "react";
import s from "./Premios.module.css";
import { useNavigate } from "react-router-dom";
//Material UI
import { Grid, Tab, Tabs, Box, CircularProgress } from "@mui/material";

//redux
import { useSelector, useDispatch } from "react-redux";

//icons
import WorkspacePremiumOutlinedIcon from "@mui/icons-material/WorkspacePremiumOutlined";

//translation
import { useTranslation } from "react-i18next";
import AgencySelectModal from "../../Shared/AgencySelectModal/AgencySelectModal";
//tabs
import Pagados from "./Tabs/Pagados";
import Caducos from "./Tabs/Caducos";
import Resumen from "./Tabs/Resumen";
import PagadosDeOtra from "./Tabs/PagadosDeOtra";
import PagadosPorOtra from "./Tabs/PagadosPorOtra";

import Loader from "../../Shared/Loader";

import { useEffect } from "react";
import { primerDiaDelMes, today } from "../../../helpers/today";
import { lastWeek } from "../../../helpers/today";

import {
  getPrizesFromAnother,
  getPrizesForAnother,
  getPrizesExpired,
  getPrizesPaid,
  getPrizesSummary,
} from "../../../api/saaservices/prize";
import { Button } from "@mui/material";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import ModalProviders from "./ModalProviders/index";
import {
  setConcentrator,
  setSelectedAgencieToConsult,
} from "../../../store/slices/my_agencies";
import { useLocation } from "react-router-dom";
import { CalendarioHoy, CalendarioSemanaPasada } from "../../../helpers/today";
import { cleanup } from "@testing-library/react";
import printTable from "../../../helpers/imprimir";
import {
  minDateRange,
  formatJavaCompleto,
  formatArg,
} from "../../../config/strings";
import { agencieSearchByNumber } from "../../../api/agencies/AgenciesService";
import { updateLocation } from "../../../store/slices/localSlice";
import { differenceInCalendarYears } from "date-fns/esm";
import { format, parse } from "date-fns";
import { getSaaId } from "../../../utils/session.utils";


export const Premios = () => {
  const saaOrgId = getSaaId()
  const [isReady, setIsReady] = useState(true);
  const navigate = useNavigate();
  const agencieSelected = useSelector(
    (state) => state.myAgencies?.selected_agencie_to_consult,
  );
  const { activeOrganization } = useSelector((state) => state.gamma);
  const { t } = useTranslation();
  const agencies = useSelector((state) => state.myAgencies.user_agencies);
  const [tabValue, setTabValue] = useState("Resumen");
  const [dateFrom, setDateFrom] = useState(primerDiaDelMes());
  const [dateTo, setDateTo] = useState(CalendarioHoy());
  const [dataPaid, setDataPaid] = useState({});
  const [dataExpired, setDataExpired] = useState({});
  const [dataSummary, setDataSummary] = useState({});
  const [dataForAnother, setDataForAnother] = useState({});
  const [dataFromAnother, setDataFromAnother] = useState({});
  const [dataFormForAnother, setDataFromForAnother] = useState({});
  const [searching, setSearching] = useState(false);
  const [search, setSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paid, setPaid] = useState({});
  const [expired, setExpired] = useState({});
  const [print, setPrint] = useState(false);
  const [listadoPlano, setListadoPlano] = useState();
  // const {filterPremios} = useSelector((state)=> state.local);
  const [summary, setSummary] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [filtro, setFiltro] = useState({
    from: primerDiaDelMes(),
    to: today(),
    game: -1,
    agencyLevel: 0,
    fromOthers: false,
    byOthers: false,
  });

  /**
   * fechadesde: 10/01/2023
   * fechahasta: 31/10/2023
   */

  const dispatch = useDispatch();
  const handleOpen = () => setOpenModal(true);
  const location = useLocation();
  const [showModal, setShowModal] = useState();
  const [loadingAgency, setLoadingAgency] = useState(false);

  function primerDia() {
    let d = new Date();
    d.setDate(1);
    return format(d, formatArg);
  }

  function diaHoy() {
    let d = new Date();
    return format(d, formatJavaCompleto);
  }

  useEffect(() => {
    dispatch(updateLocation({ premios: true }));
  }, []);

  useEffect(() => {
    const level = agencieSelected.agencyLevel === "CONCENTRATOR" ? 1 : 0;
    setFiltro((prevState) => ({ ...prevState, agencyLevel: level }));
    setSearch(true);
  }, [agencieSelected]);

  async function fetchRes(agencie) {
    try {
      let res = await getPrizesSummary(
        agencie.saaOrgId,
        agencie.agencyNumber,
        filtro.from,
        agencie.subNumber || 0,
        agencie.agencyLevel === "SALE_POINT" ? 0 : 1,
        filtro.game,
      );
      console.log("RESUMEN: ", res);
      setListadoPlano(res);
      console.log("LISTADOPLANO: ", listadoPlano);
      return res;
    } catch (e) {
      console.log(e);
    }
  }
  async function fetchPaid(agencie) {
    try {
      let res = await getPrizesPaid(
        agencie.saaOrgId,
        agencie.agencyNumber,
        filtro.from,
        filtro.to,
        agencie.subNumber || 0,
        agencie.agencyLevel === "SALE_POINT" ? 0 : 1,
        filtro.game,
      );
      return res;
    } catch (e) { }
  }
  async function fetchExpired(agencie) {
    try {
      const res = await getPrizesExpired(
        agencie.saaOrgId,
        agencie.agencyNumber,
        agencie.filtro.from,
        agencie.filtro.to,
        agencie.subNumber || 0,
        agencie.agencyLevel === "SALE_POINT" ? 0 : 1,
      );

      return res;
    } catch (e) {
      console.error("Error getting prizes expired", e)
    }
  }
  async function fetchDataFromAnother(agencie) {
    try {
      let res = await getPrizesFromAnother(
        agencie.saaOrgId,
        filtro.from,
        filtro.to,
        agencie.agencyNumber,
        agencie.subNumber || 0,
        agencie.agencyLevel === "SALE_POINT" ? 0 : 1,
      );
      setDataFromAnother(res);
      console.log("DATA FROM ANOTHER: ", dataFromAnother);
      return res;
    } catch (e) {
      console.log(e);
    }
  }
  async function fetchDataForAnother(agencie) {
    try {
      let res = await getPrizesForAnother(
        agencie.saaOrgId,
        filtro.from,
        filtro.to,
        agencie.agencyNumber,
        agencie.subNumber || 0,
        agencie.agencyLevel === "SALE_POINT" ? 0 : 1,
      );
      setDataForAnother(res);
      return res;
    } catch (e) {
      console.log(e);
    }
  }
  async function fetchDataForFromAnother(agencie) {
    try {
      let res = [];
      res = await getPrizesForAnother(
        activeOrganization.id,
        filtro.from,
        filtro.to,
        agencie.number,
        agencie.subNumber || 0,
        agencie.agencyLevel === "SALE_POINT" ? 0 : 1,
      );
      res = [
        ...res,
        ...(await getPrizesFromAnother(
          activeOrganization.id,
          filtro.from,
          filtro.to,
          agencie.number,
          agencie.subNumber || 0,
          agencie.agencyLevel === "SALE_POINT" ? 0 : 1,
        )),
      ];
      return res;
    } catch (e) {
      console.log(e);
    }
  }
  const searchAgencie = () => {
    let res = "";
    let logOut = `/logout`;
    const path = location.pathname.split("/");
    if (path.length > 3) {
      const agencieNumber = path.slice(3);
      agencies.forEach((agencie) => {
        if (agencie.agencyLevel === "CONCENTRATOR") {
          if (agencie.number == agencieNumber.toString()) {
            res = agencie;
          } else {
            const traverse =
              Array.isArray(agencie?.childrenAgencies) &&
              agencie?.childrenAgencies?.forEach((subAgencie) => {
                let number = subAgencie.number + "-" + subAgencie.subNumber;
                if (number == agencieNumber.toString()) {
                  res = subAgencie;
                }
              });
          }
        } else {
          let number = agencie.number + "-" + agencie.subNumber;
          if (number == agencieNumber.toString()) {
            res = agencie;
          }
        }
      });
      if (res === "") {
        navigate(logOut);
      }
    }
    return res;
  };

  async function set(ress) {
    return await setDataSummary(ress);
  }

  async function fetchPrizesData(agencie) {
    if (typeof agencie === "object" && Object.keys(agencie).length !== 0) {
      switch (tabValue) {
        case "Resumen":
          return await fetchRes(agencie);
        case "Caducos":
          return await fetchExpired(agencie);
        case "Pagados":
          return await fetchPaid(agencie);
        case "Pagados De Otra":
          return await fetchDataFromAnother(agencie);
        case "Pagados Por Otra":
          return await fetchDataForAnother(agencie);
        default:
          break;
      }
    }
  }

  const puntoDeVentaExtractor = (premio) => {
    return premio.puntoDeVenta;
  };

  const puntoVentaExtractor = (premio) => {
    return premio.puntoVenta;
  };

  const defaultPdvExtractor = puntoDeVentaExtractor;

  useEffect(() => {
    if (print === true) {
      switch (tabValue) {
        case "Resumen":
          printTable(document.getElementById("resumen-tabla"));
          break;
        case "Caducos":
          printTable(document.getElementById("caduco-tabla"));
          break;
        case "Pagados":
          printTable(document.getElementById("pagado-tabla"));
          break;
        case "Pagados Por Otra":
          printTable(document.getElementById("pagado-tabla-por-otra"));
          break;
        case "Pagados De Otra":
          printTable(document.getElementById("pagado-tabla-de-otra"));
          break;
      }
    }
    setPrint(false);
  }, [print]);

  useEffect(() => {
    const res = searchAgencie();
    dispatch(setSelectedAgencieToConsult(res));
    if (filtro.from && filtro.to) {
      setLoading(true);
      fetchRes(res).then(() => setLoading(false));
    }
  }, []);

  useEffect(() => {
    if (searching) {
      setDataExpired({});
      setDataForAnother({});
      setDataFromAnother({});
      setDataPaid({});
      setDataSummary({});
      if (
        typeof agencieSelected === "object" &&
        Object.keys(agencieSelected).length !== 0
      ) {
        const path = location.pathname.split("/");
        const agencyNumber = path.slice(3);

        if (agencyNumber.length !== 0 && filtro?.from && filtro?.to) {
          setLoading(true);
          console.log(agencieSelected)
          const params = {
            saaOrgId,
            agencyNumber,
            filtro,
            subNumber: agencieSelected.subNumber || 0,
            agencyLevel: agencieSelected.agencyLevel === "SALE_POINT" ? 0 : 1
          }
          fetchPrizesData(params)
            .then((res) => {
              switch (tabValue) {
                case "Resumen":
                  setDataSummary(res || []);
                  break;
                case "Caducos":
                  setDataExpired(res || []);
                  break;
                case "Pagados":
                  setDataPaid(res || []);
                  break;
                case "Pagados De Otra":
                  setDataFromAnother(res || []);
                  break;
                case "Pagados Por Otra":
                  setDataForAnother(res || []);
                  break;
                default:
                  break;
              }
            })
            .then(() => setLoading(false));
        }
      }
    }
    setSearch(false);
  }, [search, agencieSelected]);

  useEffect(() => {
    const group = (info) => {
      let result = info.reduce(function (r, a) {
        r[a.estado] = r[a.estado] || [];
        r[a.estado].push(a);
        return r;
      }, Object.create([]));
      return result;
    };
    if (dataSummary?.length > 0) {
      const res = dataSummary.map((data) => {
        const cad = data.fechaCaducidad;
        let sorteo = [];
        if (data.fechaSorteo === "1970-01-01 00:00:00") {
          sorteo.push("-");
          return { ...data, fechaCaducidad: cad, fechaSorteo: sorteo[0] };
        } else {
          sorteo = data.fechaSorteo;
        }
        return { ...data, fechaCaducidad: cad, fechaSorteo: sorteo };
      });
      let games = Object.values(
        groupByGame(res, filtro.fromOthers, filtro.byOthers),
      );
      let groupSubAgencie = games.map((game) =>
        Object.values(groupBySubAgencie(game, puntoVentaExtractor)),
      );
      let groupEstado = groupSubAgencie.map((subAgencie) =>
        subAgencie.map((estado) => Object.values(group(estado))),
      );
      console.log("groupEstado", groupEstado);
      setSummary({
        groups: groupEstado,
        size: dataSummary.length,
        amount: dataSummary.reduce((acc, b) => acc + b.premio, 0),
      });
    }
  }, [dataSummary]);

  useEffect(() => {
    if (dataPaid?.length > 0) {
      //pagados
      const res = dataPaid.map((data) => {
        let sorteo = [];
        if (data.fechaSorteo === "1970-01-01 00:00:00") {
          sorteo.push("-");
          return { ...data, fechaSorteo: sorteo[0] };
        } else {
          sorteo = data.fechaSorteo;
        }
        return { ...data, fechaSorteo: sorteo };
      });
      let games = Object.values(
        groupByGame(res, filtro.fromOthers, filtro.byOthers),
      );
      let groupSubAgencie = games.map((game) =>
        Object.values(groupBySubAgencie(game)),
      );
      setPaid({
        groups: groupSubAgencie,
        size: dataPaid.length,
        amount: dataPaid.reduce((acc, b) => acc + b.importePremio, 0),
      });
    } else {
      setPaid({
        size: 0,
        amount: 0,
      });
    }
  }, [dataPaid]);

  useEffect(() => {
    const group = (info) => {
      let result = info.reduce(function (r, a) {
        r[a.juego] = r[a.juego] || [];
        r[a.juego].push(a);
        return r;
      }, Object.create([]));
      return result;
    };
    const groupBySubAgenci = (info) => {
      let result = info.reduce(function (r, a) {
        r[a.puntoDeVenta] = r[a.puntoDeVenta] || [];
        r[a.puntoDeVenta].push(a);
        return r;
      }, Object.create([]));
      return result;
    };
    if (dataExpired?.length > 0) {
      const res = dataExpired.map((data) => {
        let sorteo = [];
        if (data.fechaSorteo === "1970-01-01 00:00:00") {
          sorteo.push("-");
          return { ...data, fechaSorteo: sorteo[0] };
        } else {
          sorteo = data.fechaSorteo;
        }
        return { ...data, fechaSorteo: sorteo };
      });
      let games = Object.values(group(res));
      let groupSubAgencie = games.map((game) =>
        Object.values(groupBySubAgenci(game)),
      );
      setExpired({
        groups: groupSubAgencie,
        size: dataExpired.length,
        amount: dataExpired.reduce((acc, b) => acc + b.importePremio, 0),
      });
    } else {
      setExpired({
        size: 0,
        amount: 0,
      });
    }
  }, [dataExpired]);

  useEffect(() => {
    if (dataForAnother?.length > 0) {
      //pagados
      const res = dataForAnother.map((data) => {
        let sorteo = [];
        if (data.fechaSorteo === "1970-01-01 00:00:00") {
          sorteo.push("-");
          return { ...data, fechaSorteo: sorteo[0] };
        } else {
          sorteo = data.fechaSorteo;
        }
        return { ...data, fechaSorteo: sorteo };
      });
      let games = Object.values(
        groupByGame(res, filtro.fromOthers, filtro.byOthers),
      );
      let groupSubAgencie = games.map((game) =>
        Object.values(groupBySubAgencie(game)),
      );
      setPaid({
        groups: groupSubAgencie,
        size: dataForAnother.length,
        amount: dataForAnother.reduce((acc, b) => acc + b.importePremio, 0),
      });
    } else {
      setPaid({
        size: 0,
        amount: 0,
      });
    }
  }, [dataForAnother]);

  useEffect(() => {
    if (dataFromAnother?.length > 0) {
      //pagados
      const res = dataFromAnother.map((data) => {
        let sorteo = [];
        if (data.fechaSorteo === "1970-01-01 00:00:00") {
          sorteo.push("-");
          return { ...data, fechaSorteo: sorteo[0] };
        } else {
          sorteo = data.fechaSorteo;
        }
        return { ...data, fechaSorteo: sorteo };
      });
      let games = Object.values(
        groupByGame(res, filtro.fromOthers, filtro.byOthers),
      );
      let groupSubAgencie = games.map((game) =>
        Object.values(groupBySubAgencie(game)),
      );
      setPaid({
        groups: groupSubAgencie,
        size: dataFromAnother.length,
        amount: dataFromAnother.reduce((acc, b) => acc + b.importePremio, 0),
      });
    } else {
      setPaid({
        size: 0,
        amount: 0,
      });
    }
  }, [dataFromAnother]);

  useEffect(() => {
    if (dataFormForAnother?.length > 0) {
      //pagados
      const res = dataFormForAnother.map((data) => {
        let sorteo = [];
        if (data.fechaSorteo === "1970-01-01 00:00:00") {
          sorteo.push("-");
          return { ...data, fechaSorteo: sorteo[0] };
        } else {
          sorteo = data.fechaSorteo;
        }
        return { ...data, fechaSorteo: sorteo };
      });
      let games = Object.values(
        groupByGame(res, filtro.fromOthers, filtro.byOthers),
      );
      let groupSubAgencie = games.map((game) =>
        Object.values(groupBySubAgencie(game)),
      );
      setPaid({
        groups: groupSubAgencie,
        size: dataFormForAnother.length,
        amount: dataFormForAnother.reduce((acc, b) => acc + b.importePremio, 0),
      });
    } else {
      setPaid({
        size: 0,
        amount: 0,
      });
    }
  }, [dataFormForAnother]);

  const groupByGame = (info, forOther, fromOther) => {
    if (forOther === false && fromOther === false) {
      let result = info.reduce(function (r, a) {
        r[a.juegoDescripcion] = r[a.juegoDescripcion] || [];
        r[a.juegoDescripcion].push(a);
        return r;
      }, Object.create([]));
      return result;
    } else {
      let result = info.reduce(function (r, a) {
        r[a.juego] = r[a.juego] || [];
        r[a.juego].push(a);
        return r;
      }, Object.create([]));
      return result;
    }
  };

  const groupBySubAgencie = (info, pdvExtractor) => {
    pdvExtractor = pdvExtractor || defaultPdvExtractor;
    let result = info.reduce(function (r, a) {
      const pdv = pdvExtractor(a);
      r[pdv] = r[pdv] || [];
      r[pdv].push(a);
      return r;
    }, Object.create([]));
    return result;
  };

  function showHide() {
    setShowModal(!showModal);
  }

  const handleConsulting = (agencie) => {
    if (typeof agencie === "object" && Object.keys(agencie).length !== 0) {
      // dispatch(setSelectedAgencieToConsult(agencie))
      setLoadingAgency(true);
      agencieSearchByNumber(activeOrganization?.id, { number: agencie?.number })
        .then((response) => {
          dispatch(setConcentrator(response));
          dispatch(setSelectedAgencieToConsult(agencie));
          setLoadingAgency(false);
        })
        .then(() => {
          if (location.pathname.includes("/premios")) {
            if (agencie?.agencyLevel === "CONCENTRATOR") {
              navigate(`/main/premios/${agencie?.number}`);
            } else {
              navigate(
                `/main/premios/${agencie?.number}-${agencie?.subNumber || 0}`,
              );
            }
          }
        })
        .catch((e) => {
          console.log(e);
          setLoadingAgency(false);
        });
    }
  };

  function handleChangeTab(value) {
    setTabValue(value);
    setSearch(true);
  }

  function clean() {
    setFiltro((prevState) => ({ ...prevState, fromOthers: false }));
    setFiltro((prevState) => ({ ...prevState, byOthers: false }));
    setFiltro((prevState) => ({ ...prevState, game: -1 }));
    //setFiltro((prevState) => ({ ...prevState, to: null }));
    //setFiltro((prevState) => ({ ...prevState, from: null }));
    setDateTo(CalendarioHoy());
    setDateFrom(CalendarioSemanaPasada());
    setSearching(false);
    setSearch(false);
    setDataExpired({});
    setDataForAnother({});
    setDataFromAnother({});
    setDataPaid({});
    setDataSummary({});
    setSummary({});
    setExpired({});
    setPaid({});
  }

  return isReady ? (
    <Grid container className={s.container}>
      <Grid item container xs={11} className={s.header}>
        <Grid item lg={4} md={6} xs={12} className={s.titleContainer}>
          <WorkspacePremiumOutlinedIcon
            sx={{ fontSize: "1.8rem", color: "primary.main" }}
          />
          <h4>{t("prizes")}</h4>
        </Grid>

        <Grid item xs={12} md={6} lg={8} className={s.filters}>
          <Button
            disabled={!agencieSelected}
            variant="contained"
            className={s.filterButton}
            onClick={handleOpen}
          >
            <TuneRoundedIcon
              sx={{ color: "secondary.light", fontSize: "1.8rem" }}
            />
            <p>{t("filter")}</p>
          </Button>
          {searching ? (
            <Button
              variant="contained"
              sx={{ ml: 1 }}
              className={s.filterButton}
              onClick={() => clean()}
            >
              <p>{t("cleanFilters")}</p>
            </Button>
          ) : null}
          {loadingAgency ? (
            <Box sx={{ mx: 7 }}>
              <CircularProgress size="2rem" />
            </Box>
          ) : (
            <Button
              sx={{ ml: 1 }}
              onClick={showHide}
              color="primary"
              className={s.filterButton}
              variant="contained"
            >
              <p style={{ fontSize: "0.7rem" }}>{t("selectAgency")}</p>
            </Button>
          )}
        </Grid>
      </Grid>

      <Grid className={s.selectAgency} item xs={11}>
        <p style={{ fontSize: "1.3rem", fontWeight: "300" }}>
          {agencieSelected?.name
            ? `${t("salePoint")}: (${agencieSelected.number}${agencieSelected.subNumber !== null
              ? " - " + agencieSelected.subNumber
              : ""
            }) ${agencieSelected.name}`
            : t("agencyNotSelected")}
        </p>
        {showModal && (
          <AgencySelectModal
            open={showModal}
            setOpen={setShowModal}
            onSelect={handleConsulting}
          />
        )}
      </Grid>

      <Grid item xs={12} className={s.content}>
        <Grid item xs={12} className={s.tabContainer}>
          <Tabs
            value={tabValue}
            onChange={(e, value) => handleChangeTab(value)}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
            className={s.tabs}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            <Tab
              value="Resumen"
              label={t("summary")}
              className={`${s.tabItem} ${tabValue === "Resumen" && s.selected}`}
            />
            <Tab
              value="Pagados"
              label={t("paid")}
              className={`${s.tabItem} ${tabValue === "Pagados" && s.selected}`}
            />
            <Tab
              value="Pagados De Otra"
              label={"Pagados De Otra"}
              className={`${s.tabItem} ${tabValue === "Pagados De Otra" && s.selected
                }`}
            />
            <Tab
              value="Pagados Por Otra"
              label={"Pagados Por Otra"}
              className={`${s.tabItem} ${tabValue === "Pagados Por Otra" && s.selected
                }`}
            />
            <Tab
              value="Caducos"
              label={t("expired")}
              className={`${s.tabItem} ${tabValue === "Caducos" && s.selected}`}
            />
          </Tabs>
        </Grid>
        <Grid item xs={12} className={s.render}>
          <>
            {tabValue === "Resumen" && (
              <Resumen
                print={print}
                data={summary}
                loading={loading}
                filtro={filtro}
                setFiltro={setFiltro}
                setPrint={setPrint}
                listadoPlano={listadoPlano}
              />
            )}
            {tabValue === "Pagados" && (
              <Pagados
                print={print}
                data={paid}
                loading={loading}
                filtro={filtro}
                setFiltro={setFiltro}
                setPrint={setPrint}
                setFilter={setFiltro}
                setSearching={setSearching}
                setSearch={setSearch}
              />
            )}
            {tabValue === "Caducos" && (
              <Caducos
                print={print}
                data={expired}
                loading={loading}
                filtro={filtro}
                setFiltro={setFiltro}
                setPrint={setPrint}
              />
            )}
            {tabValue === "Pagados De Otra" && (
              <PagadosDeOtra
                print={print}
                data={paid} //CONSULTAR
                loading={loading}
                filtro={filtro}
                setFiltro={setFiltro}
                setPrint={setPrint}
              />
            )}
            {tabValue === "Pagados Por Otra" && (
              <PagadosPorOtra
                print={print}
                data={paid} //CONSULTAR
                loading={loading}
                filtro={filtro}
                setFiltro={setFiltro}
                setPrint={setPrint}
              />
            )}
          </>
        </Grid>
      </Grid>
      <ModalProviders
        open={openModal}
        setOpen={setOpenModal}
        setFilters={setFiltro}
        filters={filtro}
        setSearching={setSearching}
        setSearch={setSearch}
        tabValue={tabValue}
        dateFrom={dateFrom}
        dateTo={dateTo}
        setDateFrom={setDateFrom}
        setDateTo={setDateTo}
      />
    </Grid>
  ) : (
    <Grid container className={s.container}>
      <Grid item container xs={11} className={s.header}>
        <Grid item lg={6} md={6} xs={12} className={s.titleContainer}>
          <h4>Próximamente.</h4>
        </Grid>
      </Grid>
    </Grid>
  );
};
