import React, { useEffect, useState, /*useMemo*/ } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom"
import EditIcon from "@mui/icons-material/Edit";
import { createRow, formatResultConcentrators } from "../../../helpers/tableFunctions"
import styles from "./Concentrators.module.css"
import { getOrganizationAgencies } from "../../../api/gamma"
import Pagination from '@mui/material/Pagination';
import { useTranslation } from "react-i18next";
import FiltersModal from './Filters'
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
//-Material
import {
    TableContainer,
    TableHead,
    TableRow,
    TableBody,
    Table,
    Grid,
    MenuItem,
    CircularProgress,
    Select,
    TextField,
    TableCell,
    Tooltip,
    Snackbar,
    Alert
} from "@mui/material";
import Button from "@mui/material/Button";
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import HandleRequests from "../../Shared/handleRequests/HandleRequests.jsx";

const Concentrators = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCALES
    const [concentrators, setConcentrators] = useState([]);
    const [agenciesToSearch, setAgenciesToSearch] = useState([]);
    // REDUX
    const errors = useSelector(state => state.errors.concentratorsError)
    const { textColor } = useSelector((state) => state.local);
    const [primaryColor, setPrimaryColor] = useState("");
    const { uiConfig } = useSelector((state) => state.local);
    const activeOrganization = useSelector((state) => state.gamma.activeOrganization)

    const [alertError, setAlertError] = useState(false);

    //pagination
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [customPage, setCustomPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState({ status: 'ALL', type: 'ALL' })
    const [open, setOpen] = useState(false)
    const [searching, setSearching] = useState(false)

    // getOrganizationAgencies(filter);

    const [sort, setSort] = useState({
        field: 'name',
        criteria: 'ASC'
    })


    const { t } = useTranslation();
    const handleSort = (field) => {
        setSort(prev => ({
            field: field,
            criteria: prev.criteria === 'ASC' ? 'DESC' : 'ASC'
        }))
    }

    const handleChangePerPage = (e) => {
        setPerPage(e * 1)
    }

    const handlePagination = (e, v) => {
        setPage(v);
    };

    const handleOnClick = (obj) => {
        navigate(`./${obj.name.replaceAll(" ", "-")}/${obj.number}-${obj.subNumber || 0}/${obj.id}`)
    }

    useEffect(() => {
        setLoading(true);
        getOrganizationAgencies(activeOrganization?.id, page, perPage, sort, filter)
            .then(data => {
                setConcentrators(data.content);
                setTotalPages(data.totalPages);
            })
            .then(() => setLoading(false))
            .catch(() => {
                setLoading(false);
                setAlertError(true);
            });
    }, [page, perPage, sort, filter]);

    useEffect(() => {
        setPrimaryColor(uiConfig.primary.main);
    }, [uiConfig]);

    const cellStyles = {
        fontFamily: 'Roboto, sans-serif',
        fontSize: '0.9rem',
        fontWeight: '400',
        borderBottom: '1px solid rgba(0, 0, 0, 0.455)',
    }

    return (
        <div className={styles.container}>
            <HandleRequests error={errors}>
                <Grid container className={styles.header}>
                    <Grid item xs={3} className={styles.titleConcentrators}>
                        <PointOfSaleIcon sx={{ fontSize: '1.5rem', color: 'primary.main' }} />
                        <h1 className={styles.title}>{t("concentradoras")}</h1>
                    </Grid>
                    <Grid item xs={9} className={styles.searchBar}>
                        <Button variant='contained' className={styles.filterButton} onClick={() => setOpen(true)}>
                            <TuneRoundedIcon sx={{ color: "secondary.light", fontSize: "1.8rem" }} />
                            <p>{t('filter')}</p>
                        </Button>
                        {searching ?
                            <Button variant='contained' sx={{ ml: 1 }} className={styles.filterButton} onClick={() => {
                                setFilter((prevState) => ({ ...prevState, status: "ALL", type: 'ALL' }))
                                setSearching(false)
                            }}>
                                <p style={{ fontSize: '0.9rem' }}>{t('cleanFilters')}</p>
                            </Button> : null}
                        {/* <SearchBar arr={agenciesToSearch} keys={{ keys: ["name" , "number"] }}
                    result={"name"} onSelect={handleOnClick} format={formatResultConcentrators} 
                    primaryColor={primaryColor} placeholder={t("findAgency")}
                    component={"sale_points"}/>*/}
                    </Grid>
                </Grid>

                <div className={styles.tableContainer}>
                    {!loading ? !concentrators?.length ?
                        <Grid item xs={12} sx={{ fontSize: '2rem', fontWeight: 300, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                            {t('noResults')}
                        </Grid> :
                        <TableContainer className={styles.table}>
                            <Table>
                                <TableHead className={styles.tableHead}>
                                    <TableRow color={'primary.main'} sx={{ backgroundColor: 'primary.main' }}>
                                        <Tooltip title={t('sort')} placement="top-start">
                                            <TableCell sx={{ cursor: 'pointer', color: textColor, fontSize: '0.9rem' }} onClick={() => handleSort('number')}>{`${t('number')} ${sort.field === 'number' ? sort.criteria === 'ASC' ? '▲' : '▼' : ''}`}</TableCell>
                                        </Tooltip>
                                        <Tooltip title={t('sort')} placement="top-start">
                                            <TableCell sx={{ cursor: 'pointer', color: textColor, fontSize: '0.9rem' }} onClick={() => handleSort('name')}>{`${t('name')} ${sort.field === 'name' ? sort.criteria === 'ASC' ? '▲' : '▼' : ''}`}</TableCell>
                                        </Tooltip>
                                        <TableCell sx={{ color: textColor, fontSize: '0.9rem' }}>{t('contact')}</TableCell>
                                        <TableCell sx={{ color: textColor, fontSize: '0.9rem' }}>{t('address')}</TableCell>
                                        <TableCell sx={{ color: textColor, fontSize: '0.9rem' }}>{t('type')}</TableCell>
                                        <TableCell sx={{ color: textColor, fontSize: '0.9rem' }}>{t('state')}</TableCell>
                                        <TableCell sx={{ color: textColor, fontSize: '0.9rem' }}>{t('edit')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        concentrators?.map((concentrator, i) => {
                                            return (
                                                <TableRow key={i} sx={{ "&:hover": { backgroundColor: "secondary.light", cursor: "pointer" } }}>
                                                    {createRow(concentrator.number !== null && `${concentrator.number}`, "left", i % 2 === 0, cellStyles)}
                                                    {createRow(concentrator.name, "left", i % 2 === 0, cellStyles)}
                                                    {createRow(concentrator.mail || "-", "left", i % 2 === 0, cellStyles)}
                                                    {createRow((concentrator.address.street) + " " + (concentrator.address.number), "left", i % 2 === 0, cellStyles)}
                                                    {createRow(concentrator.agencyLevel === "CONCENTRATOR" ? t('concentradora') : t('agency'), "left", i % 2 === 0, cellStyles)}
                                                    {createRow(concentrator.status === "DISABLED" ? t("disabled") : t("enabled"), "left", i % 2 === 0, cellStyles)}
                                                    {createRow(<EditIcon color={"primary"} sx={{ fontSize: '1.4rem', cursor: "pointer", color: 'primary.main' }} onClick={() => handleOnClick(concentrator)} className={styles.btn} />, "left", i % 2 === 0, cellStyles)}
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        :
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }} className={styles.loader} >
                            <CircularProgress size={'3rem'} />
                        </Grid>
                    }
                </div>
                <Grid item container xs={10} sx={{ display: "flex", justifyContent: 'space-evenly', alignItems: 'center', mb: 5, mt: 5 }}>
                    <Select value={perPage} onChange={(e) => handleChangePerPage(e.target.value)}>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                    </Select>
                    <Pagination
                        showFirstButton
                        showLastButton
                        count={totalPages}
                        page={page}
                        onChange={handlePagination}
                        boundaryCount={2}
                        color={"secondary"} />
                    <form style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", width: '120px' }}>
                        <TextField type={'number'} value={customPage} onChange={(e) => setCustomPage(e.target.value)} sx={{ width: '100%', mr: '5px' }} />
                        <Button variant='contained' onClick={() => {
                            if (0 <= customPage && customPage <= totalPages) {
                                setPage(customPage * 1);
                            } else {
                                setCustomPage(page);
                            }
                        }}>Ir</Button>
                    </form>
                    <FiltersModal open={open} setOpen={setOpen} filter={filter} setFilter={setFilter} setSearching={setSearching} />
                </Grid>

            </HandleRequests>
            <Snackbar open={alertError} autoHideDuration={6000} onClose={() => setAlertError(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert variant="filled" onClose={() => setAlertError(false)} severity="error" sx={{ width: '100%' }}>
                    {t('tryInOtherMoment')}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default Concentrators;
