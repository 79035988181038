import styles from "./index.module.css";
import React from 'react'
import Grid from "@mui/material/Grid";
import { getOnLine } from "../../../api/saaservices/onLine";
import { useState, useEffect } from "react";
import PrintIcon from "@mui/icons-material/Print";
import DescriptionIcon from "@mui/icons-material/Description";
import { useSelector, useDispatch } from "react-redux";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { format, parse } from "date-fns";
import usePagination from "./Pagination";
import { exportExcel, exportPDF } from "../../../helpers/exportExcel";
import Swal from "sweetalert2";
import { Currency, currencyFormat } from "../../../helpers/currencyConvert";
import { Button } from "@mui/material";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import ModalProviders from "./ModalProviders/index";
import printTable from "../../../helpers/imprimir";
import my_agencies, {
  setSelectedAgencieToConsult,
} from "../../../store/slices/my_agencies";
import { useLocation, useNavigate } from "react-router-dom";
import AgencySelectModal from "../../Shared/AgencySelectModal/AgencySelectModal";
import CircularProgress from "@mui/material/CircularProgress";
import {
  formatJava,
  formatJavaCompleto,
  formatJavaTiempo,
  timeFormat,
  formatArg,
  minDateRange,
} from "../../../config/strings";
import { configDate } from "../../../helpers/convertDate";
import {
  AgencyPage,
  getPresentAgencyInPath,
  logOutPath,
  hasAgencyInPath,
  getAgencyNumberDisplay,
} from "../../../helpers/path";
import { unique } from "../../../helpers/arrays";
import { updateLocation } from "../../../store/slices/localSlice";
import { CalendarioHoy, CalendarioSemanaPasada } from "../../../helpers/today";
import { getSaaId } from "../../../utils/session.utils";

export const OnLine = () => {
  const [isReady, setIsReady] = useState(true);
  const [openFilter, setOpenFilter] = useState(false);
  //const [dateFrom, setDateFrom] = useState(
  //  diaAyer() > minDateRange ? diaAyer() : minDateRange
  //);
  //const [dateTo, setDateTo] = useState(diaHoy());
  const firstDayMonth = new Date().setDate(1);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(CalendarioHoy());
  const { imputations, organization_Configuration } = useSelector(
    (state) => state.gamma
  );
  const { activeOrganization } = useSelector((state) => state.gamma);
  const [movimientos, setMovimientos] = useState([]);
  const agencies = useSelector((state) => state.myAgencies.user_agencies);
  const { textColor } = useSelector((state) => state.local);
  const [search, setSearch] = useState(false);
  const [searching, setSearching] = useState(false);
  const [print, setPrint] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const agencieSelected = useSelector(
    (state) => state.myAgencies?.selected_agencie_to_consult
  );
  const [filterParams, setFilterParams] = useState({
    dateFrom: diaAyer(),
    dateTo: diaHoy(),
    game: "",
    concept: "",
  });
  const { formatLocalDate, formatLocalDateTime } = configDate();
  const location = useLocation();
  const actualPage = useSelector(
    (state) => state.pagination.currentAccountActualPage
  );
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState();

  const page = usePagination(
    actualPage,
    imputations?.totalPages,
    agencieSelected?.id
  );
  const handlePagination = (e, p) => {
    page.jump(p);
  };

  const { formatCurrency } = currencyFormat();

  const defaultMovimientos = {
    headers: [],
    movements: [],
  };

  useEffect(() => {
    dispatch(updateLocation({ online: true }));
  }, []);

  function diaAyer() {
    let d = new Date();
    d.setDate(d.getDate() - 1);
    return format(d, formatArg);
  }

  function diaHoy() {
    return format(new Date(), formatArg);
  }

  async function loadMovimientos(agencie, dateTo, dateFrom) {
    const saaId = getSaaId()
    return await getOnLine(
      saaId,
      dateFrom,
      dateTo,
      agencie.number,
      agencie.subNumber === null ? -1 : agencie.subNumber
    );
  }

  useEffect(() => {
    //setMovimientos(defaultMovimientos);
    attemptLoadMovimientos();
  }, []);

  async function attemptLoadMovimientos() {
    if (agencieSelected) {
      setLoading(true);
      await loadMovimientos(
        agencieSelected,
        filterParams.dateTo,
        filterParams.dateFrom
      ).then((mov) => {
        setMovimientos(mov);
        setLoading(false);
      }).catch(error => console.log(error));
    }
  }

  useEffect(() => {
    if (search) {
      attemptLoadMovimientos().then(() => {
        setSearch(false);
      });
    }
  }, [search, agencieSelected]);

  const excelItem = () => {
    let excelData = [];
    const traverse = movimientos?.forEach((item) => {
      const itemData = {};
      itemData[t("subagency")] = item.subagencia;
      itemData[t("client")] = item.cliente;
      itemData[t("date")] = item.fecha;
      itemData[t("imputation")] = item.descripcionJuego;
      itemData[t("descriptionCode")] = item.movimiento;
      itemData[t("couponQuantity")] = item.cantidadCupones;
      itemData[t("total")] = formatCurrency(item.totalImporte);
      excelData.push(itemData);
    });
    return excelData;
  };

  const excelDownload = () => {
    Swal.fire({
      title: t("excelConfirm"),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "#d33",
      cancelButtonText: t("cancel"),
      confirmButtonText: t("accept"),
    }).then((result) => {
      if (result.isConfirmed) {
        let today = new Date();
        exportExcel(
          excelItem(),
          `Detalle Movimientos Diarios - ${today.toISOString()}`
        );
      }
    });
  };

  // const subTotalRow = (movements) => {
  //   let sub = 0;
  //   const mov = MovementsByImputation(movements, imputationId)
  //   sub = subTotal(mov)
  //   return sub;
  // }

  function formatDate(string) {
    if (!string?.length) {
      return "";
    }
    return new Date(string).toLocaleDateString();
  }

  function showHide() {
    setShowModal(!showModal);
  }

  const handleConsulting = (agencie) => {
    if (typeof agencie === "object" && Object.keys(agencie).length !== 0) {
      dispatch(setSelectedAgencieToConsult(agencie));
    }
    setLoading(true);
    loadMovimientos(agencie, filterParams.dateTo, filterParams.dateFrom).then(
      (mov) => {
        setMovimientos(mov);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (print === true) {
      printTable(document.getElementById("table-print"));
    }
    setPrint(false);
  }, [print]);

  return isReady ? (
    <Grid container className={styles.container}>
      <Grid item container xs={11} className={styles.header}>
        <Grid item lg={6} md={6} xs={12} className={styles.titleContainer}>
          <AttachMoneyIcon sx={{ fontSize: "1.8rem", color: "primary.main" }} />
          <h4>{t("dailyTransactionsDetail")}</h4>
        </Grid>

        <Grid item xs={12} md={5} lg={5} className={styles.filters}>
          <Button
            variant="contained"
            className={styles.filterButton}
            onClick={() => setOpenFilter(true)}
          >
            <TuneRoundedIcon
              sx={{ color: "secondary.light", fontSize: "1.8rem" }}
            />
            <p style={{ fontSize: "0.9rem" }}>{t("filter")}</p>
          </Button>
          {searching ? (
            <Button
              variant="contained"
              sx={{ ml: 1 }}
              className={styles.filterButton}
              onClick={() => {
                setFilterParams({
                  dateFrom: diaAyer(),
                  dateTo: diaHoy(),
                  concept: "",
                });
                setMovimientos([]);
                setDateFrom(diaAyer());
                setDateTo(diaHoy());
                setSearching(false);
              }}
            >
              <p style={{ fontSize: "0.9rem" }}>{t("cleanFilters")}</p>
            </Button>
          ) : null}
          <Button
            sx={{ ml: 1 }}
            onClick={showHide}
            color="primary"
            className={styles.filterButton}
            variant="contained"
          >
            <p style={{ fontSize: "0.7rem" }}>{t("selectAgency")}</p>
          </Button>
        </Grid>
      </Grid>

      <Grid className={styles.selectAgency} item xs={11}>
        <p style={{ fontSize: "1.3rem", fontWeight: "300" }}>
          {agencieSelected?.name
            ? `${t("salePoint")}: (${agencieSelected.number}${agencieSelected.subNumber !== null
              ? " - " + agencieSelected.subNumber
              : ""
            }) ${agencieSelected.name}`
            : t("agencyNotSelected")}
        </p>
        {showModal && (
          <AgencySelectModal
            open={showModal}
            setOpen={setShowModal}
            onSelect={handleConsulting}
          />
        )}
      </Grid>

      {loading ? (
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "300px",
          }}
        >
          <CircularProgress size="3rem" />
        </Grid>
      ) : (
        <>
          {print !== true ? (
            <Grid container item xs={12} className={styles.content}>
              <Grid item container xs={11} sx={{ mt: 3 }}>
                <TableContainer className={styles.tableContainer}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableCell
                        sx={{
                          backgroundColor: "primary.main",
                          color: textColor,
                        }}
                      >
                        {t("subagency")}
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "primary.main",
                          color: textColor,
                        }}
                      >
                        {t("client")}
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "primary.main",
                          color: textColor,
                        }}
                      >
                        {t("date")}
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "primary.main",
                          color: textColor,
                        }}
                      >
                        {t("imputation")}
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "primary.main",
                          color: textColor,
                        }}
                      >
                        {t("descriptionCode")}
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "primary.main",
                          color: textColor,
                        }}
                      >
                        {t("couponQuantity")}
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "primary.main",
                          color: textColor,
                        }}
                      >
                        {`${t("total")}($)`}
                      </TableCell>
                    </TableHead>
                    <TableBody>
                      {/* {Array.isArray(movimientos?.headers) && */}
                      {movimientos && movimientos.length > 0 ? (
                        <>
                          {movimientos?.map((item, i) => {
                            return (
                              <TableRow
                                sx={{
                                  "&:hover": {
                                    backgroundColor: "secondary.light",
                                  },
                                }}
                              >
                                <TableCell>{item.subagencia}</TableCell>
                                <TableCell>{item.cliente}</TableCell>
                                <TableCell>{item.fecha}</TableCell>
                                <TableCell>{item.descripcionJuego}</TableCell>
                                <TableCell>{item.movimiento}</TableCell>
                                <TableCell>{item.cantidadCupones}</TableCell>
                                <TableCell>
                                  <Currency value={item.totalImporte} />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </>
                      ) : (
                        <TableCell colSpan={6}>
                          <p>{t("noInfo")}</p>
                        </TableCell>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid
                sx={{ marginBottom: "5vw", mt: 2 }}
                justifyContent="center"
                alignItems="center"
                container
                item
                xs={11}
              >
                <Grid
                  container
                  item
                  xs={7}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h4 className={styles.date}>
                    {t("dateRange")}:{" "}
                    <span className={styles.inputationDate}>
                      {filterParams?.dateFrom}{" "}
                      {filterParams?.dateFrom.length &&
                        filterParams?.dateTo.length
                        ? "al"
                        : "-"}{" "}
                      {filterParams?.dateTo}
                    </span>
                  </h4>
                </Grid>
                <Grid item container xs={5} sx={{ justifyContent: "flex-end" }}>
                  <Button
                    size="small"
                    variant="contained"
                    sx={{ mr: 1, height: "2.5rem" }}
                    className={styles.optionsContainer}
                    onClick={() => setPrint(true)}
                  >
                    <PrintIcon
                      sx={{ fontSize: "1.5rem", color: textColor }}
                      className={styles.iconOptions}
                    />
                  </Button>

                  <Button
                    size="small"
                    variant="contained"
                    className={styles.optionsContainer}
                    onClick={() => excelDownload()}
                    sx={{ height: "2.5rem" }}
                  >
                    <DescriptionIcon
                      sx={{ fontSize: "1.5rem", color: textColor }}
                      className={styles.iconOptions}
                    />
                  </Button>
                </Grid>
              </Grid>

              <ModalProviders
                open={openFilter}
                setOpen={setOpenFilter}
                setFilters={setFilterParams}
                filters={filterParams}
                setSearching={setSearching}
                setSearch={setSearch}
                dateFrom={dateFrom}
                dateTo={dateTo}
                setDateFrom={setDateFrom}
                setDateTo={setDateTo}
                movimientos={movimientos}
              />
            </Grid>
          ) : (
            <Grid
              container
              item
              xs={12}
              className={styles.content}
              id="table-print"
            >
              {agencieSelected.agencyLevel ===
                organization_Configuration.settlementWay ? (
                <Grid
                  justifyContent="space-between"
                  alignItems="center"
                  container
                  item
                  xs={11}
                  sx={{ mt: 3 }}
                ></Grid>
              ) : (
                <></>
              )}
              <Grid item container xs={11} sx={{ mt: 3 }}>
                <TableContainer className={styles.tableContainer}>
                  <Table stickyHeader className="print-table">
                    <TableHead>
                      <TableCell
                        sx={{
                          backgroundColor: "primary.main",
                          color: textColor,
                        }}
                      >
                        {t("subagency")}
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "primary.main",
                          color: textColor,
                        }}
                      >
                        {t("client")}
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "primary.main",
                          color: textColor,
                        }}
                      >
                        {t("date")}
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "primary.main",
                          color: textColor,
                        }}
                      >
                        {t("imputation")}
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "primary.main",
                          color: textColor,
                        }}
                      >
                        {t("descriptionCode")}
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "primary.main",
                          color: textColor,
                        }}
                      >
                        {t("couponQuantity")}
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "primary.main",
                          color: textColor,
                        }}
                      >
                        {`${t("total")}($)`}
                      </TableCell>
                    </TableHead>
                    <TableBody>
                      {/* {Array.isArray(movimientos?.headers) && */}
                      {console.log("MOVIEMIENTOS : ", movimientos)}
                      {movimientos ? (
                        <>
                          {movimientos?.map((item, i) => {
                            return (
                              <TableRow
                                sx={{
                                  backgroundColor:
                                    item.subAgencia === null
                                      ? "secondary.main"
                                      : "primary.light",
                                }}
                              >
                                <TableCell>{item.subAgencia}</TableCell>
                                <TableCell>{item.cliente}</TableCell>
                                <TableCell>{item.fecha}</TableCell>
                                <TableCell>{item.descripcionJuego}</TableCell>
                                <TableCell>{item.movimiento}</TableCell>
                                <TableCell>{item.cantidadCupones}</TableCell>
                                <TableCell>
                                  <Currency value={item.totalImporte} />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </>
                      ) : (
                        <TableCell colSpan={6}>
                          <p>{t("noInfo")}</p>
                        </TableCell>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid
                sx={{ marginBottom: "5vw" }}
                justifyContent="center"
                alignItems="center"
                container
                item
              >
                <Grid
                  container
                  item
                  xs={11}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h4 className={styles.date}>
                    {t("dateRange")}:{" "}
                    <span className={styles.inputationDate}>
                      {filterParams?.dateFrom}{" "}
                      {filterParams?.dateFrom.length &&
                        filterParams?.dateTo.length
                        ? "al"
                        : "-"}{" "}
                      {filterParams?.dateTo}
                    </span>
                  </h4>
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Grid>
  ) : (
    <Grid container className={styles.container}>
      <Grid item container xs={11} className={styles.header}>
        <Grid item lg={6} md={6} xs={12} className={styles.titleContainer}>
          <h4>Próximamente.</h4>
        </Grid>
      </Grid>
    </Grid>
  );
};
