// import react
import { useState, useEffect } from "react";
// import styles
import "./index.css";
// import material UI
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import FilledInput from "@mui/material/FilledInput";
// import api
import authService from "../../../services/auth.service";
// import react-router
import { useNavigate } from "react-router-dom";
import abacoLogoWhite from "../../../../src/assets/abaco-logo-b.png";
import { logoBaseOnOrgId } from "../../../api/themes/ThemeService";
import PersonIcon from '@mui/icons-material/Person';
import { Typography } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const organizationURL = {
  default: -1,
};

function getOrgIdFromHostName() {
  const currentUrl = window.location.hostname;

  const orgId = organizationURL[currentUrl];

  if (!orgId) {
    return organizationURL.default;
  }

  return orgId;
}

// ============================= | RESET PASSWORD | ============================

export function ResetPassword() {
  // Section: Navigation
  const navigate = useNavigate();
  // session
  const [logError, setLogError] = useState({ error: false, message: "  " });

  const [logo, setLogo] = useState(null);

  const [formData, setFormData] = useState({ username: '' })

  const [openMailNotification, setOpenMailNotification] = useState({
    isOpen: false,
    message: "",
    title: ""
  })

  useEffect(() => {
    process.env.REACT_APP_URL_ORGANIZATIONS.split(";").forEach((data) => {
      const organizationData = data.split(",");
      organizationURL[organizationData[0]] = organizationData[1];
    });


    const getLogo = async (organizationId) => {
      try {
        if (organizationId !== -1) {
          const response = await logoBaseOnOrgId(organizationId);
          setLogo(response.imgBase64);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const organizationId = getOrgIdFromHostName();
    getLogo(organizationId);
  }, []);

  // Section: Handler
  const handleSubmit = async (event) => {
    setLogError({ error: false, message: "" });
    event.preventDefault();

    const form = event.target;
    const formData = new FormData(form);

    const userData = {
      username: formData.get("username"),
    }

    try {
      const response = await authService.resetPassword(
        userData.username,
        '/'
      );
      console.log(response)
      setOpenMailNotification((prev) => {
        return {
          ...prev,
          isOpen: true,
          message: "Se envió un email para el cambio de contraseña.",
          title: 'Olvido de contraseña'
        }
      })
    } catch (error) {
      const errorMessage = error.message.split(":");
      setLogError({ error: true, message: errorMessage[1] });
    }
  };

  const handleOnChange = (event) => {
    setFormData((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value
      }
    })
  }

  const handleOnClose = () => {
    setOpenMailNotification((prev) => ({ ...prev, isOpen: false }))
    navigate('/', { replace: true })
  }

  // Section: JSX
  return (
    <div className="login--container">
      <div className="logo--container">
        <div className="dynamic--logo">
          {logo !== null &&
            <img alt="logo" className="logo--state" src={logo} />
          }
        </div>
        <div className="static--logo">
          <img alt="logo" className="logo--img" src={abacoLogoWhite} />
        </div>
      </div>
      <div className="form--container">
        <Box
          className="form--item"
          component="form"
          onSubmit={handleSubmit}
        >
          <p
            className={`${logError.error ? "show--message" : "hidde--message"}`}
          >
            {logError.message}
          </p>
          <Box className="forget-box">
            <IconButton aria-label="delete" size="small" onClick={() => navigate("/", { replace: true })}>
              <ArrowBackIcon fontSize="inherit" />
            </IconButton>
            <Typography
              variant="h6"
              color="white"
            >
              Recuperación contraseña
            </Typography>
          </Box>
          <div className="input--style">
            <FormControl
              variant="filled"
              sx={{ display: 'flex' }}
              fullWidth
            >
              <FilledInput
                hiddenLabel={true}
                id="filled-adornment-user"
                type='text'
                value={formData.username}
                onChange={handleOnChange}
                name="username"
                placeholder="DNI o correo electrónico"
                color="abaco"
                label="Usuario"
                startAdornment={
                  <InputAdornment position="end" >
                    <PersonIcon
                      sx={{
                        color: 'action.active',
                        mr: 1
                      }}
                    />
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
          <div className="form--button">
            <Button
              disabled={!formData.username ? true : false}
              variant="contained"
              type="submit"
              color="abaco"
              sx={{
                color: '#9999CC',
                borderRadius: '2rem',
                padding: '.4rem 1.5rem',
                textTransform: 'none'
              }}
            >
              Enviar
            </Button>
          </div>
        </Box>
      </div>
      {openMailNotification.isOpen &&
        <Dialog
          open={openMailNotification}
          onClose={handleOnClose}
        >
          <DialogTitle
            id="alert-dialog-title"
          >
            {openMailNotification.title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {openMailNotification.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleOnClose} >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      }
    </div>
  );
}
