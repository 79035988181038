/* TODO: Externalizar variables entorno, implementando el .env */
export const MAIN_URL = {
  apiPath: "http://54.177.224.223",
  localPath: "http://123.456.789",
};

export const API_CONFIG_ENDPOINT = {
  apiUrl: "https://apiconf.tecnoaccion.borealit.biz",
  local: "http://localhost:8070",
};

export const API_TERMS_ENDPOINT = {
  apiUrl: "https://apiterms.tecnoaccion.borealit.biz",
  local: "http://localhost:8074",
};

export const API_THEMES_ENDPOINT = {
  apiUrl: "https://apithemes.tecnoaccion.borealit.biz",
  local: "http://localhost:8072",
};

export const roles = {
  superusuario: "ABACO_ROOT",
  administradorLoteria: "Administrador Loteria Abaco",
  concentradora: "Usuario Concentradora",
  agenciero: "Usuario Agenciero",
  empleado: "Empleado",
};

export const STATUS_OK = 200;

export const BASE_THEME = "Playa";

export const itemDatas = [
  {
    title: "Lavanda",
    primary: {
      main: "#ffcc33",
      light: "#ffff69",
      dark: "#c89b00",
    },
    secondary: {
      main: "#666699",
      light: "#9594ca",
      dark: "#393c6b",
    },
  },
  {
    title: "Cielo",
    primary: {
      main: "#336699",
      light: "#6693ca",
      dark: "#003c6b",
    },
    secondary: {
      main: "#6699cc",
      light: "#98caff",
      dark: "#326b9b",
    },
  },
  {
    title: "Fresa",
    primary: {
      main: "#f2526f",
      light: "#ff869b",
      dark: "#ba1244",
    },
    secondary: {
      main: "#bfb2aa",
      light: "#f2e4dc",
      dark: "#8f837b",
    },
  },
  {
    title: "Naturaleza",
    primary: {
      main: "#365902",
      light: "#648633",
      dark: "#132f00",
    },
    secondary: {
      main: "#ecf23d",
      light: "#ffff74",
      dark: "#b6c000",
    },
  },
  {
    title: "Playa",
    primary: {
      main: "#357c8c",
      light: "#67abbc",
      dark: "#00505f",
    },
    secondary: {
      main: "#f29e38",
      light: "#ffcf68",
      dark: "#bb6f00",
    },
  },
];

export const languages = {
  es: "es",
  pt: "pt",
};

export const organization = {
  id: 9,
};

export function yesterday() {
  let date = new Date();
  date.setDate(date.getDate() - 2);
  let day = date.getDate();
  day = day.toString().padStart(2, "0");
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}T00:00:00`;
  return currentDate;
}
export function today() {
  let today = new Date();
  let tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate());
  let day = tomorrow.getDate();
  day = day.toString().padStart(2, "0");
  let month = tomorrow.getMonth() + 1;
  let year = tomorrow.getFullYear();
  let currentDate = `${year}-${month}-${day}T23:59:59`;
  return currentDate;
}

export const agency = {
  id: 0,
  name: "string",
  number: 0,
  subNumber: 0,
  agencyKind: {
    id: 0,
    name: "string",
  },
  agencyLevel: "CONCENTRATOR",
  level: 0,
  status: "ENABLED",
  ocumentNumber: "string",
  cuiNumber: "string",
  phone: "string",
  mail: "string",
  enableDate: "2022-11-30",
  validityDateFrom: "2022-11-30",
  validityDateTo: "2022-11-30",
  personKind: {
    id: 0,
    name: "string",
  },
  personSubkind: {
    id: 0,
    name: "string",
  },
  documentKind: {
    id: 0,
    name: "string",
  },
  address: {
    id: 0,
    street: "string",
    number: "string",
    complement: "string",
    zipcode: "string",
    latitude: "string",
    longitude: "string",
    location: {
      id: 0,
      name: "string",
      province: {
        id: 0,
        name: "string",
        country: {
          id: 0,
          name: "string",
        },
      },
    },
  },
  organization: {
    id: 0,
    name: "string",
  },
  activeMachines: 0,
};

export const EmptyMovement = {
  id: 0,
  source: "MANUAL",
  status: "PENDING",
  amount: 0,
  imputationDate: "2022-11-30T17:03:51.133Z",
  created: "2022-11-30T17:03:51.133Z",
  lottery: {
    id: 0,
    name: "string",
  },
  currency: {
    id: 0,
    name: "string",
    symbol: "string",
  },
  agency: {
    id: 0,
    name: "string",
    organizationId: 0,
  },
  raffle: {
    id: 0,
    number: "string",
    raffleStatus: "RELEASED",
    raffleDate: "2022-11-30T17:03:51.133Z",
    raffleCategory: {
      id: 0,
      name: "string",
    },
  },
  accountingCode: {
    id: 0,
    description: "string",
    currency: {
      id: 0,
      name: "string",
      symbol: "string",
    },
    code: "string",
    destinationAccount: "string",
    costCenter: "string",
    kind: "DEBIT",
    accountingCodeKind: {
      id: 0,
      name: "string",
    },
    imputation: {
      id: 0,
      name: "string",
      imputationKind: {
        id: 0,
        name: "string",
        enabled: true,
      },
    },
    behaviour: "PROVIDER",
    saleChannel: {
      id: 0,
      name: "string",
    },
  },
  saleChannel: {
    id: 0,
    name: "string",
  },
  raffleReference: "string",
  settlementReference: "string",
  observations: "string",
};

function buscarEnAgencias(agencias, nombre, numero) {
  let resultado = null;

  agencias.some((agencia) => {
    return agencia.subagencia.some((subagencia) => {
      if (subagencia.name === nombre || subagencia.number === numero) {
        resultado = subagencia;
        return true;
      }
    });
  });

  return resultado;
}

export const formatJava = "yyyy-MM-dd";
export const formatJavaTiempo = "yyyy-MM-dd HH:mm:ss";
export const formatJavaCompleto = "yyyy-MM-dd'T'HH:mm:ss";
export const timeJavaFormat = "HH:mm:ss.SSS";
export const formatArg = "dd/MM/yyyy";
export const formatArgTime = "dd/MM/yyyy HH:mm:ss";
export const timeFormat = "HH:mm:ss";
export const formatTimeTermsCond = "HH:mm:ss.SSSxxx";
export const formatDateTimeTermsCond = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx";

export const minDateRange = new Date("1/1/2022");
