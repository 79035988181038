import { organization } from "../../config/strings";
import apiClient from "../../utils/api.client";
import { getSaaId } from "../../utils/session.utils";

// premios de otra
export const getPrizesFromAnother = async (
  organizationId,
  desde,
  hasta,
  agencia,
  subagencia,
  tipoAgencia
) => {
  try {
    const saaId = getSaaId()
    const response = await apiClient.get(`/saaServices/saaServices/services/abaco/premiosdeotra?organizacionId=${saaId}&fechadesde=${desde}&fechahasta=${hasta}&agencia=${agencia}&subagencia=${subagencia}&tipoagencia=${tipoAgencia}`);
    const data = response.data.content;

    return data;
  } catch (error) {
    console.error('Error getPrizesFromAnother EP: ', error);
  }
};

//premios por otra
export const getPrizesForAnother = async (
  organizationId,
  desde,
  hasta,
  agencia,
  subagencia,
  tipoAgencia
) => {
  try {
    const saaId = getSaaId()
    const response = await apiClient.get(`/saaServices/saaServices/services/abaco/premiosxotra?organizacionId=${saaId}&fechadesde=${desde}&fechahasta=${hasta}&agencia=${agencia}&subagencia=${subagencia}&tipoagencia=${tipoAgencia}`);
    const data = response.data.content;

    return data;
  } catch (error) {
    console.error('Error at getPrizesForAnother EP: ', error);
    throw error
  }
};

//premios res
export const getPrizesRes = async (
  organizationId,
  agencia,
  fecha,
  subagencia,
  tipoagencia,
  juego
) => {
  try {
    const saaId = getSaaId()
    const response = await apiClient(`/saaServices/saaService/services/abaco/premiosres?organizacionId=${saaId}&fecha=${fecha}&agencia=${agencia}&subagencia=${subagencia}&juego=${juego}&tipoagencia=${tipoagencia}`);
    const data = response.data.content;

    return data;
  } catch (error) {
    console.error('Error at getPrizesRes EP: ', error);
    throw error
  }
};

//premios caducos
export const getPrizesExpired = async (
  organizationId,
  agencia,
  desde,
  hasta,
  subagencia,
  tipoAgencia,
  juego
) => {
  try {
    const saaId = getSaaId()
    const response = await apiClient.get(`/saaServices/saaServices/services/abaco/premioscaducos?organizacionId=${saaId}&fechaDesde=${desde}&agencia=${agencia}&subagencia=${subagencia}&juego=${juego}&tipoagencia=${tipoAgencia}&fechaHasta=${hasta}`);
    const data = response.data.content;

    return data;
  } catch (error) {
    console.error('Error at getPrizesExpired EP: ', error);
  }
};

//Premios caducos futuros
export const getFuturePrizesExpired = async (
  organizationId,
  agencia,
  desde,
  hasta,
  subagencia,
  juego,
  tipoagencia
) => {
  try {
    const saaId = getSaaId()
    const response = await apiClient.get(`/saaServices/saaServices/services/abaco/premioscaducosfuturos?organizacionId=${saaId}&fechaDesde=${desde}&agencia=${agencia}&subagencia=${subagencia}&juego=${juego}&tipoagencia=${tipoagencia}&fechaHasta=${hasta}`);
    const data = response.data;

    return data;
  } catch (error) {
    console.error('Error at getFuturePrizesExpired EP: ', error);
  }
};

//Premios pagados
export const getPrizesPaid = async (
  organizationId,
  agencia,
  desde,
  hasta,
  subagencia,
  tipoAgencia,
  juego
) => {
  try {
    const saaId = getSaaId()
    const response = await apiClient.get(`/saaServices/saaServices/services/abaco/premiospagados?organizacionId=${saaId}&fechaDesde=${desde}&fechaHasta=${hasta}&agencia=${agencia}&subagencia=${subagencia}&juego=${juego}&tipoagencia=${tipoAgencia}`);
    const data = response.data;

    return data;
  } catch (error) {
    console.error('Error at getPrizesPaid EP: ', error);
    throw error;
  }
};

//premios resumen
export const getPrizesSummary = async (
  organizationId,
  agencia,
  fecha,
  subagencia,
  tipoAgencia,
  juego
) => {
  try {
    const saaId = getSaaId()
    const response = await apiClient.get(`/saaServices/saaServices/services/abaco/premiosresumen?organizacionId=${saaId}&fecha=${fecha}&agencia=${agencia}&subagencia=${subagencia}&juego=${juego}&tipoagencia=${tipoAgencia}`);
    const data = response.data;

    return data;
  } catch (error) {
    console.error('Error at getPrizesSummary EP: ', error);
    throw error
  }
};

export const getPlayDetails = async (organizationId, apuesta) => {
  try {
    const saaId = getSaaId()
    const response = await apiClient.get(`/saaServices/saaServices/services/abaco/detalleApuesta?organizacionId=${saaId}&numeroApuesta=${apuesta}`)
    const data = response.data;

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getcoupons = async (numeroApuesta, organizationId) => {
  try {
    const saaId = getSaaId()
    const response = await apiClient.get(`/saaServices/saaServices/services/abaco/detalleCupon?organizacionId=${saaId}&numeroApuesta=${numeroApuesta}`);
    const data = response.data;

    return data;
  } catch (error) {
    console.error('Error at getcoupons EP: ', error);
  }
};

// //premios de otra
// export const getPrizesFromAnother = async () => {
//         try{
//             const response = await fetch('/saaServices/services/abaco/premiosdeotra?organizacionId=23&agencia=6&subagencia=0',{
//                 method: "GET",
//                 headers: {"Content-Type": "application/json","accept": "application/json"}
//             })
//             return response.json();
//         }catch(e){
//             console.log(e)
//         }
// }

// //premios por otra
// export const getPrizesForAnother = async () => {
//         try{
//             const response = await fetch('/saaServices/saaServices/services/abaco/premiosxotra?organizacionId=23&agencia=6&subagencia=0&tipoagencia=2',{
//                 method: "GET",
//                 headers: {"Content-Type": "application/json","accept": "application/json"}
//             })
//             return response.json();
//         }catch(e){
//             console.log(e)
//         }
// }

// //premios res
// export const getPrizesRes = async () => {
//         try{
//             const response = await fetch('/saaService/saaService/services/abaco/premiosres?organizacionId=23&fecha=16-11-2022&agencia=9&subagencia=0&juego=0&tipoagencia=1',{
//                 method: "GET",
//                 headers: {"Content-Type": "application/json","accept": "application/json"}
//             })
//             return response.json();
//         }catch(e){
//             console.log(e)
//         }
// }

// //premios caducos
// export const getPrizesExpired = async () => {
//         try{
//             const response = await fetch('/saaServices/saaServices/services/abaco/premioscaducos?organizacionId=23&fechaDesde=16/11/2022&agencia=9&subagencia=0&tipoagencia=1&fechaHasta=20/12/2022',{
//                 method: "GET",
//                 headers: {"Content-Type": "application/json","accept": "application/json"}
//             })
//             return response.json();
//         }catch(e){
//             console.log(e)
//         }
// }

// //Premios caducos futuros
// export const getFuturePrizesExpired = async () => {
//         try{
//             const response = await fetch('/saaServices/saaServices/services/abaco/premioscaducosfuturos?organizacionId=23&fechaDesde=16/11/2022&agencia=9&subagencia=0&juego=0&tipoagencia=1&fechaHasta=20/12/2022',{
//                 method: "GET",
//                 headers: {"Content-Type": "application/json","accept": "application/json"}
//             })
//             return response.json();
//         }catch(e){
//             console.log(e)
//         }
// }

// //Premios pagados
// export const getPrizesPaid = async () => {
//         try{
//             const response = await fetch('/saaServices/saaServices/services/abaco/premiospagados?organizacionId=23&fechaDesde=16/11/2022&fechaHasta=17/11/2022&agencia=9&subagencia=0&tipoagencia=1',{
//                 method: "GET",
//                 headers: {"Content-Type": "application/json","accept": "application/json"}
//             })
//             return response.json();
//         }catch(e){
//             console.log(e)
//         }
// }

// //premios resumen
// export const getPrizesSummary= async () => {
//         try{
//             const response = await fetch('/saaServices/saaServices/services/abaco/premiosresumen?organizacionId=23&fecha=16/11/2022&agencia=9&subagencia=0&juego=0&tipoagencia=1',{
//                 method: "GET",
//                 headers: {"Content-Type": "application/json","accept": "application/json"}
//             })
//             return response.json();
//         }catch(e){
//             console.log(e)
//         }
// }
