// react-router imports
import { useNavigate } from "react-router-dom";
import authService from "../services/auth.service";
// react imports
import React, { useState, useEffect, useCallback, useRef } from "react";
// utils imports
import { roles } from "../config/strings";
// api imports
import {
  getAllConfigurations,
  getOrganizationPackage,
} from "../api/config/ConfigService";
import { getOrganizationConfiguration } from "../api/general/generalService";
import {
  getAgencieByIdAwait,
  getAgenciesRelationed,
} from "../api/agencies/AgenciesService";
import { getRoles, getAllOrganizations, getloggedUser } from "../api/gamma";
import { getLegal, getLegals } from "../api/local";
// theme imports
import { currentLogo, currentTheme } from "../api/themes/ThemeService";
// redux slice imports
import {
  allRolesUpdate,
  allOrganizations,
  loggedUser,
  setActiveOrganization,
  appConfigLoader,
  organizationConfiguration,
  sliceOrganizationPackage,
} from "../store/slices/gammaSlice";
import {
  uiConfigUpdate,
  uiLogosUpdate,
  uiProfilePictureUpdate,
  legalTermsUpdate,
  uiOrganizationLogo,
  resetLogos,
} from "../store/slices/localSlice";
import { setSelectedAgencieToConsult } from "../store/slices/my_agencies";
// redux imports
import { useDispatch, useSelector } from "react-redux";
// components imports
import { ComponentError } from "../components/ErrorPages/ComponentsError";
// material imports
import CircularProgress from "@mui/material/CircularProgress";
// translation utils imports
import { useTranslation } from "react-i18next";
// authorization
import { useAuth } from "../components/Auth/AuthProvider";

const INIT = "init";
const SETTLED = "settled";

// FIX: This has to be in its own file
const styles = {
  width: "100%",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const stylesH4 = {
  fontSize: "1.8rem",
  fontWeight: "300",
  fontFamily: "Roboto, sans-serif",
};

const confState = {
  conf: INIT,
  usr: INIT,
  theme: INIT,
  org: INIT,
  allOrg: INIT,
  roles: INIT,
  orgConf: INIT,
  legals: INIT,
  legal: INIT,
  agencyRelation: INIT,
  agency: INIT,
};

/*================================ | PRELOAD | ===============================*/

/* This component load all the required data to initialize the app */
export const Preload = () => {
  // Section: Session tools
  const { session, userId } = useAuth(); // using hook to get current session

  // Defining navigation hook
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Defining translation hook
  const { t } = useTranslation();

  // Section: Redux State
  const currentUser = useSelector((state) => state.gamma.logged_user);
  const activeOrganization = useSelector(
    (state) => state.gamma.activeOrganization,
  );
  const { uiConfig, legalTerms } = useSelector((state) => state.local);

  // Section: Reference, it helps to get the data just once
  const ref = useRef({
    conf: INIT,
    usr: INIT,
    theme: INIT,
    org: INIT,
    allOrg: INIT,
    roles: INIT,
    orgConf: INIT,
    legals: INIT,
    legal: INIT,
    agencyRelation: INIT,
    agency: INIT,
  });

  // Section: State
  const [stateLegal, setStateLegal] = useState({});
  const [loadedConfigurations, setLoadedConfigurations] = useState(false);
  const [agencyId, setAgency] = useState(null);
  const [loadedLoggedUser, setLoadedLoggedUser] = useState(false);
  const [loadedCurrentTheme, setLoadedCurrentTheme] = useState(false);
  const [loadedCurrentLogo, setLoadedCurrentLogo] = useState(false);
  const [loadedOrganizations, setLoadedOrganizations] = useState(false);
  const [loadedRoles, setLoadedRoles] = useState(false);
  const [loadedLegal, setLoadedLegal] = useState(false);
  const [loadedLegals, setLoadedLegals] = useState(false);
  const [loadedAgencies, setLoadedAgencies] = useState(false);
  const [
    loadedOrganizationConfigurations,
    setLoadedOrganizationConfigurations,
  ] = useState(false);
  const [backError, setBackError] = useState({ error: false });
  const [agenciesToCounsult, setAgenciesToConsult] = useState(false);

  // Section: Effect
  useEffect(() => {
    if (uiConfig) {
      dispatch(resetLogos());
      if (uiConfig.profilePicture) {
        dispatch(uiProfilePictureUpdate(uiConfig.profilePicture));
      }
      if (uiConfig.logos) {
        dispatch(uiLogosUpdate(uiConfig.logos));
      }
    }
  }, [dispatch, uiConfig]);

  useEffect(() => {
    if (!loadedLoggedUser && ref.current.usr === INIT) {
      ref.current.usr = SETTLED;
      authService
        .getUser(userId)
        .then((res) => {
          if (isErrorResponse(res)) {
            handleLoadingError(res, t("loading.loggedUserError"));
          } else {
            const keycloakRoles = session.decodedToken.realm_access.roles.map(
              (rol, index) => {
                return {
                  id: index,
                  name: rol,
                  description: rol,
                };
              },
            );
            const responseMembership = Array.isArray(res?.memberships)
              ? res?.memberships[0]
              : undefined;
            responseMembership.roles = [{ ...keycloakRoles[0] }];
            const newLoggedUser = {
              ...res,
              memberships: [{ ...responseMembership, agencies: null }],
            };
            if (isValidMemberships(newLoggedUser?.memberships)) {
              const userOrganization =
                newLoggedUser.memberships[0].organization;
              dispatch(setActiveOrganization(userOrganization));
              dispatch(loggedUser(newLoggedUser));
              setLoadedLoggedUser(true);
            } else {
              navigate("/logout", { replace: true });
            }
          }
        })
        .catch((e) => {
          handleLoadingError(e, t("loading.loggedUserError"));
        });
    }
  }, [
    dispatch,
    loadedConfigurations,
    loadedLoggedUser,
    navigate,
    session.accesstoken,
    session.decodedToken.realm_access.roles,
    t,
    userId,
  ]);

  useEffect(() => {
    if (loadedLoggedUser && ref.current.conf === INIT) {
      ref.current.conf = SETTLED;

      getAllConfigurations(activeOrganization.id)
        .then((res) => {
          if (isErrorResponse(res)) {
            handleLoadingError(res, t("loading.configurationError"));
          } else {
            dispatch(appConfigLoader(res.content));
            setLoadedConfigurations(true);
          }
        })
        .catch((e) => {
          handleLoadingError(e, t("loading.configurationError"));
        });
    }
  }, [
    activeOrganization,
    currentUser,
    dispatch,
    loadedConfigurations,
    loadedLoggedUser,
    t,
  ]);

  useEffect(() => {
    const getOrgPackage = async () => {
      await getOrganizationPackage()
        .then((data) => {
          dispatch(sliceOrganizationPackage(data));
        })
        .catch((error) => {
          console.log(error);
        });
    };

    if (loadedLoggedUser) {
      getOrgPackage();
    }
  }, [dispatch, loadedLoggedUser]);

  useEffect(() => {
    if (loadedLoggedUser && !loadedCurrentTheme && ref.current.theme === INIT) {
      ref.current.theme = SETTLED;
      currentTheme(activeOrganization.id)
        .then((currentSelectedTheme) => {
          if (isErrorResponse(currentSelectedTheme)) {
            handleLoadingError(currentSelectedTheme, t("loading.themeError"));
          } else {
            const themeColors = {
              id: currentSelectedTheme.id,
              title: currentSelectedTheme.displayName,
              primary: {
                main: currentSelectedTheme.primary.normal,
                light: currentSelectedTheme.primary.light,
                dark: currentSelectedTheme.primary.dark,
              },
              secondary: {
                main: currentSelectedTheme.secondary.normal,
                light: currentSelectedTheme.secondary.light,
                dark: currentSelectedTheme.secondary.dark,
              },
              custom:
                (uiConfig?.custom
                  ? uiConfig.custom
                  : currentSelectedTheme.custom) || {},
            };
            dispatch(uiConfigUpdate(themeColors));
            setLoadedCurrentTheme(true);
          }
        })
        .catch((e) => {
          /* Ver si incluir esto o mandar error cuando no se pueden cargar los temas */
          /*
          dispatch(uiConfigUpdate(defaultThemeColors));
          */
          handleLoadingError(e, t("loading.themeError"));
        });
    }
  }, [
    activeOrganization,
    currentUser,
    dispatch,
    loadedCurrentTheme,
    loadedLoggedUser,
    t,
    uiConfig,
  ]);

  useEffect(() => {
    if (loadedLoggedUser && !loadedCurrentLogo && ref.current.org === INIT) {
      ref.current.org = SETTLED;

      currentLogo(activeOrganization.id)
        .then((currLogo) => {
          if (isErrorResponse(currLogo)) {
            handleLoadingError(currLogo, t("loading.logoError"));
          } else {
            dispatch(uiOrganizationLogo(currLogo));
            setLoadedCurrentLogo(true);
          }
        })
        .catch((e) => {
          handleLoadingError(e, t("loading.logoError"));
        });
    }
  }, [activeOrganization.id, dispatch, loadedCurrentLogo, loadedLoggedUser, t]);

  useEffect(() => {
    if (
      loadedLoggedUser &&
      !loadedOrganizations &&
      ref.current.allOrg === INIT
    ) {
      ref.current.allOrg = SETTLED;
      getAllOrganizations(activeOrganization.id)
        .then((res) => {
          if (isErrorResponse(res)) {
            handleLoadingError(res, t("loading.organizationsError"));
          } else {
            dispatch(allOrganizations(res.content));
            setLoadedOrganizations(true);
          }
        })
        .catch((e) => {
          handleLoadingError(e, t("loading.organizationsError"));
        });
    }
  }, [
    activeOrganization.id,
    dispatch,
    loadedLoggedUser,
    loadedOrganizations,
    t,
  ]);

  useEffect(() => {
    if (loadedLoggedUser && !loadedRoles && ref.current.roles === INIT) {
      ref.current.roles = SETTLED;
      getRoles(activeOrganization.id)
        .then((res) => {
          if (isErrorResponse(res)) {
            handleLoadingError(res, t("loading.rolesError"));
          } else {
            dispatch(allRolesUpdate(res));
            setLoadedRoles(true);
          }
        })
        .catch((e) => {
          handleLoadingError(e, t("loading.rolesError"));
        });
    }
  }, [activeOrganization.id, dispatch, loadedLoggedUser, loadedRoles, t]);

  useEffect(() => {
    if (
      loadedLoggedUser &&
      !loadedOrganizationConfigurations &&
      ref.current.orgConf === INIT
    ) {
      ref.current.orgConf = SETTLED;
      const userOrganization = currentUser?.memberships[0]?.organization;
      getOrganizationConfiguration(userOrganization?.id)
        .then((res) => {
          if (isErrorResponse(res)) {
            handleLoadingError(
              res,
              t("loading.organizationConfigurationError"),
            );
          } else {
            dispatch(organizationConfiguration(res));
            setLoadedOrganizationConfigurations(true);
          }
        })
        .catch((e) => {
          handleLoadingError(e, t("loading.organizationConfigurationError"));
        });
    }
  }, [
    currentUser?.memberships,
    dispatch,
    loadedLoggedUser,
    loadedOrganizationConfigurations,
    t,
  ]);

  useEffect(() => {
    if (loadedLoggedUser && !loadedLegals && ref.current.legals === INIT) {
      ref.current.legals = SETTLED;
      const userOrganization = currentUser?.memberships[0]?.organization;
      getLegals(userOrganization?.id)
        .then((currentLegalTerms) => {
          // TODO: validar que hacer si no hay current Terms
          setStateLegal(currentLegalTerms);
          setLoadedLegals(true);
        })
        .catch((e) => {
          handleLoadingError(e, t("loading.legalsError"));
        });
    }
  }, [currentUser?.memberships, loadedLegals, loadedLoggedUser, t]);

  useEffect(() => {
    if (
      loadedLoggedUser &&
      loadedLegals &&
      !loadedLegal &&
      ref.current.legal === INIT
    ) {
      ref.current.legal = SETTLED;
      const userOrganization = currentUser?.memberships[0]?.organization;
      if (stateLegal?.id) {
        getLegal(userId, userOrganization?.id, stateLegal.id)
          .then((resp) => {
            dispatch(legalTermsUpdate(resp));
            setLoadedLegal(true);
          })
          .catch((e) => {
            handleLoadingError(e, t("loading.legalError"));
          });
      } else {
        setLoadedLegal(true);
      }
    }
  }, [
    currentUser?.memberships,
    dispatch,
    loadedLegal,
    loadedLegals,
    loadedLoggedUser,
    stateLegal,
    t,
    userId,
  ]);

  useEffect(() => {
    if (
      loadedLoggedUser & !loadedAgencies &&
      ref.current.agencyRelation === INIT
    ) {
      ref.current.agencyRelation = SETTLED;
      getAgenciesRelationed(
        userId,
        currentUser?.memberships[0]?.organization?.id,
      )
        .then((response) => {
          if (response?.content) {
            //const ids = response?.content?.map(id => id * 1)
            dispatch(
              loggedUser({
                ...currentUser,
                memberships: [
                  {
                    ...currentUser.memberships[0],
                    agencies: response?.content,
                  },
                ],
              }),
            );
            setAgency(response.content);
          } else {
            dispatch(
              loggedUser({
                ...currentUser,
                memberships: [{ ...currentUser.memberships[0], agencies: [] }],
              }),
            );
          }
          setLoadedAgencies(true);
        })
        .catch((e) => {
          handleLoadingError(e, t("loading.agenciesError"));
        });
    }
  }, [currentUser, dispatch, loadedAgencies, loadedLoggedUser, t, userId]);

  useEffect(() => {
    if (agencyId && ref.current.agency === INIT) {
      ref.current.agency = SETTLED;
      getAgencieByIdAwait(agencyId[0])
        .then((data) => {
          dispatch(setSelectedAgencieToConsult(data));
          setAgenciesToConsult(true);
        })
        .catch((error) => console.log(error));
    }
  }, [agencyId, dispatch]);

  const allConfigurationsLoaded = useCallback(() => {
    return (
      loadedConfigurations &&
      loadedLoggedUser &&
      loadedCurrentTheme &&
      loadedCurrentLogo &&
      loadedOrganizations &&
      loadedRoles &&
      loadedLegal &&
      loadedLegals &&
      loadedAgencies &&
      loadedOrganizationConfigurations &&
      agenciesToCounsult
    );
  }, [
    agenciesToCounsult,
    loadedAgencies,
    loadedConfigurations,
    loadedCurrentLogo,
    loadedCurrentTheme,
    loadedLegal,
    loadedLegals,
    loadedLoggedUser,
    loadedOrganizationConfigurations,
    loadedOrganizations,
    loadedRoles,
  ]);

  useEffect(() => {
    const confLoaded = allConfigurationsLoaded();
    if (confLoaded) {
      // Admin validation
      const isArray = Array.isArray(currentUser?.memberships);
      const isAdminLottery = roleBelongsToUser(
        currentUser?.memberships[0]?.roles,
        roles.administradorLoteria,
      );

      // Agent validation
      const agentRoles = [roles.agenciero, roles.concentradora, roles.empleado];
      const isAgent = roleBelongsToUser(
        currentUser?.memberships[0]?.roles,
        agentRoles,
      );

      if (isArray && isAdminLottery) {
        /* ES ADMINISTADOR DE LOTERIA */
        navigate(`../admin/users`);
      } else if (isAgent) {
        /* ES AGENCIERO */

        if (stateLegal?.id) {
          legalTerms.accepted
            ? navigate(`../main/agencias`)
            : navigate("../terms");
        } else {
          /* NO HAY TERMINOS INGRESA DIRECTO */
          navigate(`../main/agencias`);
        }
      } else {
        navigate("/logout");
      }
    }
  }, [
    allConfigurationsLoaded,
    currentUser?.memberships,
    legalTerms.accepted,
    navigate,
    stateLegal?.id,
  ]);

  // Section: Functions
  function isErrorResponse(res) {
    return res?.status && !(res?.status >= 200 && res?.status < 300);
  }

  function handleLoadingError(e, message) {
    setBackError({
      error: true,
      code: message || e?.status || 500,
    });
  }

  function isValidMemberships(memberships) {
    return (
      Array.isArray(memberships) &&
      memberships.length > 0 &&
      memberships[0].organization &&
      memberships[0].organization.id &&
      memberships[0].organization.name &&
      Array.isArray(memberships[0].roles) &&
      memberships[0].roles.length > 0
    );
  }

  return backError?.error ? (
    <ComponentError code={backError?.code} />
  ) : (
    <div style={styles}>
      <CircularProgress size={"2rem"} />
      <h4 style={stylesH4}>{t("validateData")}</h4>
    </div>
  );
};

// FIX: AUX FUNCTIONS. They must go in their own file

/**
 * Checks if a specific role or any of a list of roles belongs to a user.
 *
 * @param {object[]} userRoles - A list of user role objects.
 * @param {string|string[]} roles - The role or roles to check against the user's roles.
 * @returns {boolean} - True if the user has the specified role or any of the roles, otherwise false.
 */
export function roleBelongsToUser(userRoles, roles) {
  if (!Array.isArray(userRoles) || !userRoles.length) {
    return false;
  }

  const roleSet = new Set(Array.isArray(roles) ? roles : [roles]);
  return userRoles.some((userRole) => roleSet.has(userRole.name));
}
