import apiClient from "../../utils/api.client";
import { getSaaId } from "../../utils/session.utils";

export const getCashControl = async (organizationId, fecha, agencia, subagencia) => {
  try {
    const saaId = getSaaId()
    const response = await apiClient.get(`/saaServices/saaServices/services/abaco/controlesCaja?organizacionId=${saaId}&fecha=${fecha}&agencia=${agencia}&subagencia=${subagencia}`);
    const data = response.data;

    return data;
  } catch (error) {
    console.error('Error at getCashControl EP: ', error)
    throw error
  }
}
