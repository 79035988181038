import apiClient from "../../utils/api.client";
import { getOrganizationId } from "../../utils/session.utils";

export const getloggedUser = async (userId) => {
  try {
    const response = await apiClient.get(`/gamma-users/users/${userId}`);
    const data = response.data;
    return data;
  } catch (error) {
    console.log(error);
  }
  // return fetch(`/gamma-users/users/${userId}`)
};

export const getGameOffers = async (
  organizationId,
  enabled,
  pageNumber,
  pageSize,
) => {
  try {
    const response = await apiClient.get(
      `/game-service/organizations/${organizationId}/gameOffers?pageNumber=${pageNumber || 1}&pageSize=${pageSize || 2}0&orderCriteria=ASC&orderField=id&enabled=${enabled === null || enabled === undefined ? enabled : true}`,
    );

    const data = response.data;

    return data;
  } catch (error) {
    console.log(error);
  }
  // return fetch(`/gamma-organizations/organizations/${organizationId}/gameOffers?pageNumber=${pageNumber || 1}&pageSize=${pageSize || 2}0&orderCriteria=ASC&orderField=id&enabled=${enabled === null || enabled === undefined ? enabled : true}`)
  //   .then((response) => response.json())
  //   .then((json) => {
  //     return json
  //   })
  //   .catch((err) => console.log(err));
};

export const getOrganizationAgencies = async (
  organizationId = getOrganizationId(),
  pageNumber,
  perPage,
  sort,
  filter,
) => {
  try {
    const response = await apiClient.get(
      `/gamma-agencies/organizations/${organizationId || 9}/agencies?pageNumber=${pageNumber || 1}&pageSize=${perPage || 10}&orderCriteria=${sort?.criteria || "ASC"}&orderField=${sort?.field || "name"}${filter?.status ? (filter.status !== "ALL" ? `&status=${filter?.status}` : "") : ""}${filter?.type ? (filter.type !== "ALL" ? `&agencyKind=${filter?.type}` : "") : ""}`,
    );

    const data = response.data;

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }

  // return fetch(`/gamma-agencies/organizations/${organizationId || 9}/agencies?pageNumber=${pageNumber || 1}&pageSize=${perPage || 10}&orderCriteria=${sort?.criteria || 'ASC'}&orderField=${sort?.field || 'name'}${filter?.status ? filter.status !== 'ALL' ? `&status=${filter?.status}` : "" : ""}${filter?.type ? filter.type !== 'ALL' ? `&agencyKind=${filter?.type}` : "" : ""}`, {
  //   method: "GET",
  //   headers: { 'Content-Type': 'application/json' }
  // })
  //   .then((response) => {
  //     if (response.status !== 200) {
  //       throw response.status;
  //     } else {
  //       return response;
  //     }
  //   })
  //   .catch((err) => { throw err });
};

export const getRoles = async () => {
  try {
    const response = await apiClient.get(
      `/gamma-users/roles?pageNumber=1&pageSize=100&orderCriteria=ASC&orderField=name`,
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.log(error);
  }

  // return fetch(`/gamma-users/roles?pageNumber=1&pageSize=100&orderCriteria=ASC&orderField=name`)
  //   .then((response) => response.json())
  //   .then((json) => {
  //     return json
  //   })
  //   .catch((err) => console.log(err));
};

// NOTE: Add organizationID
export const getSalePoints = async (organizationId = getOrganizationId()) => {
  try {
    const response = await apiClient.get(
      `/gamma-agencies/agencies?pageNumber=1&pageSize=20&orderCriteria=ASC&orderField=name&status=ENABLED&agencyKind=SALE_POINT&organizationId=${organizationId}`,
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.log(error);
  }

  // return fetch(`/gamma-agencies/agencies?pageNumber=1&pageSize=20&orderCriteria=ASC&orderField=name&status=ENABLED&agencyKind=SALE_POINT`, {
  //   method: "GET",
  //   headers: { 'Content-Type': 'application/json' }
  // })
  //   .then((response) => response.json())
  //   .then((json) => {
  //     return json
  //   })
  //   .catch((err) => console.log(err));
};

// FIX: This shouldn't been used, should be delete
export const getAllOrganizations = (organizationId) => {
  return fetch(
    `/${organizationId}/gamma-all-organizations/organizations?pageNumber=1&pageSize=20&orderCriteria=ASC&orderField=name`,
  )
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => console.log(err));
};

// NOTE: Add organizationID, assess this with MASSA -> RESPONSE: NO
export const getAccountingCodeKinds = async () => {
  try {
    const response = await apiClient.get(
      `/gamma-accounting/accountingCodeKinds?pageNumber=1&pageSize=100&orderCriteria=ASC&orderField=name`,
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.log(error);
  }

  // return fetch(`/gamma-accounting/accountingCodeKinds?pageNumber=1&pageSize=100&orderCriteria=ASC&orderField=name`)
  //   .then((response) => response.json())
  //   .then((json) => {
  //     return json
  //   })
  //   .catch((err) => console.log(err));
};
