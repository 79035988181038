
import axios from "axios"
// imoprt interceptors
import {
  axiosRequestInterceptor,
  axiosResponseInterceptor,
} from "./api.client.interceptor"

const apiClient = axios.create({
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
})

// Intercepta las consultas que se le hacen a la api

apiClient.interceptors.request.use(axiosRequestInterceptor)

apiClient.interceptors.response.use(
  (response) => response,
  axiosResponseInterceptor,
)

export default apiClient
