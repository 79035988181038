import apiClient from "../../utils/api.client";

export const getAgencieReport = async (agencie, date, subagency) => {
    try {
        const response = await apiClient.get(`/gamma-movements/settlements/agencies/${agencie?.id}/report?settlementDate=${date}&pageSize=10000000&movementSize=10000000${subagency && agencie?.id !== subagency?.id ? `&agencyIds=${subagency?.id}` : ''}`);
        const data = response.data;

        return data;
    } catch (error) {
        console.error('Error at getAgencieReport EP: ', error)
        if (error instanceof Error) {
            throw error
        } else {
            throw new Error('Error at getAgencieReport EP: ', error)
        }
    }
}
