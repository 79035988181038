// import react
import { useEffect, useState } from "react";
// import react router
import { Navigate, useNavigate } from "react-router-dom";
// import local components
import { useAuth } from "../components/Auth/AuthProvider";
// import jwt
import { decodeToken, useJwt } from "react-jwt";
// import api
import authService from "../services/auth.service";
// import redux
import { useDispatch } from "react-redux";
import { isSessionExpired } from "../utils/session.utils";
// ======================== | PRIVATE ROUTE | =================================

/* It verifies whether the user can access to the protected route or not
 * @param { Object } children - The private content that needs to be protected
 * */
const PrivateRoute = ({ children }) => {
  // Section: Hook navigation
  const navigate = useNavigate();
  // Section: Auth
  const { session, setSession } = useAuth()
  // Section: JWT
  const { decodedToken } = useJwt(session.accessToken)
  // Section: State
  const [isLogged, setIsLogged] = useState(!session.accessToken ? false : true)
  // Section: Redux
  const dispatch = useDispatch()

  // Section: Effect
  useEffect(() => {
    console.log('Checking private routes')
    if (session) {
      if (session.accessToken !== null) {
        if (decodedToken) {
          const isExpired = isSessionExpired(decodedToken.exp)
          if (isExpired) {
            setIsLogged(false)
            navigate('/logout', { replace: true })
          }
        }
      } else {
        navigate('/logout', { replace: true })
      }
    }
  }, [decodedToken, navigate, session, session.accessToken])

  // Section: Return
  return !isLogged ? <Navigate to={'/'} replace={true} /> : children
};

export default PrivateRoute;
