import "./index.css";
import React from 'react'
import { currentVersion } from "../../../helpers/version"
import { useTranslation } from "react-i18next";

export const AboutVersion = () => {
  const { t } = useTranslation();

  return (
    <div className="container">
      <div className="indicator">
        <h1>Abaco</h1>
        <h3>{t('version')}: {currentVersion}</h3>
      </div>
    </div>
  );
};
