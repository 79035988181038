// import react
import { useEffect } from 'react'
// import react-router
import { Navigate } from "react-router-dom";
// import redux
import { useDispatch } from "react-redux";
// import sices
import { resetGammaSlice, resetLoggedUser } from "../../store/slices/gammaSlice";
import { resetLocalSlice } from "../../store/slices/localSlice";
import { resetAgenciesSlice } from "../../store/slices/my_agencies";
import { resetPaginationSlice } from "../../store/slices/paginationSlice";
import { resetErrors } from "../../store/slices/errors"
import { resetPointsSlice } from "../../store/slices/pointsSlice";
import { resetUserSlice } from "../../store/slices/userSlice";
import { deleteSession } from "../../store/slices/session";
//import services
import authService from "../../services/auth.service";
//import components
import { useAuth } from "../../components/Auth/AuthProvider";
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';

const styles = {
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
}

export const LogOut = () => {
    // Section: Auth Hook
    const { session } = useAuth()
    const navigate = useNavigate()

    // Section: Despatch Hook
    const dispatch = useDispatch();

    // Section: Effect
    useEffect(() => {
        async function logOut() {
            try {
                await authService.logOut().then(() => {
                    dispatch(resetLocalSlice())
                    dispatch(resetAgenciesSlice())
                    dispatch(resetPaginationSlice())
                    dispatch(resetErrors())
                    dispatch(resetPointsSlice())
                    dispatch(resetUserSlice())
                    dispatch(deleteSession())
                    dispatch(resetGammaSlice())
                    dispatch(resetLoggedUser())
                    navigate('/', { replace: true })
                })
            } catch (error) {
                console.log(error)
            }
        }

        logOut()
    }, [dispatch, navigate, session])


    return (
        <div style={styles}>
            <CircularProgress size={'3rem'} />
        </div>
    )
}
