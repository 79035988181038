import "./index.css";
import React from 'react'

export const Documentos = () => {

  return (
    <div className="container">
      <div className="indicator">
        <h1>Mis Documentos</h1>
      </div>
    </div>
  );
};
