import { Grid } from '@mui/material';
import React from 'react'
import s from './addConfigure.module.css';
import TextField from "@mui/material/TextField";
import { useState } from "react";
import Button from '@mui/material/Button';
import { createConfigItem } from "../../../api/config/ConfigService";
import Swal from "sweetalert2";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom"
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";

export const CreateConfigure = () => {

  const [configItems, setConfigItems] = useState({});
  const navigate = useNavigate();
  const configItem = {}
  const { t } = useTranslation();
  const handleConfigText = (e) => {
    if (e.target.name === 'key') {
      configItem.key = e.target.value;
    } else if (e.target.name === 'value') {
      configItem.value = e.target.value;
    }
  }
  const handleBack = () => {
    navigate(-1);
  }
  const handleSaveConfigItem = () => {
    createConfigItem(configItem)
      .then(() => {
        Swal.fire(t("Saved"), t("configurationCreatedSuccessfully"), "success");
      }).catch(() => {
        Swal.fire({
          icon: "error",
          title: "anErrorOccurred,TryAgain",
          showConfirmButton: false,
          timer: 1500
        })
      });
  }

  return (
    <div className={s.container}>
      <Grid container className={s.titleContainer}>
        <IconButton onClick={handleBack}  >
          <ArrowBackIcon color={"primary"} sx={{
            fontSize: '1.8rem',
            cursor: "pointer",
          }} />
        </IconButton>
        <h1 className={s.title}>{t("addConfiguration")}</h1>
      </Grid>

      <Grid container justifyContent="center" >
        <Grid container item spacing={3} xs={8}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item xs={12} >
            <TextField
              id="outlined-basic"
              label={t("key")}
              name="key"
              required
              rows={1}
              variant="outlined"
              sx={{ marginTop: 3, width: "100%", backgroundColor: "white", boxShadow: '0px 0px 15px -3px rgba(0, 0, 0, 0.18)' }}
              onChange={handleConfigText} />
          </Grid>
          <Grid container item xs={12} >
            <TextField
              sx={{ width: "100%", backgroundColor: "white", boxShadow: '0px 0px 15px -3px rgba(0, 0, 0, 0.18)' }}
              id="outlined-multiline-static"
              label={t("value")}
              name="value"
              required
              variant="outlined"
              onChange={handleConfigText}
            />
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <Button variant="outlined" onClick={handleSaveConfigItem} sx={{ width: "50%", marginTop: 4, transform: "scale(1.2)" }}>
              {t("saveSettings")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
