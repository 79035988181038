import store from "../store"
import apiClient from "../utils/api.client"
import { GATEWAY_URL } from "../constants/constants.urls"
import storage from "redux-persist/lib/storage"

/**
 * @typedef {Object} Session
 * @property {string} accessToken - The access token used for authenticated requests.
 * @property {number} expiresIn - The time in seconds until the access token expires.
 * @property {number} refreshExpiresIn - The time in seconds until the refresh token expires.
 * @property {string} refreshToken - The refresh token used to obtain a new access token.
 * @property {string} tokenType - The type of token (typically "Bearer").
 * @property {string} sessionState - The current session state identifier.
 * @property {string} scope - The scope of the access token.
 */

/**
 * Signin function to perform the signin action
 *
 * @param {string} username - a valid username required to perform the signin
 * @param {string} password - a valid password required to perform the signin
 * @returns {Promise<Session>} - A promise that resolves to an object containing session details.
 */
const signIn = async (username, password) => {
  const url = `${GATEWAY_URL}/auth/login`

  try {
    const res = await fetch(
      url,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: username,
          password: password
        }),
      }
    )

    const data = await res.json()

    // WARNING: Currently when the user is invalid the EP returns a 200 status code
    // in the response, the is wrong, it should be a error code (400-499)
    if (data.accessToken === null) {
      throw new Error('Credencial Inválida')
    }

    return data
  } catch (error) {
    console.error("users.service ---> login user")
    throw new Error(error)
  }

}

/**
 * @typedef {Object} Role
 * @property {string} id - The unique identifier for the role.
 * @property {string} name - The name of the role.
 * @property {string} description - A description of the role.
 */

/**
 * @typedef {Object} Organization
 * @property {number} id - The unique identifier for the organization.
 * @property {string} name - The name of the organization.
 */

/**
 *
 * @typedef {Object} Membership
 * @property {number} id - The unique identifier for the membership.
 * @property {boolean} selected - Indicates if this membership is selected.
 * @property {string} userId - The unique identifier for the user associated with this membership.
 * @property {Organization} organization - The organization associated with this membership.
 * @property {Role[]} roles - An array of roles associated with this membership.
 */

/**
 * @typedef {Object} User
 * @property {string} id - The unique identifier for the user.
 * @property {string} email - The user's email address.
 * @property {string} username - The user's username.
 * @property {string} firstname - The user's first name.
 * @property {string} lastname - The user's last name.
 * @property {boolean} enabled - Indicates if the user is enabled.
 * @property {Membership[]} memberships - An array of memberships the user holds.
 */
/**
 * @typedef {Object} User
 * @property {string} id - user id
 * @property {string} email  - user email
 */

/**
 * It gets the user data
 *
 * @param {string} userId - the user id gotten from the token decodification
 * @returns {Promise<User>}
 * */
const getUser = async (userId) => {
  const accessToken = store.getState().session.accessToken // Accessign the session storage
  try {
    const url = `${GATEWAY_URL}/users/${userId}`
    const response = await fetch(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${accessToken}`,
      },
    })
    const data = await response.json()

    return data
  } catch (error) {
    throw new Error(error.response.data.title)
  }
}

/**
 * This delete the user session
*/
const logOut = async () => {
  // Section: getting storage
  const accessToken = store.getState().session.accessToken // Accessign the session storage
  const refreshToken = store.getState().session.refreshToken

  console.log('Log from the logOut function: ', accessToken)
  const url = `${GATEWAY_URL}/auth/logout`

  try {
    await fetch(
      url,
      {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          refreshToken: refreshToken
        }),
      }
    )
  } catch (error) {
    console.error('Error at logOut EP: ', error)
    throw error
  }
}


/**
 * Refresh function to perform the refresh token
 *
 * @returns {Promise<Session>} - A promise that resolves to an object containing session details.
 */
const refreshToken = async () => {
  const accessToken = store.getState().session.accessToken // Accessign the session storage
  const refreshToken = store.getState().session.refreshToken

  const url = `${GATEWAY_URL}/auth/token/refresh`

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        refreshToken: refreshToken
      })
    })

    const data = await response.json()

    return data
  } catch (error) {
    console.log(error)
  }
}

const authService = {
  signIn,
  getUser,
  logOut,
  refreshToken
}

export default authService
