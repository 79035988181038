// import react
import { useState, useEffect } from "react";
// import styles
import "./index.css";
// import material UI
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import FilledInput from "@mui/material/FilledInput";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
// import api
import authService from "../../../services/auth.service";
// import react-router
import { useNavigate } from "react-router-dom";
// import redux
import { useDispatch } from "react-redux";
import { updateSession } from "../../../store/slices/session";
import abacoLogoWhite from '../../../../src/assets/abaco-logo-b.png'
import { useAuth } from "../../Auth/AuthProvider";
import { decodeToken, useJwt } from "react-jwt";
import { isSessionExpired } from "../../../utils/session.utils";
import { resetAgenciesSlice } from "../../../store/slices/my_agencies";
import { logoBaseOnOrgId } from "../../../api/themes/ThemeService";

const organizationURL = {
  default: -1
}

function getOrgIdFromHostName() {
  const currentUrl = window.location.hostname;

  const orgId = organizationURL[currentUrl]

  if (!orgId) {
    return organizationURL.default
  }

  return orgId
}

// ============================= | LOGIN | =====================================
export function Login() {
  // Section: State
  const [showPassword, setShowPassword] = useState(false);
  // Section: Redux
  const dispatch = useDispatch()
  // Section: Navigation
  const navigate = useNavigate()
  // session
  const { session } = useAuth()

  const [logError, setLogError] = useState({ error: false, message: '  ' })

  const [logo, setLogo] = useState(abacoLogoWhite)

  useEffect(() => {
    process.env.REACT_APP_URL_ORGANIZATIONS.split(';')
      .forEach((data) => {
        const organizationData = data.split(',')
        organizationURL[organizationData[0]] = organizationData[1]
      })

    const getLogo = async (organizationId) => {
      try {
        if (organizationId === -1) {
          setLogo(abacoLogoWhite)
        } else {
          const response = await logoBaseOnOrgId(organizationId)
          setLogo(response.imgBase64)
        }
      } catch (error) {
        console.log(error)
      }
    }

    const organizationId = getOrgIdFromHostName()
    getLogo(organizationId)
  }, [])


  // Section: handlers errors

  // NOTE: This is for clean the agencies redux state
  dispatch(resetAgenciesSlice())

  const { decodedToken } = useJwt(session.accessToken)

  // Section: Session validation
  useEffect(() => {
    if (session) {
      if (session.accessToken !== null) {
        if (decodedToken) {
          const isExpired = isSessionExpired(decodedToken.exp)
          if (!isExpired) {
            navigate('/check-user')
          }
        }
      }
    }
  }, [decodedToken, navigate, session])

  // Section: Handler
  const handleSubmit = async (event) => {
    setLogError({ error: false, message: '' })
    event.preventDefault()

    const form = event.target
    const formData = new FormData(form)

    const userData = {
      username: formData.get("username"),
      password: formData.get("password"),
    }

    try {
      const response = await authService
        .signIn(userData.username, userData.password)
      const decodedToken = decodeToken(response.accessToken)
      response.decodedToken = decodedToken

      dispatch(updateSession(response))

    } catch (error) {
      const errorMessage = error.message.split(':')
      setLogError({ error: true, message: errorMessage[1] })
    }
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // Section: JSX
  return (
    <div className="login--container">
      <div className="logo--container">
        <img
          alt="logo"
          className="logo--img"
          src={logo}
        />
      </div>
      <div className="form--container">
        <Box
          className="form--item"
          component="form"
          method=""
          onSubmit={handleSubmit}
        >
          <p className={`${logError.error ? 'show--message' : 'hidde--message'}`} >
            {logError.message}
          </p>
          <div className="input--form input--style">
            <TextField
              variant='filled'
              color='info'
              id='filled-required'
              label='Usuario'
              name='username'
              required={true}
              fullWidth
            />
          </div>
          <div className='input--style'>
            <FormControl variant='filled' >
              <InputLabel htmlFor="filled-adornment-password">Contraseña</InputLabel>
              <FilledInput
                id="filled-adornment-password"
                type={showPassword ? 'text' : 'password'}
                name='password'
                color='info'
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label='Contraseña'
              />
            </FormControl>
          </div>
          <div className="form--button">
            <Button variant="contained" type="submit" color='info'>
              Ingresar
            </Button>
          </div>
        </Box>
      </div>
    </div>
  )
}
