// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.Modal_boxModal__h2-Gy{
   animation: Modal_init__9kNh8 0.3s;
   transition: 1s;
   display: flex;
   justify-content: center;
   align-items: center;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   min-height: 15%;
   max-height: 30%;
   border-radius: 5px;
   box-shadow: 0 5px 50px -3px rgba(0, 0, 0, 0.511);
}

.Modal_boxModal__h2-Gy p{
   animation: Modal_init2__KDmAi 0.5s both;
   margin-left: 15px;
   font-size: 1.2rem;
   font-weight: 300;
}

@media screen and (max-width: 900px){
   .Modal_boxModal__h2-Gy{
       background-color: rgba(238, 238, 238, 0.95);
       transition: 0.3s;
       min-height: 100%;
       border-radius: 0;
       box-shadow: none;
   }
}

@keyframes Modal_init__9kNh8{
   0%{
       top: 55%;
       opacity: 0;
   }
   100%{
       top: 50%;
       opacity: 1;
   }
}

@keyframes Modal_init2__KDmAi{
   0%{
       transform: translateX(30px);
       opacity: 0;
   }
   100%{
       transform: translateX(0);
       opacity: 1;
   }
}

@keyframes Modal_init3__2kRfd{
   0%{
       transform: translateX(-30px);
       opacity: 0;
   }
   100%{
       transform: translateX(0);
       opacity: 1;
   }
}`, "",{"version":3,"sources":["webpack://./src/components/Pages/Agencias/Modal/Modal.module.css"],"names":[],"mappings":";AACA;GACG,iCAAoB;GACpB,cAAc;GACd,aAAa;GACb,uBAAuB;GACvB,mBAAmB;GACnB,kBAAkB;GAClB,QAAQ;GACR,SAAS;GACT,gCAAgC;GAChC,eAAe;GACf,eAAe;GACf,kBAAkB;GAClB,gDAAgD;AACnD;;AAEA;GACG,uCAA0B;GAC1B,iBAAiB;GACjB,iBAAiB;GACjB,gBAAgB;AACnB;;AAEA;GACG;OACI,2CAA2C;OAC3C,gBAAgB;OAChB,gBAAgB;OAChB,gBAAgB;OAChB,gBAAgB;GACpB;AACH;;AAEA;GACG;OACI,QAAQ;OACR,UAAU;GACd;GACA;OACI,QAAQ;OACR,UAAU;GACd;AACH;;AAEA;GACG;OACI,2BAA2B;OAC3B,UAAU;GACd;GACA;OACI,wBAAwB;OACxB,UAAU;GACd;AACH;;AAEA;GACG;OACI,4BAA4B;OAC5B,UAAU;GACd;GACA;OACI,wBAAwB;OACxB,UAAU;GACd;AACH","sourcesContent":["\n.boxModal{\n   animation: init 0.3s;\n   transition: 1s;\n   display: flex;\n   justify-content: center;\n   align-items: center;\n   position: absolute;\n   top: 50%;\n   left: 50%;\n   transform: translate(-50%, -50%);\n   min-height: 15%;\n   max-height: 30%;\n   border-radius: 5px;\n   box-shadow: 0 5px 50px -3px rgba(0, 0, 0, 0.511);\n}\n\n.boxModal p{\n   animation: init2 0.5s both;\n   margin-left: 15px;\n   font-size: 1.2rem;\n   font-weight: 300;\n}\n\n@media screen and (max-width: 900px){\n   .boxModal{\n       background-color: rgba(238, 238, 238, 0.95);\n       transition: 0.3s;\n       min-height: 100%;\n       border-radius: 0;\n       box-shadow: none;\n   }\n}\n\n@keyframes init{\n   0%{\n       top: 55%;\n       opacity: 0;\n   }\n   100%{\n       top: 50%;\n       opacity: 1;\n   }\n}\n\n@keyframes init2{\n   0%{\n       transform: translateX(30px);\n       opacity: 0;\n   }\n   100%{\n       transform: translateX(0);\n       opacity: 1;\n   }\n}\n\n@keyframes init3{\n   0%{\n       transform: translateX(-30px);\n       opacity: 0;\n   }\n   100%{\n       transform: translateX(0);\n       opacity: 1;\n   }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"boxModal": `Modal_boxModal__h2-Gy`,
	"init": `Modal_init__9kNh8`,
	"init2": `Modal_init2__KDmAi`,
	"init3": `Modal_init3__2kRfd`
};
export default ___CSS_LOADER_EXPORT___;
