import s from "./index.module.css";
import React from 'react'
import TextField from "@mui/material/TextField";
import { Grid, Box } from "@mui/material/";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";

import { useSelector, useDispatch } from "react-redux";
import { termsAndConditionsUpdate } from "../../../../store/slices/localSlice";
import { Term_and_ConditionTotalElementsAdd } from "../../../../store/slices/paginationSlice";
import Swal from "sweetalert2";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ArticleIcon from "@mui/icons-material/Article";
import { useTranslation } from "react-i18next";
import { createTermsAndCondition } from "../../../../api/terms/TermsService";

import "dayjs/locale/es";
import "dayjs/locale/pt";
import { today } from "../../../../helpers/today";

export const AddTerms = () => {
  const min = 0;
  const { terms_And_Conditions } = useSelector((state) => state.local);
  const [valueFrom, setValueFrom] = useState(null);
  const currentUser = useSelector((state) => state.gamma.logged_user);
  const [valueTo, setValueTo] = useState(null);
  const { language } = useSelector((state) => state.local);
  const dispatch = useDispatch();
  const [primaryColor, setPrimaryColor] = useState("");
  const { uiConfig } = useSelector((state) => state.local);
  const { textColor } = useSelector((state) => state.local);
  const { t } = useTranslation();
  const userOrganization = currentUser?.memberships[0]?.organization;
  const [terms, setTerms] = useState({
    title: "",
    textTc: "",
    creationDate: new Date(),
    validFrom: "",
    validTo: "",
    published: false,
    omitAmount: 0,
    organizationId: userOrganization.id,
  });
  let navigate = useNavigate();

  useEffect(() => {
    setPrimaryColor(uiConfig.primary.main);
  }, [uiConfig]);

  const handleSave = () => {
    Swal.fire({
      title: t("areYouSureCreateTerms"),
      text: "",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "#d33",
      confirmButtonText: t("accept"),
      cancelButtonText: t("cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        createTermsAndCondition(terms)
          .then((response) => {
            Term_and_ConditionTotalElementsAdd();
            console.log("data", response);
            let newTermList = [...terms_And_Conditions, response];
            dispatch(termsAndConditionsUpdate(newTermList));
            Swal.fire({
              icon: "success",
              title: t("changesSavedSuccessfully"),
              showConfirmButton: false,
              timer: 1500,
            });
          }).catch((error) => {
            console.log(error)
          });
        handleBack();
      }
    });
  };
  const handleLegalText = (e) => {
    setTerms({
      ...terms,
      [e.target.name]: e.target.value,
    });
  };

  const handleOmit = (e) => {
    e.target.value = parseInt(e.target.value, 10);
    if (e.target.value < min) e.target.value = min;
    setTerms({
      ...terms,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeDateTo = (day) => {
    let update = terms;
    update.validTo = day;
    setTerms(update);
    setValueTo(day);
  };

  const handleChangeDateFrom = (day) => {
    let update = terms;
    update.validFrom = day;
    setTerms(update);
    setValueFrom(day);
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className={s.container}>
        <div className={s.titleContainer}>
          <IconButton onClick={handleBack}>
            <ArrowBackIcon
              color={"primary"}
              sx={{
                fontSize: "1.8rem",
                cursor: "pointer",
              }}
            />
          </IconButton>
          <div className={s.title}>
            <ArticleIcon
              color={"primary"}
              sx={{
                fontSize: "1.8rem",
                color: primaryColor,
              }}
            />
            <h1 className={s.title}>{t("terms")}</h1>
          </div>
        </div>
        <Grid container justifyContent="center">
          <Grid
            container
            item
            spacing={3}
            xs={7}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                label={t("title")}
                name="title"
                required
                rows={1}
                variant="outlined"
                sx={{
                  marginTop: 3,
                  width: "100%",
                  backgroundColor: "white",
                  boxShadow: "0px 0px 15px -3px rgba(0, 0, 0, 0.18)",
                }}
                onChange={handleLegalText}
              />
            </Grid>
            <Grid container item xs={12}>
              <TextField
                sx={{
                  width: "100%",
                  backgroundColor: "white",
                  boxShadow: "0px 0px 15px -3px rgba(0, 0, 0, 0.18)",
                }}
                id="outlined-multiline-static"
                label={t("terms")}
                name="textTc"
                required
                multiline
                rows={14}
                variant="outlined"
                onChange={handleLegalText}
              />
            </Grid>
            <Grid
              item
              container
              xs={12}
              justifyContent="space-between"
              alignItems="center"
              rowSpacing={2}
            >
              <Grid item xs={3.5}>
                <TextField
                  type="number"
                  name="omitAmount"
                  label={t("omitAmount")}
                  inputProps={{ min }}
                  onChange={handleOmit}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  className={s.superdate}
                  adapterLocale={language}
                >
                  <DatePicker
                    label={t("effectiveDate") + " *"}
                    inputFormat="DD/MM/YYYY"
                    minDate={today()}
                    value={valueFrom}
                    name="date"
                    onChange={handleChangeDateFrom}
                    renderInput={(params) => <TextField {...params} />}
                    className={s.date}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item sx={3}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  className={s.superdate}
                  adapterLocale={language}
                >
                  <DatePicker
                    label={t("endOfEffectiveDate") + " *"}
                    inputFormat="DD/MM/YYYY"
                    value={valueTo}
                    minDate={valueFrom}
                    name="date"
                    onChange={handleChangeDateTo}
                    renderInput={(params) => <TextField {...params} />}
                    className={s.date}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sx={{ mb: 3 }}>
                <Button
                  onClick={handleSave}
                  className={s.btn}
                  disabled={
                    !(
                      terms.title &&
                      terms.textTc &&
                      terms.validFrom &&
                      terms.validTo
                    )
                  }
                  sx={{ width: "100%", height: "100%", borderRadius: 2 }}
                  color="primary"
                  variant="contained"
                >
                  {t("save")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
