import styles from "./index.module.css";
import React from 'react'
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
//import { user, user2, user3, user4, user5, user6} from "./assets/columns.js"
//import { Autocomplete } from '@mui/material';
import Collapse from "./Collapse/Collapse.jsx";
//import Loader from "../../Shared/Loader/index.jsx"
import { callAgeniesById } from "../../../store/slices/my_agencies.js";
import { allUsersOfTheLoginInUser } from "../../../store/slices/gammaSlice";
import { Grid } from "@mui/material";
import { getImputation } from "../../../api/accounting/imputation";
import { imputationUpdate } from "../../../store/slices/gammaSlice";
import StoreIcon from "@mui/icons-material/Store";
import HandleRequests from "../../Shared/handleRequests/HandleRequests";
import { getAllUsersFromAgencies } from "../../../api/agencies/AgenciesService";
import { alreadyAnalisis } from "../../../store/slices/my_agencies.js";

import { t } from "i18next";
import UsersModal from "./Modal";
import { updateLocation } from "../../../store/slices/localSlice";

export const Agencias = () => {
  const dispatch = useDispatch();

  // const [ favsAgencies, setFavsAgencies ] = useState([])
  const [agenciesToMap, setAgenciesToMap] = useState(null);
  // const [ errorPage , setErrorPage ] = useState(false)
  const movements = useSelector((state) => state?.myAgencies?.my_movements);
  const colors = useSelector((state) => state.local.uiConfig);
  const { logged_user } = useSelector((state) => state?.gamma);
  const agencies = useSelector((state) => state?.myAgencies?.user_agencies);
  //const { agencies_favs } = useSelector((state) => state?.myAgencies)
  const { my_agenciesError } = useSelector((state) => state.errors);
  const analisisUsers = useSelector((state) => state.myAgencies?.analisisUsers);
  const total_movement = useSelector(
    (state) => state?.myAgencies?.total_movement
  );
  const agencySelected = useSelector(
    (state) => state.myAgencies?.selected_agencie_to_consult
  );

  const [open, setOpen] = useState(true);

  useEffect(() => {
    dispatch(updateLocation({ agencias: true }));
  }, []);

  useEffect(() => {
    const ids = logged_user?.memberships[0].agencies?.map((agencie) => (agencie));
    dispatch(callAgeniesById(ids, agencySelected));
  }, [agencySelected, dispatch, logged_user?.memberships]);

  useEffect(() => {
    const ids = logged_user?.memberships[0].agencies || [];
    const subagenciesids = agencies?.map((a) =>
      a.childrenAgencies.map((s) => s.id)
    );
    getAllUsersFromAgencies([...ids, ...subagenciesids.flat()])
      .then((res) =>
        res.filter(
          (user, index) =>
            res.findIndex((u) => u.userUUID === user.userUUID) === index
        )
      )
      .then((users) => {
        allUsersOfTheLoginInUser(users)
          .then(data => dispatch(data))
          .catch(error => console.log(error)
          )
      }).catch(error => console.log('Error'));
  }, [agencies]);

  useEffect(() => {
    setAgenciesToMap(agencies);
  }, [agencies]);

  // useEffect(() => {
  //   setFavsAgencies(agencies_favs)
  // }, [agencies_favs])

  return (
    <Grid sx={{ display: "flex", flexDirection: "column" }}>
      <HandleRequests
        loading={!agenciesToMap?.length || !agencies.length}
        error={my_agenciesError}
      >
        <Grid className={styles.titleAgencias}>
          <StoreIcon
            sx={{
              fontSize: "1.8rem",
              color: colors.primary.main,
              marginRight: 2,
            }}
          />
          <h1 className={styles.title}>{t("salePoint")}</h1>
        </Grid>
        <Grid className={styles.container}>
          {
            <Grid className={styles.body}>
              <Grid className={styles.tables}>
                {Array.isArray(agenciesToMap) &&
                  agenciesToMap?.length &&
                  agenciesToMap?.map((agencie, index) => {
                    return <Collapse agencie={agencie} key={agencie?.id} />;
                  })}
              </Grid>
            </Grid>
          }
        </Grid>
      </HandleRequests>
      {analisisUsers ? (
        open ? (
          <UsersModal open={open} setOpen={setOpen} />
        ) : null
      ) : null}
    </Grid>
  );
};
