// import react
import React, { StrictMode } from 'react'
// import styles
import "./App.css";
//import react router
import { createBrowserRouter, RouterProvider } from "react-router-dom";
// import material ui
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from '@mui/material/styles';
// import theme
import { ColorPalette } from "./Themes"
// import utils
import { GetLanguage } from './helpers/getLanguage'
import { routes } from "./components/Navigation/routes/routes";
// import auth provider
import AuthProvider from "./components/Auth/AuthProvider";

// ================================= | APP | ===================================

function App() {
  GetLanguage()

  const router = createBrowserRouter(routes);

  const loteryColors = createTheme(ColorPalette());

  return (
    <StrictMode>
      <AuthProvider>
        <ThemeProvider theme={loteryColors}>
          <RouterProvider router={router} />
        </ThemeProvider>
      </AuthProvider>
    </StrictMode>
  );
}

export default App;

